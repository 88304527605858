import {Component, EventEmitter, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {IonModal, ModalController} from '@ionic/angular';

@Component({
  selector: 'app-printer-settings-modal',
  templateUrl: './printer-settings-modal.component.html',
  styleUrls: ['./printer-settings-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PrinterSettingsModalComponent  {
  @Output() connected = new EventEmitter<any>();
  @ViewChild(IonModal) modal: IonModal;

  constructor(private modalCtrl: ModalController) {
  }

  onWillDismiss(event: Event) {
    return event;
  }
}
