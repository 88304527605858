import {Injectable} from '@angular/core';
import {CarParkApiService} from './API/CarParkApiService';
import {DateTime} from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class CarParkService {

  constructor(private api: CarParkApiService) {
  }

  public carParks() {
    return this.api.carParks();
  }

  public barrierOpen(barrierId: number) {
    return this.api.open(barrierId);
  }

  public currentSession() {
    return this.api.sessions(DateTime.now().startOf('day').toFormat('yyyy-LL-dd HH:mm:ss'));
  }

  public licensePlates() {
    return this.api.licensePlates().then(response => response.licensePlates);
  }

  public deleteLicensePlate(plateId: number) {
    return this.api.deleteLicensePlate(plateId);
  }

  public parkingTicketByBarcode(barcode: string) {
    return this.api.parkingTicketByBarcode(barcode);
  }

  public validateParkingTicket(parkingTicketId: number,
                               customerId: number = null,
                               userId: number = null,
                               remark: string = null,
                               meetingId: number = null) {
    return this.api.validateParkingTicket(parkingTicketId, customerId, userId, remark, meetingId);
  }
}
