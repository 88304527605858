import {Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {RoomControlService} from '../../../../services/Rooms/RoomControlService';
import {ToastService} from '../../../../services/General/ToastService';

@Component({
  selector: 'app-room-lights',
  templateUrl: './lights.component.html',
  styleUrls: ['./lights.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RoomLightsComponent implements OnInit {
  @ViewChild('switch') switch: ElementRef;
  @Input() roomId: number;
  status = true;
  swiping = false;
  touchStart: number;
  touchLocation: number;

  constructor(private controls: RoomControlService, private toaster: ToastService) {
  }

  ngOnInit(): void {
    this.controls.lightStatus(this.roomId).then((state) => {
      this.status = state;
      if (!this.status) {
        this.switch.nativeElement.style.setProperty('--move-left', '4px');
      }
    });
  }

  toggleLights() {
    this.swiping = false;
    if (this.status) {
      this.status = false;
      this.switch.nativeElement.style.setProperty('--transition', '0.4s');
      this.switch.nativeElement.style.setProperty('--move-left', '4px');
      this.controls.lightsOff(this.roomId).then(() => {
        this.toaster.toast('Lights turned off','ok');
        });
    } else {
      this.status = true;
      this.switch.nativeElement.style.setProperty('--transition', '0.4s');
      this.switch.nativeElement.style.setProperty('--move-left', 'calc(100% - 38px)');
      this.controls.lightsOn(this.roomId).then(() => {
        this.toaster.toast('Lights turned on','ok');
      });
    }
    setTimeout(() => {
      this.switch.nativeElement.style.setProperty('--transition', '0s');
    }, 500);
  }

  switchLabel() {
    if (this.status) {
      return 'On';
    } else {
      return 'Off';
    }
  }

  switchTouch(event) {
    this.swiping = true;
    this.touchStart = event.targetTouches[0].screenX;
  }

  switchMoving(event) {
    const location = event.targetTouches[0].screenX;
    if ((this.status && location > this.touchStart - 107 && location < this.touchStart) ||
      (!this.status && location < this.touchStart + 107 && location > this.touchStart)) {
        this.touchLocation = location;
      if(this.status) {
        this.switch.nativeElement.style.setProperty('--move-left', 'calc(100% - 38px - ' + (this.touchStart - this.touchLocation) + 'px)');
      } else {
        this.switch.nativeElement.style.setProperty('--move-left', (this.touchLocation - this.touchStart + 4) + 'px');

      }
    }
  }

  switchReleased(event) {
    if(this.touchLocation !== null) {
      if (this.touchLocation > this.touchStart + 53 && !this.status) {
        this.toggleLights();
        this.touchStart = null;
        this.touchLocation = null;
      } else if (this.touchLocation < this.touchStart - 53 && this.status){
        this.toggleLights();
        this.touchStart = null;
        this.touchLocation = null;
      } else if (this.status) {
        this.switch.nativeElement.style.setProperty('--transition', '0.4s');
        this.switch.nativeElement.style.setProperty('--move-left', 'calc(100% - 38px)');
        setTimeout(() => {
          this.switch.nativeElement.style.setProperty('--transition', '0s');
        }, 500);
      } else {
        this.switch.nativeElement.style.setProperty('--transition', '0.4s');
        this.switch.nativeElement.style.setProperty('--move-left', '4px');
        setTimeout(() => {
          this.switch.nativeElement.style.setProperty('--transition', '0s');
        }, 500);
      }
    }
  }
}
