import {Component, Input, ViewEncapsulation} from '@angular/core';
import {NavController} from '@ionic/angular';

@Component({
  selector: 'app-meeting-orders',
  templateUrl: './meeting-orders.component.html',
  styleUrls: ['./meeting-orders.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class MeetingOrdersComponent {
  @Input() meeting: any;
  @Input() orders: Array<any>;

  constructor(private nav: NavController) {
  }

  amount(amountString) {
    return parseInt(amountString, 10);
  }

  addOrder() {
    this.nav.navigateForward(['/catering'], {
      state: {
        meetingId: this.meeting.id,
        date: this.meeting.from,
      }
    });
  }
}
