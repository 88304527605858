import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {TypeFormAnswersPage} from './type-form-answers.page';
import {TypeFormAnswersPageRoutingModule} from './type-form-answers-routing.module';
import {AppComponentsModule} from '../../app_components.module';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        TypeFormAnswersPageRoutingModule,
        AppComponentsModule,
    ],
  declarations: [TypeFormAnswersPage],
})
export class TypeFormAnswersPageModule {}
