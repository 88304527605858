/* eslint-disable @typescript-eslint/naming-convention */

import {Injectable} from '@angular/core';
import {MeetingApiService} from './API/MeetingApiService';
import {MeetingDateFilter} from '../../pages/meet/components/date/meeting-date-filter.interface';
import {MeetingRoomSort} from '../../pages/meet/components/container/meeting-room-sort.interface';
import {DateTime} from 'luxon';
import {BehaviorSubject} from 'rxjs';
import {Attendee} from './attendee.interface';
import {DomSanitizer} from '@angular/platform-browser';
import {TenantService} from '../Tenant/TenantService';
import {AssetTypes} from '../Assets/AssetTypes';

@Injectable({
  providedIn: 'root'
})
export class MeetingService {
  attendees = new BehaviorSubject<Array<Attendee>>([]);

  constructor(
    private api: MeetingApiService,
    private domSanitizer: DomSanitizer,
    private tenantService: TenantService
  ) {
  }

  protected static setAssetTypes(tv, whiteboard, teams) {
    const hasAssetTypes = [];
    if (tv) {
      hasAssetTypes.push(AssetTypes.tv);
    }
    if (whiteboard) {
      hasAssetTypes.push(AssetTypes.whiteboard);
    }
    if (teams) {
      hasAssetTypes.push(AssetTypes.teams);
    }
    return hasAssetTypes;
  }

  public agenda(includeEnded = true, includeTomorrow = false) {
    const startDate = DateTime.now().startOf('day');
    const endDate = includeTomorrow ? DateTime.now().plus({day: 1}).endOf('day') : DateTime.now().endOf('day');
    return this.meetings(startDate, endDate, includeEnded);
  }

  public meetings(from: DateTime, till: DateTime, includeEnded: boolean = true) {
    return this.api.myMeetings(
      from.toFormat('yyyy-LL-dd HH:mm:ss'),
      till.toFormat('yyyy-LL-dd HH:mm:ss'))
      .then(response => {
        let meetings = this.checkForProlongedMeetings(response.data);
        if (!includeEnded) {
          meetings = meetings.filter(m => DateTime.fromFormat(m.dateTill, 'yyyy-LL-dd HH:mm:ss.SSS') > DateTime.now());
        }
        return meetings.map((m) => ({
            id: m.id,
            from: DateTime.fromFormat(m.dateFrom, 'yyyy-LL-dd HH:mm:ss.SSS').toFormat('HH:mm'),
            till: DateTime.fromFormat(m.dateTill, 'yyyy-LL-dd HH:mm:ss.SSS').toFormat('HH:mm'),
            fromDate: DateTime.fromFormat(m.dateFrom, 'yyyy-LL-dd HH:mm:ss.SSS'),
            tillDate: DateTime.fromFormat(m.dateTill, 'yyyy-LL-dd HH:mm:ss.SSS'),
            subject: m.subject,
            location: m.is_room_assignment_final === '1' ? m.room.name : '',
            locationShort:  m.is_room_assignment_final === '1' ? m.room.shortname : '',
            city: m.property_name.replace(this.tenantService.getName(), ''),
            type: 'md',
            orderAllowed: m.orderAllowed,
            orders: m.orders,
          }));
      });
  }

  public update(meetingId: number, subject: string, description: string) {
    return this.api.update(meetingId, subject, description);
  }

  public updateNote(attendeeId: number, note: string) {
    return this.api.updateNote(attendeeId, note);
  }

  public availableMeetingRooms(dateFilter: MeetingDateFilter,
                               capacityFrom: number,
                               propertyId: number,
                               sort: MeetingRoomSort,
                               hasTv: boolean,
                               hasWhiteboard: boolean,
                               hasTeams: boolean,
                               roomName: string = null,
                               replaceId: number = null,
                               alternative: boolean = false,
                               allowPast: boolean = null) {

    const dateFrom = dateFilter.date.set({hour: dateFilter.from.hour, minute: dateFilter.from.minute, second: 0})
      .toFormat('yyyy-LL-dd HH:mm:ss');
    const dateTill = dateFilter.date.set({hour: dateFilter.till.hour, minute: dateFilter.till.minute, second: 0})
      .toFormat('yyyy-LL-dd HH:mm:ss');
    const hasAssetTypes = MeetingService.setAssetTypes(hasTv, hasWhiteboard, hasTeams);
    return this.api.availableMeetingRooms({
      dateFrom,
      dateTill,
      capacityFrom,
      property_id: propertyId,
      'hasAssetTypes[]': hasAssetTypes,
      paginate: 0,
      ...(roomName && {roomName}),
      ...(sort.sortBy && {sortBy: sort.sortBy}),
      ...(sort.sortDirection && {sortDirection: sort.sortDirection}),
      ...(replaceId && {replace_id: replaceId}),
      ...(replaceId && {oldbooking_id: replaceId}),
      alternative,
      ...(allowPast && {allowPast: (allowPast ? 1 : 0)})
    });
  }

  public availableCapacityMeetingRooms(dateFilter: MeetingDateFilter,
                               capacityFrom: number,
                               propertyId: number,
                               sort: MeetingRoomSort,
                               hasTv: boolean,
                               hasWhiteboard: boolean,
                               hasTeams: boolean,
                               roomName: string = null,
                               replaceId: number = null,
                               alternative: boolean = false,
                               allowPast: boolean = null) {

    const dateFrom = dateFilter.date.set({hour: dateFilter.from.hour, minute: dateFilter.from.minute, second: 0})
      .toFormat('yyyy-LL-dd HH:mm:ss');
    const dateTill = dateFilter.date.set({hour: dateFilter.till.hour, minute: dateFilter.till.minute, second: 0})
      .toFormat('yyyy-LL-dd HH:mm:ss');
    const hasAssetTypes = MeetingService.setAssetTypes(hasTv, hasWhiteboard, hasTeams);
    return this.api.availableCapacityMeetingRooms({
      dateFrom,
      dateTill,
      capacityFrom,
      property_id: propertyId,
      'hasAssetTypes[]': hasAssetTypes,
      paginate: 0,
      ...(roomName && {roomName}),
      ...(sort.sortBy && {sortBy: sort.sortBy}),
      ...(sort.sortDirection && {sortDirection: sort.sortDirection}),
      ...(replaceId && {replace_id: replaceId}),
      ...(replaceId && {oldbooking_id: replaceId}),
      alternative,
      ...(allowPast && {allowPast: (allowPast ? 1 : 0)})
    }).then(data => data.map(i => ({
        ...i,
        name: null,
        shortname: null,
    })));
  }

  public suggestMeetingRoom(from: DateTime, till: DateTime, attendeeCount: number) {
    return this.api.suggestMeetingRoom(
      from.toFormat('yyyy-LL-dd HH:mm:ss'),
      till.toFormat('yyyy-LL-dd HH:mm:ss'),
      attendeeCount
    ).then(response => {
      const room = response.room;
      return this.formatRoom(room);
    });
  }

  public formatRoom(room) {
    return {
      id: room.id,
      name: room.name,
      capacity: parseInt(room.maxCapacity, 10),
      price: room.price,
      tokens: room.tokenPrice,
      tv: room.assetsInTheRoom.some(asset => asset.assetType_id === String(AssetTypes.tv)),
      whiteboard: room.assetsInTheRoom.some(asset => asset.assetType_id === String(AssetTypes.whiteboard)),
      teams: room.assetsInTheRoom.some(asset => asset.assetType_id === String(AssetTypes.teams)),
      imageUrl: this.domSanitizer.bypassSecurityTrustResourceUrl(room.imageUrlWide ?? room.imageUrl),
      isFavorite: room.isFavorite,
      imageFullUrl: this.domSanitizer.bypassSecurityTrustResourceUrl(room.imageUrl),
      ...(room.bookingDateFrom && {from: DateTime.fromFormat(room.bookingDateFrom.date, 'yyyy-LL-dd HH:mm:ss')}),
      ...(room.bookingDateTill && {till: DateTime.fromFormat(room.bookingDateTill.date, 'yyyy-LL-dd HH:mm:ss')}),
    };
  }

  public meeting(meetingId: number) {
    return this.api.meeting(meetingId).then(meeting => {
      meeting.from = DateTime.fromFormat(meeting.dateFrom, 'yyyy-LL-dd HH:mm:ss.SSS');
      meeting.till = DateTime.fromFormat(meeting.dateTill, 'yyyy-LL-dd HH:mm:ss.SSS');
      meeting.attendees = this.mapAttendees(meeting.attendees);
      meeting.teamsUrl = meeting.online_meeting_join_url;
      meeting.prolongedMeetingId = parseInt(meeting.prolonged_room_booking_id, 10);
      meeting.tokenPrice = parseInt(meeting.token_price, 10);
      if (meeting.is_room_assignment_final === '0') {
        meeting.room.name = null;
        meeting.room.shortname = null;
      }
      return meeting;
    });
  }

  public cancelMeeting(meetingId: number) {
    return this.api.cancelMeeting(meetingId);
  }

  public endMeeting(meetingId: number) {
    return this.api.endMeeting(meetingId);
  }

  public mapAttendees(attendees, isFullList = true) {
    let formattedAttendees = attendees.map(attendee => {
      let name = '';
      if (attendee.firstname && attendee.lastname) {
        name = `${attendee.firstname} ${attendee.lastname}`;
      } else {
        const username = attendee.email.split('@')[0];
        const nameParts = username.split('.');
        nameParts.forEach(part => {
          name += part.charAt(0).toUpperCase() + part.substring(1) + ' ';
        });
      }

      if (!attendee.user_id || !attendee.customer) {
        let customer = attendee.email.split('@')[1].split('.')[0] ?? null;
        if (['gmail', 'outlook', 'hotmail', 'yahoo', 'msn', 'live'].includes(customer)) {
          customer = null;
        }
        attendee.customer = {
          name: customer ? customer.substring(0, 1).toUpperCase() + customer.substring(1) : null,
        };
      }

      return {
        name,
        id: attendee.id,
        userId: attendee.user_id ? parseInt(attendee.user_id, 10) : null,
        email: attendee.email,
        note: attendee.note,
        host: attendee.user_id === attendee.host_id,
        status: attendee.status ? parseInt(attendee.status, 10) : 0,
        carParkReservation: !!attendee.parking_reservation_id,
        customer: attendee.customer.name,
        invited_by_attendee_id: attendee.invited_by_attendee_id,
        parkedAt: attendee.parked_at,
        checkedInAt: attendee.checked_in_at,
        inMeetbox: attendee.in_meetbox,
        parkingAccess: attendee.parkingAccess,
        visitorPassUrl: attendee.visitor_pass_url,
      };
    }).sort(a => a.host ? -1 : 1);

    for (const attendee of formattedAttendees) {
      attendee.invitedAttendees = [];
    }
    if (isFullList) {
      formattedAttendees = this.createNestedAttendees(formattedAttendees);
    }
    return formattedAttendees;
  }

  public createNestedAttendees(formattedAttendees) {
    const newFilteredAttendees = [];
    const processedAttendeeIds = new Set();

    for (const attendee of formattedAttendees) {
      if (attendee.invited_by_attendee_id) {
        const parent = formattedAttendees.find(filterAttendee => filterAttendee.id === parseInt(attendee.invited_by_attendee_id, 10));

        if (parent) {
          if (!parent.invitedAttendees) {
            parent.invitedAttendees = [];
          }

          if (!processedAttendeeIds.has(attendee.id)) {
            parent.invitedAttendees.push(attendee);
            processedAttendeeIds.add(attendee.id);
          }
        } else {
          if (!processedAttendeeIds.has(attendee.id)) {
            newFilteredAttendees.push(attendee);
            processedAttendeeIds.add(attendee.id);
          }
        }
      } else {
        if (!processedAttendeeIds.has(attendee.id)) {
          newFilteredAttendees.push(attendee);
          processedAttendeeIds.add(attendee.id);
        }
      }
    }

    for (const filteredAttendee of newFilteredAttendees) {
      if (filteredAttendee.host === true) {
        for (const attendee of filteredAttendee.invitedAttendees) {
          newFilteredAttendees.push(attendee);
        }
        filteredAttendee.invitedAttendees = [];
      }
    }

    return newFilteredAttendees;
  }

  public calendarInformation(meetingId: number) {
    return this.api.calendarInformation(meetingId);
  }

  public setNativeEventId(meetingId: number, eventId: string) {
    return this.api.setNativeEventId(meetingId, eventId);
  }

  public replyToInvite(attendeeId: number, status: number, addedToNativeCalendar: boolean = null) {
    return this.api.replyToInvite(attendeeId, status, addedToNativeCalendar);
  }

  public recommendedAttendees() {
    return this.api.recommendedAttendees().then(response =>
      response.map(attendee => ({
        name: `${attendee.firstname} ${attendee.lastname}`,
        id: attendee.id,
        email: attendee.email,
      }))
    );
  }

  public inviteAttendees(meetingId: number, emails: Array<object>) {
    return this.api.inviteAttendees(meetingId, emails).then(response => this.mapAttendees(response.data, false));
  }

  public removeAttendee(attendeeId: number) {
    return this.api.removeAttendee(attendeeId);
  }

  public notifyHost(meetingId: number, message: string) {
    return this.api.notifyHost(meetingId, message);
  }

  public locationInvite(
    emails: Array<string>,
    parking: Array<number>,
    date: DateTime,
    propertyId: number,
    officeAccess: boolean,
    corporateWalletAccess: boolean
  ) {
    return this.api.locationInvite(emails, parking, date, propertyId, officeAccess, corporateWalletAccess);
  }

  public locationInvitees() {
    return this.api.locationInvitees().then(response => JSON.parse(response));
  }

  public getScheduledMeetingsForIds(from: DateTime, till: DateTime, meetingIds: Array<number>) {
    return this.api.getScheduledMeetingsForIds(
      from.toFormat('yyyy-LL-dd HH:mm:ss'),
      till.toFormat('yyyy-LL-dd HH:mm:ss'),
      meetingIds,
    );
  }

  public replyToVisitorRegistration(notificationId, action) {
    return this.api.replyToVisitorRegistration(notificationId, action);
  }

  public defaultVisitorRegistration(notificationId) {
    return this.api.defaultVisitorRegistration(notificationId);
  }

  public replaceMeetingRoom(meetingId: number, roomId: number) {
    return this.api.replaceMeetingRoom(meetingId, roomId);
  }

  public setOutlookEventId(meetingId: number, outlookEventId: string) {
    this.api.setOutlookEventId(meetingId, outlookEventId);
  }

  private checkForProlongedMeetings(meetings) {
    const meetingsWithProlongMeeting = meetings.filter(meeting => meeting.prolonged_room_booking_id);
    meetingsWithProlongMeeting.forEach(meeting => {
      meetings.splice(meetings.indexOf(meeting), 1);
      const meetingToUpdate = meetings.filter(m => m.id === parseInt(meeting.prolonged_room_booking_id, 10))[0];
      meetingToUpdate.dateTill = meeting.dateTill;
    });
    return meetings;
  }
}
