import {IonModal} from '@ionic/angular';
import {ModalController} from '@ionic/angular';
import {OverlayEventDetail} from '@ionic/core/components';
import {Component, ViewChild, ViewEncapsulation} from '@angular/core';
import {UserService} from '../../services/User/UserService';
import {WalletService} from '../../services/Wallet/WalletService';
import {CacheService} from '../../services/Cache/CacheService';
import {ModuleService} from '../../services/Modules/ModuleService';

@Component({
  selector: 'app-more-modal',
  templateUrl: './more-modal.component.html',
  styleUrls: ['./more-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MoreModalComponent {
  @ViewChild(IonModal) modal: IonModal;
  name: string;
  customers: Array<any> = [];
  customer: number;
  print: any = {
    username: null,
    password: null
  };
  walletAmount = '0,00';
  isMember = true;

  constructor(private modalCtrl: ModalController, public user: UserService,
              private walletService: WalletService, private cache: CacheService,
              public modules: ModuleService) {
    this.init();
  }

  init() {
    this.user.hasMembership.subscribe(isMember => {
      this.isMember = isMember;
    });
    this.user.isMember();
    this.handleProfileData();

    this.cache.getWithDelayedObservable(
      'personal-wallet-value', this.walletService.walletValue('wallet')).subscribe(value => {
      this.walletAmount = value;
    });
  }

  handleProfileData(fresh: boolean = false) {
    this.user.profile(fresh).then(profile => {
      this.name = profile.firstname;
      if (this.modules.has('printing')) {
        this.print = {
          username: profile.facility_username,
          password: profile.facility_password
        };
      }
      if (!fresh) {
        this.customers = profile.customers;
        const defaultCustomer = this.customers.find(customer => customer.pivot.isDefault === '1');
        this.customer = defaultCustomer ? defaultCustomer.id : null;
      }
    });
  }

  customerChanged() {
    this.user.updateDefaultCustomer(this.customer).then(() => {
      this.handleProfileData(true);
    });
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
  }

  goToRoute(route: string) {
    this.modalCtrl.dismiss({route}, 'confirm');
  }
}
