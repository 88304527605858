import {Injectable} from '@angular/core';
import {RoomApiService} from './API/RoomApiService';
import {DateTime} from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class RoomService {

  constructor(private api: RoomApiService) {
  }

  public rooms(propertyId: number = null, hasLock: boolean = false, roomName: string = null, floor: number = null) {
    return this.api.rooms(propertyId, hasLock ? 1 : 0, roomName, floor);
  }

  public roomsWithControls(propertyId: number = null) {
    return this.api.roomsWithControls(propertyId).then(response => (response.rooms));
  }

  public roomsWithAssets(propertyId, includeInactive = false) {
    return this.api.roomsWithAssets(propertyId, includeInactive);
  }

  public accessibleRooms() {
    return this.api.accessibleRooms().then(rooms => {
      rooms.forEach(room => {
        if (room.bookings) {
          room.meeting = {};
          room.meeting.id = room.bookings[0].id;
          room.meeting.from = DateTime.fromFormat(room.bookings[0].dateFrom, 'yyyy-LL-dd HH:mm:ss.SSS').toFormat('HH:mm');
          room.meeting.till = DateTime.fromFormat(room.bookings[0].dateTill, 'yyyy-LL-dd HH:mm:ss.SSS').toFormat('HH:mm');
          delete room.bookings;
        }
      });
      return rooms;
    });
  }

  public room(roomId: number) {
    return this.api.room(roomId);
  }

  public types() {
    return this.api.types();
  }
}
