import {ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {DateTime} from 'luxon';
import {Reservation} from '../../services/Reservation/reservation.interface';
import {SwiperComponent} from 'swiper/angular';

@Component({
  selector: 'app-meetdistrict-calendar',
  templateUrl: './meetdistrict-calendar.component.html',
  styleUrls: ['./meetdistrict-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MeetdistrictCalendarComponent {
  @ViewChild('swiper', {static: false}) swiper?: SwiperComponent;
  @Input() reservations: Array<Reservation> = [];
  @Output() selectionChanged = new EventEmitter<DateTime>();
  @Output() monthChanged = new EventEmitter<DateTime>();
  now = DateTime.now().set({hour: 0, minute: 0, second: 0, millisecond: 0});
  weekVariation = 0;
  selectedDay = this.now;
  selectedMonth = this.now.set({day: 1});
  selectedMonthBefore = this.now.minus({month: 1}).set({day: 1});
  selectedMonthAfter = this.now.plus({month: 1}).set({day: 1});
  mode = 'week';
  weeks: Array<Array<DateTime>>;
  weeksBefore: Array<Array<DateTime>>;
  weeksAfter: Array<Array<DateTime>>;
  weekOfMonth: number;
  hidingMonthView = false;
  finishedLoading = false;

  constructor(private change: ChangeDetectorRef) {
    this.init();
  }

  init() {
    this.weeks = [this.getWeek(this.now)];
    this.weeksBefore = [this.getWeek(this.now.minus({week: 1}))];
    this.weeksAfter = [this.getWeek(this.now.plus({week: 1}))];
    this.setWeekOfMonth();
  }

  getWeek(date) {
    const startOfWeek = date.startOf('week');
    const endOfWeek = date.endOf('week');
    let day = startOfWeek;
    const week = [];
    while (day < endOfWeek) {
      week.push(day);
      day = day.plus({day: 1});
    }
    return week;
  }

  loadMonthView(day) {
    let firstWeekDay = day.startOf('month').startOf('week');
    const weeks = [];
    let weekCount = 0;
    while (weekCount < 6) {
      weeks.push(this.getWeek(firstWeekDay));
      firstWeekDay = firstWeekDay.plus({week: 1});
      weekCount++;
    }
    return weeks;
  }

  changeMonth(amount) {
    this.selectedMonth = this.selectedMonth.set({month: this.selectedMonth.month + amount});
    this.selectedMonthBefore = this.selectedMonth.minus({month: 1});
    this.selectedMonthAfter = this.selectedMonth.plus({month: 1});
    this.weeks = this.loadMonthView(this.selectedMonth);
    this.weeksBefore = this.loadMonthView(this.selectedMonthBefore);
    this.weeksAfter = this.loadMonthView(this.selectedMonthAfter);
    this.monthChanged.emit(this.selectedMonth);
  }

  changeWeek(amount) {
    this.weekVariation += amount;
    const date = this.selectedDay.plus({week: this.weekVariation});
    this.weeks = [this.getWeek(date)];
    this.weeksBefore = [this.getWeek(date.minus({week: 1}))];
    this.weeksAfter = [this.getWeek(date.plus({week: 1}))];
    this.setWeekOfMonth();
  }

  setWeekOfMonth() {
    const month = this.loadMonthView(this.selectedDay);
    let weekOfMonth = null;
    month.forEach((week, index) => {
      if (week.filter(day => day.hasSame(this.selectedDay, 'day')).length) {
        weekOfMonth = index + 1;
      }
    });
    this.weekOfMonth = weekOfMonth;
  }

  selectDay(day) {
    const monthChange = day.startOf('month').diff(this.selectedMonth.startOf('month'), 'months').months;
    if (monthChange !== 0) {
      this.changeMonth(monthChange);
    }
    this.selectedDay = day;
    this.weekVariation = 0;
    this.changeWeek(0);
    this.setWeekOfMonth();
    this.selectionChanged.emit(this.selectedDay);
    if (this.mode === 'month') {
      this.changeMode();
    }
    this.change.detectChanges();
  }

  isPast(day) {
    const currentDate = DateTime.now().startOf('day');
    return day < currentDate;
  }

  changeMode() {
    this.finishedLoading = false;
    this.mode = this.mode === 'week' ? 'month' : 'week';
    if (this.mode === 'week') {
      this.hidingMonthView = true;
      setTimeout(() => {
        this.selectedMonth = this.selectedDay.set({day: 1, hour: 0, minute: 0, second: 0, millisecond: 0});
        this.selectedMonthBefore = this.selectedMonth.minus({month: 1});
        this.selectedMonthAfter = this.selectedMonth.plus({month: 1});
        this.weeks = [this.getWeek(this.selectedDay)];
        this.weeksBefore = [this.getWeek(this.selectedDay.minus({week: 1}))];
        this.weeksAfter = [this.getWeek(this.selectedDay.plus({week: 1}))];
        this.hidingMonthView = false;
      }, 200);
    } else {
      setTimeout(() => {
        this.weeks = this.loadMonthView(this.selectedDay);
        this.weeksBefore = this.loadMonthView(this.selectedDay.minus({month: 1}));
        this.weeksAfter = this.loadMonthView(this.selectedDay.plus({month: 1}));
        setTimeout(() => {
          this.finishedLoading = true;
        }, 200);
      }, 200);
    }
  }

  dayHasParkingReservation(day) {
    if (this.reservations) {
      return !!this.reservations.filter(
        reservation => reservation.date.hasSame(day, 'day') && reservation.type === 'carPark').length;
    }
    return false;
  }

  dayHasSeatReservation(day) {
    if (this.reservations) {
      return !!this.reservations.filter(
        reservation => reservation.date.hasSame(day, 'day') && reservation.type === 'seat').length;
    }
    return false;
  }

  dateSwiperChanged(event) {
    event = event[0];
    if (event.activeIndex !== 1) {
      setTimeout(() => {
        if (this.mode === 'month') {
          this.changeMonth(event.activeIndex === 0 ? -1 : 1);
        } else {
          this.changeWeek(event.activeIndex === 0 ? -1 : 1);
        }

        this.swiper.swiperRef.slideTo(1, 0);
        this.change.detectChanges();

      }, 150);
    }
  }

  getWeekClass(index, weeks) {
    const classes = [
      index === 0 && weeks.length === 1 ? 'week-' + this.weekOfMonth : '',
      index === this.weekOfMonth - 1 && this.hidingMonthView ? 'hiding-' + this.weekOfMonth : ''
    ];
    let result = '';
    classes.forEach(c => {
      result += c + ' ';
    });
    return result;
  }
}
