import {ButtonInterface} from './button.interface';
import {Component, Input, Output, EventEmitter} from '@angular/core';


@Component({
  selector: 'app-button-switch',
  templateUrl: './button-switch.component.html',
  styleUrls: ['./button-switch.component.scss'],
})
export class ButtonSwitchComponent {
  @Input() buttons: ButtonInterface[] = [];
  @Output() buttonSelected = new EventEmitter<ButtonInterface>();

  setSelected(button: ButtonInterface): void {
    this.buttons = this.buttons.map((b) => ({
      ...b,
      selected: b.id === button.id,
    }));
    this.buttonSelected.emit(button);
  }

  isFirst(id: number): boolean {
    return this.buttons[0].id === id;
  }

  isLast(id: number): boolean {
    return this.buttons[this.buttons.length - 1].id === id;
  }
}
