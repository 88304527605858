import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {TypeFormAnswersPage} from './type-form-answers.page';

const routes: Routes = [
  {
    path: 'typeform-answers',
    component: TypeFormAnswersPage,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TypeFormAnswersPageRoutingModule {}
