import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-meetings-list',
  templateUrl: './meetings-list.component.html',
  styleUrls: ['./meetings-list.component.scss'],
})
export class MeetingsListComponent {
  @Input() meetings: Array<any> = [];

  constructor() {
  }
}
