import {Component, OnInit} from '@angular/core';
import {TenantService} from '../../services/Tenant/TenantService';
import {ApiService} from '../../services/General/ApiService';
import {StorageService} from '../../services/Storage/StorageService';

@Component({
  selector: 'app-logo-button',
  templateUrl: './logo-button.component.html',
  styleUrls: ['./logo-button.component.scss']
})

export class LogoButtonComponent implements OnInit{
  public logoUrl: string;

  constructor(private tenantService: TenantService, private api: ApiService,
              private storage: StorageService) {
  }

  ngOnInit(): void {
    this.storage.get('cached-app-icon').then(image => {
      this.logoUrl = image;
      this.tenantService.tenantConfig.subscribe((data) => {
        if (data){
          const imgUrl = this.tenantService.tenantConfig.value.logo.short;
          if (!image) {
            this.logoUrl = imgUrl;
          }
          const urlArr = imgUrl.split('/');
          const url = urlArr[urlArr.length - 2] + '/' + urlArr[urlArr.length - 1];
          fetch(this.api.apiURL + 'app-images/' + url).then(response => {
            if (response.status === 200) {
              response.text()
                .then(svg => {
                  this.storage.set('cached-app-icon', 'data:image/svg+xml;base64,' + btoa(svg));
                });
            }
          });
        }
      });
    });
  }

}
