import {Injectable} from '@angular/core';
import {NFC} from '@awesome-cordova-plugins/nfc/ngx';
import {ScanDataService} from '../Scan/ScanDataService';
import {Platform} from '@ionic/angular';
import {ApiService} from '../General/ApiService';
import Bugsnag from '@bugsnag/js';

@Injectable({
  providedIn: 'root'
})
export class NfcService {

  constructor(private nfc: NFC, private dataService: ScanDataService,
              private platform: Platform, private api: ApiService) {
    if (this.platform.is('android')) {
      this.init();
    }
  }

  init() {
    this.nfc.addMimeTypeListener('text/any', () => {
    }, (err) => {
      Bugsnag.notify(err);
    }).subscribe(event => {
      this.waitForToken(event);
    });

    this.nfc.addNdefListener(() => {
    }, (err) => {
      Bugsnag.notify(err);
    }).subscribe((event) => {
      this.waitForToken(event);
    });
  }

  waitForToken(event) {
    if (this.api.token) {
      this.handleNfcEvent(event);
    } else {
      setTimeout(() => {
        this.handleNfcEvent(event);
      }, 1000);
    }
  }

  handleNfcEvent(event) {
    for (const msg of event.tag.ndefMessage) {
      const command = this.nfc.bytesToString(msg.payload).substring(3);
      if (command.includes('/')) {
        this.dataService.process(command);
      }
    }
  }

  startSession() {
    return this.nfc.scanNdef();
  }

  endSession() {
    this.nfc.cancelScan();
  }
}
