import {Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {RoomControlService} from '../../../../services/Rooms/RoomControlService';
import {ToastService} from '../../../../services/General/ToastService';
import Bugsnag from '@bugsnag/js';

@Component({
  selector: 'app-room-door',
  templateUrl: './door.component.html',
  styleUrls: ['./door.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RoomDoorComponent implements OnInit {
  @ViewChild('switch') switch: ElementRef;
  @Input() roomId: number;
  @Input() pmsId: number = null;
  @Input() name: string;
  status = false;
  swiping = false;
  touchStart: number;
  touchLocation: number;

  constructor(private controls: RoomControlService, private toaster: ToastService) {
  }

  ngOnInit(): void {
  }

  unlock() {
    this.swiping = false;
    this.status = true;
    this.controls.unlock(this.roomId, this.pmsId).then(unlocked => {
      this.toaster.toast('Door unlocked','ok');
      setTimeout(() => {
        this.status = false;
        this.switch.nativeElement.style.setProperty('--move-left', '4px');
      }, 5000);
    }).catch(error => {
      Bugsnag.notify(error);
      this.status = false;
      this.switch.nativeElement.style.setProperty('--move-left', '4px');
    });
  }

  switchLabel() {
    if (this.status) {
      return 'open';
    } else {
      return 'Unlock';
    }
  }

  switchTouch(event) {
    this.swiping = true;
    this.touchStart = event.targetTouches[0].screenX;
  }

  switchMoving(event) {
    const location = event.targetTouches[0].screenX;
    if (location > this.touchStart && location < this.touchStart + 107) {
      this.touchLocation = location;
      this.switch.nativeElement.style.setProperty('--move-left', (this.touchLocation - this.touchStart + 4) + 'px');
    }
  }

  switchReleased(event) {
    if (this.touchLocation > this.touchStart + 53) {
      this.unlock();
      this.touchStart = null;
      this.touchLocation = null;
    } else {
      let position = this.touchLocation - this.touchStart + 4;
      const interval = setInterval(() => {
        if (position <= 4 || this.swiping === false) {
          this.switch.nativeElement.style.setProperty('--move-left', '4px');
          clearInterval(interval);
        } else {
          this.switch.nativeElement.style.setProperty('--move-left', position + 'px');
          position--;
        }
      }, 1);
    }
  }
}
