import {Component, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges} from '@angular/core';
import {DateTime} from 'luxon';
import {MeetingDateFilter} from './meeting-date-filter.interface';
import {ToastService} from '../../../../services/General/ToastService';

@Component({
  selector: 'app-meet-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class MeetDateComponent implements OnInit, OnChanges {
  @Input() presetState: any;
  @Output() changed = new EventEmitter<MeetingDateFilter>();
  today = DateTime.now().toISO();
  date: any = DateTime.now();
  dateModel = this.date.toISO();
  startTime: any = DateTime.now();
  maxStartTime = this.date.set({hour: 23, minute: 15}).toISO();
  startTimeModel: string;
  endTime: any = DateTime.now().plus({hour: 1});
  durationTime: any =  DateTime.now().startOf('day').plus({hour: 1});
  durationModel: string;
  max = DateTime.now().plus({year: 2});
  dateLabel = 'Today';
  startLabel = '';
  durationLabel = '';

  constructor(
    private renderer: Renderer2,
    private toast: ToastService
  ) {
  }

  ngOnInit() {
    if (this.presetState && this.presetState.from && this.presetState.till) {
      this.setPresetState();
    } else {
      this.initStartTime();
      this.initEndTime();
      this.setDuarationLabel();
    }
    this.updated();
    this.changeTextFont();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.presetState) {
      this.setPresetState();
    }
  }

  setPresetState() {
    if(!this.presetState) {
      return;
    }
    const from = DateTime.fromMillis(this.presetState.from);
    this.endTime = DateTime.fromMillis(this.presetState.till);
    this.durationTime = from.startOf('day').plus({millisecond: DateTime.fromMillis(this.presetState.till).diff(from).milliseconds});
    this.durationModel = this.durationTime.toISO();
    this.dateModel = from.toISO();
    this.dateUpdated();
    this.startTimeModel = from.toISO();
    this.startTimeUpdated();
    this.durationUpdated();
  }

  initStartTime(): void {
    // Should be at 0, 15, 30 or 45
    // If current time is not one of the above, get previous
    const now = DateTime.now().set({second: 0, millisecond: 0});
    const minute = now.minute;
    const closestQuarter = Math.round(minute / 15) * 15;
    this.startTime = now.set({ minute: closestQuarter });
    this.endTime = this.startTime.plus({hour: 1});
    this.startLabel = this.startTime.toLocaleString(DateTime.TIME_24_SIMPLE);
    this.startTimeModel = this.startTime.toISO();
  }

  initEndTime(): void {
    this.durationModel = this.durationTime.toISO();
  }

  setDuarationLabel(): void {

    this.durationLabel = this.durationTime.toFormat('H:mm');
  }

  dateUpdated(event = null): void {
    this.date = DateTime.fromISO(this.dateModel);
    const now = DateTime.now();
    if (this.date.hasSame(now, 'day')) {
      this.dateLabel = 'Today';
    } else if (this.date.hasSame(now.plus({day: 1}), 'day')) {
      this.dateLabel = 'Tomorrow';
    } else {
      // Format: DD/MM/YYYY
      this.dateLabel = this.date.toLocaleString();
    }
  }

  startTimeUpdated(): void {
    this.startTime = DateTime.fromISO(this.startTimeModel).set({second: 0, millisecond: 0});
    this.startLabel = this.startTime.toLocaleString(DateTime.TIME_24_SIMPLE);
    this.durationUpdated();
  }

  durationUpdated(): void {
    let formattedTime = this.startTime.plus({
      minute:DateTime.fromISO(this.durationModel).minute
    }).plus({
      hour: DateTime.fromISO(this.durationModel).hour
    });
    this.durationTime = DateTime.fromISO(this.durationModel);
    if(formattedTime.equals(this.startTime)){
      formattedTime = this.startTime.plus({minute:30});
      this.durationTime = this.durationTime.startOf('day').plus({minute:30});
      this.toast.toast('Minimum duration should be 30 minutes');
    }

    if (formattedTime.day !== this.startTime.day){
      formattedTime = this.startTime.plus({minute:30});
      this.durationTime = this.durationTime.startOf('day').plus({minute:30});
      this.toast.toast('Booking cannot exceed to the next day');
    }

    this.endTime = formattedTime;

    this.setDuarationLabel();
  }

  updated() {
    this.changed.emit({
      date: this.date,
      from: this.startTime,
      till: this.endTime
    });
  }

  changeTextFont() {
    setTimeout(() => {
      const days = document.querySelector('#date').shadowRoot.querySelectorAll('.calendar-day');
      days.forEach(day => {
        this.renderer.setAttribute(day, 'part', 'calendar-day');
      });
    }, 200);
  }
}
