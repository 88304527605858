/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from './ApiService';
import {ToastService} from './ToastService';
import Bugsnag from '@bugsnag/js';

@Injectable({
  providedIn: 'root'
})
export class UrlActionService {

  constructor(private api: ApiService, private toaster: ToastService) {
  }

  handleAction(action) {
    if (action.action === 'open barrier') {
      return this.api.fullPathPost(action.url).then(response => {
        this.toaster.toast('The barrier will open', 'ok');
      }).catch(err => {
        Bugsnag.notify(err);
        this.toaster.toast('Something went wrong trying to execute action');
      });
    } else {
      return this.api.fullPathGet(action.url).then(response => {
        this.toaster.toast(action.action === 'unlock' ?
          'Door unlocked' : action.action === 'tcn-auth' ? 'Authenticated' : 'Action completed', 'ok');
      }).catch(err => {
        Bugsnag.notify(err);
        this.toaster.toast('Something went wrong trying to execute action');
      });
    }
  }
}
