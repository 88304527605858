/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../General/ApiService';

@Injectable({
  providedIn: 'root'
})
export class PropertyApiService {
  constructor(private api: ApiService) {
  }

  public properties() {
    //Only when pilot the type is used in de backend
    return this.api.get('my-properties');
  }
}
