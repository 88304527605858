/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../General/ApiService';

@Injectable({
  providedIn: 'root'
})
export class TenantApiService {
  constructor(private api: ApiService) {
  }

  public setApiTenantService(service) {
    this.api.setTenantService(service);
  }

  public getConfig(): Promise<any> {
    const params = {};
    return this.api.get('tenant/config', params);
  }
}
