/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../General/ApiService';

@Injectable({
  providedIn: 'root'
})
export class RoomApiService {
  constructor(private api: ApiService) {
  }

  public rooms(propertyId: number = null, hasLock: number = 0, roomName: string = null, floor: number = null) {
    const params = {
      ...(propertyId && {property_id: propertyId}),
      canUnlock: hasLock,
      ...(roomName && {name: roomName}),
      ...(floor !== null && {floor}),
    };
    return this.api.get(`room/all`, params);
  }

  public roomsWithControls(propertyId: number = null) {
    const params = {
      propertyId,
    };
    return this.api.get('v3/rooms/with-controls', params);
  }

  public accessibleRooms() {
    return this.api.get(`user/favorite-assets`);
  }

  public roomsWithAssets(propertyId, includeInactive = false) {
    const params = {
      property_id: propertyId,
      ...(includeInactive && {includeInactive}),
    };
    return this.api.get(`room/all/assets`, params);
  }

  public room(id: number) {
    return this.api.get(`room/${id}`);
  }

  public types() {
    return this.api.get(`room/types`);
  }
}
