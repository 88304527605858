import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {NumberSliderItemInterface} from './number-slider-item.interface';

@Component({
  selector: 'app-number-slider',
  templateUrl: './number-slider.component.html',
  styleUrls: ['./number-slider.component.scss'],
})
export class NumberSliderComponent implements OnInit, OnChanges {
  @Input() items: NumberSliderItemInterface[] = [];
  @Input() presetState: any;
  @Input() step: any;
  @Output() updateSelected = new EventEmitter<NumberSliderItemInterface>();
  min = 99999999;
  max = 0;
  value = 50;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this.items.forEach((item) => {
        if (item.selected) {
          this.value = item.value;
        }
      });
    }
  }

  ngOnInit() {
    this.items.forEach((item) => {
      if (this.min > item.value) {
        this.min = item.value;
      }
      if (this.max < item.value) {
        this.max = item.value;
      }
      if (item.selected) {
        this.value = item.value;
      }
    });
    if(this.presetState && this.presetState.capacity !== null) {
      this.value = Math.min(this.presetState.capacity, 10);
      this.handleChange();
    }
  }

  handleChange() {
    const step = this.items.find((item) => item.value === this.value);

    if (step) {
      this.value = step.value;
      this.updateSelected.emit(step);
    } else {
      const nextStep = this.items.find((item) => item.value > this.value);
      this.value = nextStep.value;
      this.updateSelected.emit(nextStep);
    }
  }
}
