import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MoreModalComponentModule} from './../../../modals/more/more-modal.module';

import {TabsPage} from './tabs.page';
import {AppComponentsModule} from '../../../app_components.module';
import {RouterLink} from '@angular/router';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    AppComponentsModule,
    MoreModalComponentModule,
    RouterLink,
  ],
  declarations: [TabsPage],
  exports: [
    TabsPage
  ]
})
export class TabsPageModule {
}
