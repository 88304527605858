import {Injectable} from '@angular/core';
import {StorageService} from '../Storage/StorageService';
import {ColorService} from '../General/ColorService';

@Injectable({
  providedIn: 'root'
})
export class WalletMetaDataService {
  constructor(private storage: StorageService, private colors: ColorService) {
  }

  public handleWalletMetaDataUpdate(wallets, walletMetaData, canUseTokenPayment) {
    const colors = this.walletDefaultColors();
    const newMetaData = [];
    let preserveWallets = [];
    let resultMetaData = [];

    //Check if token wallets need to be preserved as they are not included in wallets
    if (!canUseTokenPayment && walletMetaData) {
      preserveWallets = walletMetaData.filter(wallet => wallet.wallet.includes('token'));
    }

    //Loop retrieved wallets, check if they already have an existing metaData item, if not create one
    wallets.forEach((wallet) => {
      const name = `${wallet.name}-${wallet.type}-${wallet.customerId}`;
      const existingWallet = walletMetaData ?
        walletMetaData.filter(metaData => metaData.wallet === name)[0] ?? null : null;

      if (existingWallet) {
        resultMetaData.push(existingWallet);
      } else {
        newMetaData.push({
          wallet: name,
          color: colors[newMetaData.length],
          sequence: null,
        });
      }
    });

    //re-add wallets that should be preserved
    resultMetaData = [...resultMetaData, ...preserveWallets];

    //sort the pre-existing items by sequence number
    resultMetaData.sort((a, b) => a.sequence > b.sequence ? 1 : -1);


    //add new items behind pre-existing items
    newMetaData.forEach(metaData => {
      resultMetaData.push(metaData);
    });

    //sort newly added token wallets to the top
    resultMetaData.sort((a, b) =>
      a.sequence === null && a.wallet.includes('token') ? -1 :
        b.sequence === null && b.wallet.includes('token') ? 1 : 0
    );

    //reset sequence numbers to sequential numbers starting from 0
    resultMetaData.forEach((metaData, index) => {
      metaData.sequence = index;
    });

    //store new metadata
    this.storage.set('wallet-meta-data', resultMetaData);

    //add metadata to the wallets
    wallets.forEach(wallet => {
      const name = `${wallet.name}-${wallet.type}-${wallet.customerId}`;
      wallet.metaData = resultMetaData.filter(metaData => metaData.wallet === name)[0];
    });

    //sort the wallets by sequence number
    wallets.sort((a, b) => a.metaData.sequence > b.metaData.sequence ? 1 : -1);

    return wallets;
  }

  public updateWalletColor(wallet, color) {
    this.storage.get('wallet-meta-data').then(metadata => {
      metadata.forEach(item => {
        if (item.wallet === `${wallet.name}-${wallet.type}-${wallet.customerId}`) {
          item.color = color;
        }
      });
      this.storage.set('wallet-meta-data', metadata);
    });
  }

  public updateSequence(from, to) {
    return this.storage.get('wallet-meta-data').then(metadata => {
      metadata = this.arrayMove(metadata, from, to);
      metadata.forEach((item, index) => {
        item.sequence = index;
      });
      return this.storage.set('wallet-meta-data', metadata).then(() => 'done');
    });
  }

  arrayMove(arr, oldIndex, newIndex) {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  public reOrderWallets(wallets) {
    return this.storage.get('wallet-meta-data').then(metadata => {
      const newOrder = [];
      metadata.forEach(item => {
        const updatedWallet = wallets.filter(wallet => wallet.metaData.wallet === item.wallet)[0];
        updatedWallet.metaData = item;
        newOrder.push(updatedWallet);
      });
      return newOrder;
    });
  }

  public walletDefaultColors() {
    return this.colors.colors().slice(0, 7);
  }
}
