import {Injectable} from '@angular/core';
import {TypeFormAPIService} from './API/TypeFormAPIService';

@Injectable({
  providedIn: 'root'
})
export class TypeFormService {

  constructor(private api: TypeFormAPIService) {
  }

  public typeForms() {
    return this.api.typeForms();
  }

  public typeForm(typeFormId: number) {
    return this.api.typeForm(typeFormId);
  }

  public typeFormAsnwers(typeFormId: number) {
    return this.api.typeFormAnswers(typeFormId);
  }
}
