import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {RoomService} from '../../../../services/Rooms/RoomService';
import {DisplayService} from '../../../../services/Displays/DisplayService';
import {DateTime} from 'luxon';
import {ModuleService} from '../../../../services/Modules/ModuleService';
import {AssetTypes} from '../../../../services/Assets/AssetTypes';

@Component({
  selector: 'app-room-container',
  templateUrl: './room-container.component.html',
  styleUrls: ['./room-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RoomContainerComponent implements OnInit {
  @Input() roomId: number;
  @Input() selectedButton: string;
  @Output() roomNameChanged = new EventEmitter<string>();
  doorPmsIds: Array<number> = [];
  doorNames: Array<string> = [];
  doorPmsIdsCorrected: Array<number> = [];
  displayId: number;
  hasDoor = false;
  hasLights = false;
  hasHVAC = false;
  hasBlinds = false;
  hasDisplays = false;
  meetings: Array<any> = [];
  assetIds = {
    lights: String(AssetTypes.lights),
    display: String(AssetTypes.tv),
    door: String(AssetTypes.loxoneDoor),
    hvac: String(AssetTypes.ventilation),
    blinds: String(AssetTypes.blinds)
  };
  displays: Array<any>;
  remote: any;
  showControls = false;
  loaded = false;
  hasMeetings = false;
  room: any;
  currentNavigation = 'room';
  navigationItems = [];

  constructor(private service: RoomService, private display: DisplayService,
              public modules: ModuleService, private change: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.service.room(this.roomId).then(room => {
      this.room = room;
      this.hasMeetings = ['EducationSpace', 'MeetSpace'].includes(room.type);
      this.setNavigation();
      room.bookings.forEach(meeting => {
        this.meetings.push({
          from: DateTime.fromFormat(meeting.dateFrom, 'yyyy-LL-dd HH:mm:ss.SSS').toFormat('HH:mm'),
          till: DateTime.fromFormat(meeting.dateTill, 'yyyy-LL-dd HH:mm:ss.SSS').toFormat('HH:mm'),
          customer: meeting.customer_name,
        });
      });
      this.roomNameChanged.emit(room.name);
      if (room.assets_in_the_room) {
        this.checkAssets(room.assets_in_the_room);
      }
      this.loaded = true;
    });
    this.showControls = this.selectedButton === 'tv';
  }

  setNavigation() {
    this.navigationItems = [
      {
        name: 'room',
        active: true,
      },
      {
        name: 'bookings',
        active: this.hasMeetings && this.modules.has('booking'),
      }
    ];
  }

  checkAssets(assets) {
    this.checkDoors(assets.filter(a => a.assetType_id === this.assetIds.door));
    this.checkLights(assets.filter(a => a.assetType_id === this.assetIds.lights));
    this.checkBlinds(assets.filter(a => a.assetType_id === this.assetIds.blinds));
    this.checkHVAC(assets.filter(a => a.assetType_id === this.assetIds.hvac));
    this.checkDisplays(assets.filter(a => a.assetType_id === this.assetIds.display));
  }

  checkDoors(doors) {
    doors.forEach(door => {
      const doorPmsId = parseInt(door.PMScontrol_id, 10) ?? null;
      this.hasDoor = !this.hasDoor; //only set hasDoor when amount of doors is odd
      this.doorPmsIds.push(doorPmsId);
      this.doorNames.push(door.name);
    });
    const newDoors = [...this.doorPmsIds];
    newDoors.pop();
    this.doorPmsIdsCorrected = this.doorPmsIds.length % 2 ? newDoors : this.doorPmsIds;
  }

  checkLights(lights) {
    this.hasLights = typeof lights[0] !== 'undefined';
  }

  checkBlinds(blinds) {
    this.hasBlinds = typeof blinds[0] !== 'undefined';
  }

  checkHVAC(hvac) {
    this.hasHVAC = typeof hvac[0] !== 'undefined';
  }

  checkDisplays(displays) {
    this.hasDisplays = typeof displays[0] !== 'undefined';
    if (this.hasDisplays) {
      this.getDisplays();
      this.navigationItems = [
        {
          name: 'tv',
          active: true,
        },
        ...this.navigationItems
      ];
    }
  }

  getDisplays() {
    this.display.displays(this.roomId).then(displays => {
      this.displays = displays;
      this.setDisplay(this.displays[0].id);
    });
  }

  setDisplay(id) {
    this.displayId = id;
    this.display.remote(this.displayId).then((remote) => {
      this.remote = remote;
      this.change.detectChanges();
    });
  }

  currentNavigationChanged(tab) {
    this.currentNavigation = tab;
    this.change.detectChanges();
  }
}
