/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {Geolocation} from '@capacitor/geolocation';
import {Device} from '@capacitor/device';
import {AppVersion} from '@awesome-cordova-plugins/app-version/ngx';
import {LocationApiService} from './API/LocationApiService';
import {AndroidPermissions} from '@awesome-cordova-plugins/android-permissions/ngx';
import {Platform} from '@ionic/angular';
import {LocalNotifications} from '@capacitor/local-notifications';
import Bugsnag from '@bugsnag/js';
import {BehaviorSubject} from 'rxjs';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let WifiWizard2: any;

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  location = new BehaviorSubject<any>(null);

  constructor(private version: AppVersion, private api: LocationApiService,
              private permissions: AndroidPermissions, private platform: Platform) {
  }

  public get() {
    return Geolocation.getCurrentPosition().then(position => {
      const location = {lat: position.coords.latitude, lng: position.coords.longitude};
      this.location.next(location);
      return location;
    });
  }

  public async log() {
    let hasLocationPermission = true;
    if (this.platform.is('android')) {
      const result = await this.permissions.checkPermission(this.permissions.PERMISSION.ACCESS_FINE_LOCATION);
      hasLocationPermission = result.hasPermission;
    }

    const idInfo = await Device.getId();
    const deviceInfo = await Device.getInfo();
    const version = await this.version.getVersionNumber();//
    const deviceId = idInfo.identifier; //
    const os = deviceInfo.operatingSystem;
    const osVersion = deviceInfo.osVersion;
    const model = deviceInfo.model;
    const platform = deviceInfo.platform;
    const notificationPermission = (await LocalNotifications.checkPermissions()).display === 'granted';

    let lat = null;
    let lng = null;
    let BSSID = null;
    let SSID = null;
    if (hasLocationPermission) {
      try {
        const coords = await this.get();
        lat = coords.lat;
        lng = coords.lng;
      } catch (err) {
        Bugsnag.notify(err);
      }

      try {
        BSSID = await WifiWizard2.getConnectedBSSID();
        SSID = await WifiWizard2.getConnectedSSID();
      } catch (err) {
        Bugsnag.notify(err);
      }
    }

    const deviceSession = {
      browser: 'APP',
      os,
      osVersion,
      model,
      platform,
    };

    return this.api.log(deviceId, version, BSSID, SSID, lng, lat, deviceSession, notificationPermission);
  }
}
