import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NotificationService} from '../../services/Notifications/NotificationService';
import {ModalController, ViewWillLeave} from '@ionic/angular';
import {NotificationCenterModalComponent} from '../../modals/notification-center/notification-center-modal.component';
import {enterAnimation, leaveAnimation} from '../../animations/right-side-modal-animations';


@Component({
  selector: 'app-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.scss'],
})
export class NotificationBellComponent implements ViewWillLeave, OnInit {
  messages: number;

  constructor(private notificationModal: ModalController, private change: ChangeDetectorRef,
              private notifications: NotificationService) {
  }

  ionViewWillLeave() {
    this.notifications.count.unsubscribe();
  }

  notificationCenterModal(app = this) {
    this.notificationModal.create({
      component: NotificationCenterModalComponent,
      enterAnimation,
      leaveAnimation,
    }).then(modal => {
      modal.present().then(() => {
        const handle = document.querySelector('.close-modal');
        handle.addEventListener('click', () => {
          modal.dismiss();
        });
      });
    });
  }
  ngOnInit() {
    this.notifications.count.subscribe(count => {
      this.messages = count;
      this.change.detectChanges();
    });
  }
}
