/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {App} from '@capacitor/app';
import {ApiService} from './ApiService';

declare const Pusher: any;

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  key = '5d6c4dbd07858afa13c3';
  liveKey = '5d6c4dbd07858afa13c3';
  // key = '3843e4761ed6b9543721'; //BETA key
  betaKey = '3843e4761ed6b9543721'; //BETA key
  pusher: any;
  api: ApiService;

  constructor() {
  }

  setApiService(service: ApiService) {
    this.api = service;
  }

  updateKey() {
    const key = this.isBeta() ? this.betaKey : this.liveKey;
    if (key !== this.key) {
      this.key = key;
      this.pusher = new Pusher(this.key, {cluster: 'eu', encrypted: true});
    } else if (!this.pusher) {
      this.pusher = new Pusher(this.key, {cluster: 'eu', encrypted: true});
    }
  }

  listen(channelId) {
    return this.pusher.subscribe(channelId);
  }

  stopListening(channelId) {
    this.pusher.unsubscribe(channelId);
  }

  isBeta() {
    return this.api.url && (this.api.url.includes('uat') || this.api.url.includes('beta'));
  }

}
