/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../General/ApiService';

@Injectable({
  providedIn: 'root'
})
export class TypeFormAPIService {
  constructor(private api: ApiService) {
  }

  public typeForms() {
    return this.api.get('typeforms');
  }

  public typeForm(typeFormId: number) {
    return this.api.get(`typeforms/${typeFormId}`);
  }

  public typeFormAnswers(typeFormId: number) {
    return this.api.get(`typeforms/${typeFormId}/answers`);
  }

}
