import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {TypeFormPage} from './type-form.page';
import {TypeFormPageRoutingModule} from './type-form-routing.module';
import {AppComponentsModule} from '../../app_components.module';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        TypeFormPageRoutingModule,
        AppComponentsModule,
    ],
  declarations: [TypeFormPage],
})
export class TypeFormPageModule {}
