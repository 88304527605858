/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../General/ApiService';

@Injectable({
  providedIn: 'root'
})
export class SeatReservationApiService {
  constructor(private api: ApiService) {
  }

  public cancel(reservationId: number) {
    const params = {
      reservation_id: reservationId,
    };

    return this.api.post('seat_reservation/delete', params);
  }

  public availability(date: string) {
    const params = {
      date,
      homeIncluded: true,
    };
    return this.api.get('v3/seat-reservation/availability', params);
  }

  public availabilityByWeek(startOfWeek: string) {
    const params = {
      startOfWeek
    };
    return this.api.get('v3/seat-reservation/availability/weekly', params);
  }

  public create(day: string, type: string, propertyId: number, roomId: number) {
    const params = {
      date: day,
      type,
      property: propertyId,
      room_id: roomId,
    };
    return this.api.post('seat_reservation/reserve', params);
  }

  public seat(seatId) {
    return this.api.get(`seat/${seatId}`);
  }

  public seatFromKiosk(kioskId, hash) {
    return this.api.post(`v2/seat_reservation/kiosk/${kioskId}/seat-info`, {data: hash});
  }
}
