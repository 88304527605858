import {Injectable} from '@angular/core';
import {ToastService} from '../General/ToastService';
import {ModalController, Platform} from '@ionic/angular';
import {WifiModalComponent} from '../../modals/wifi/wifi-modal.component';
import {ModuleService} from '../Modules/ModuleService';
import {BehaviorSubject} from 'rxjs';
import {NetworkPingService} from './NetworkPingService';
import {ApiService} from '../General/ApiService';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  modal = undefined;
  connected = new BehaviorSubject<boolean>(true);
  previousStatus = true;
  connectedSubscription: any;

  constructor(private toaster: ToastService, private modalCtrl: ModalController,
              private modules: ModuleService, private ping: NetworkPingService,
              private api: ApiService, private platform: Platform,
              private router: Router) {
    this.init();
    this.api.setNetworkService(this);
  }

  init() {
    this.setNetworkListener();
    this.platform.resume.subscribe(() => {
      this.connected.next(true);
      this.previousStatus = true;
      this.toaster.dismissPermanentToast();
      this.setNetworkListener();
    });
    this.platform.pause.subscribe(() => {
      if (this.connectedSubscription) {
        this.connectedSubscription.unsubscribe();
      }
      this.previousStatus = true;
      this.connected.next(true);
      this.toaster.dismissPermanentToast();
    });
    this.setPingListener();
  }

  public async connectToWifiPopup(app = this) {
    this.modal = await app.modalCtrl.create({
      component: WifiModalComponent,
      initialBreakpoint: .60,
      breakpoints: [0, .60, 1]
    });
    this.modal.present().then(() => {
      const handle = document.querySelector('.show-modal').shadowRoot.querySelector('.modal-handle');
      handle.addEventListener('click', () => {
        this.modal.dismiss();
      });
    });

    const data = await this.modal.onWillDismiss();
  }

  private setNetworkListener() {
    if (this.connectedSubscription) {
      this.connectedSubscription.unsubscribe();
    }
    setTimeout(() => {
      this.connectedSubscription = this.connected.subscribe(status => {
        if (this.router.url !== '/') {
          if (status) {
            this.ping.skipCycle = true;
            this.toaster.dismissPermanentToast();
          }
          if (status !== this.previousStatus && !status) {
            this.setOffNetworkMessage(this);
          }
          this.previousStatus = status;
        }
      });
    }, 1000);
  }

  private setOffNetworkMessage(app) {
    if (this.modules.has('wifi_credentials')) {
      app.toaster.toastPermanent(
        'You are offline and might have limited functionality',
        {
          text: 'Connect to Wi-Fi',
          handler: () => {
            app.connectToWifiPopup(app);
          }
        }, 'wifi-disconnect');
    } else {
      app.toaster.toastPermanent(
        'You are offline and might have limited functionality', null, 'wifi-disconnect');
    }
  }

  private setPingListener() {
    this.ping.pingStream.subscribe(ping => {
    });
  }
}
