/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../General/ApiService';

@Injectable({
  providedIn: 'root'
})
export class NotificationApiService {
  constructor(private api: ApiService) {
  }

  public notificationCount() {
    return this.api.get('notification/countUnread');
  }

  public notifications(page: number) {
    const params = {
      page
    };
    return this.api.get('notification/my-notifications', params);
  }

  public markNotificationsAsRead() {
    return this.api.post('notification/markAsRead');
  }

  public sendNotificationToOrganizer(id: number, message: string) {
    const params = {
      roomBooking_id: id,
      message,
    };
    return this.api.post('notification/markAsRead', params);
  }
}
