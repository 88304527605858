import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {UserService} from '../../services/User/UserService';
import {PropertyService} from '../../services/Properties/PropertyService';
import {CacheService} from '../../services/Cache/CacheService';
import {ButtonInterface} from '../../components/buttons/button-switch/button.interface';
import {StorageService} from '../../services/Storage/StorageService';
import {FilterService} from './FilterService';
import {MeetingRoomSort} from '../../pages/meet/components/container/meeting-room-sort.interface';

@Component({
  selector: 'app-cancel-meeting',
  templateUrl: './meet-filter-modal.component.html',
  styleUrls: ['./meet-filter-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class MeetFilterModalComponent implements OnInit {
  @Input() meeting: any;
  disableBtn = false;
  deleteNativeEvent = true;
  type = 'filters';
  properties: any;
  selectedProperty: any;
  locations: Array<ButtonInterface> = [];
  filters: any;
  meetboxHasTV = false;
  meetboxHasWhiteboard = false;
  meetboxHasTeams = false;
  capacityFrom = 2;
  sort: MeetingRoomSort = {sortBy: null, sortDirection: null};
  sortItems = [
    {
      name: 'capacity',
      icon: 'seat',
      title: 'Meetbox seats'
    },
    {
      name: 'price',
      icon: 'euro',
      title: 'Pricing'
    },
    {
      name: 'shortname',
      icon: 'numbered_list',
      title: 'MeetBox Number'
    }
  ];

  constructor(
    private user: UserService,
    private propertyService: PropertyService,
    private cache: CacheService,
    private modalCtrl: ModalController,
    private storage: StorageService,
    private filterService: FilterService
  ) {
  }

  ngOnInit() {
    this.getProperties();
    this.getFilters();
  }

  getFilters() {
    this.filters = this.filterService.filter.value;
    this.selectedProperty = this.filters.property;
    this.meetboxHasTeams = this.filters.meetboxHasTeams;
    this.meetboxHasWhiteboard = this.filters.meetboxHasWhiteboard;
    this.meetboxHasTV = this.filters.meetboxHasTV;
    this.capacityFrom = this.filters.capacityFrom ?? 2;
    if(this.filters.sort){
      this.sort = this.filters.sort;
    }
  }

  getProperties() {
    this.cache.get('personal-properties', this.propertyService.properties()).then(properties => {
      this.properties = properties;
    });
  }

  selectProperty(property) {
    this.selectedProperty = property;
  }

  closeFilters() {
    this.modalCtrl.dismiss('cancel');
  }

  toggleFilterSorter() {
    if (this.type === 'sorters') {
      this.type = 'filters';
    } else {
      this.type = 'sorters';
    }
  }

  applyFilters() {
    this.modalCtrl.dismiss();
    this.setFilters();
  }

  setFilters() {
    this.filters = this.filterService.filter;
    this.storage.set('filter-property', this.selectedProperty);
    this.filters.next({
      property: this.selectedProperty,
      meetboxHasTV: this.meetboxHasTV,
      meetboxHasWhiteboard: this.meetboxHasWhiteboard,
      meetboxHasTeams: this.meetboxHasTeams,
      capacityFrom: this.capacityFrom,
      sort: this.sort,
    });
  }

  clearFilters() {
    this.meetboxHasTV = false;
    this.meetboxHasTeams = false;
    this.capacityFrom = null;
    this.resetSort();
    this.applyFilters();
  }

  peopleFilter(amount: number) {
    this.capacityFrom = amount;
  }

  resetSort(){
    this.sort = {sortBy: null, sortDirection: null}
  }

  applySorting(type) {
    if (this.sort.sortBy !== type) {
      this.sort.sortBy = type;
      this.sort.sortDirection = 'asc';
    } else {
      if (this.sort.sortDirection === 'asc') {
        this.sort.sortDirection = 'desc';
      } else {
        this.resetSort();
      }
    }
  }
}
