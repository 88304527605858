import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {ToastService} from '../../../../services/General/ToastService';
import {MeetingService} from '../../../../services/Meetings/MeetingService';
import {CancelMeetingModalComponent} from '../../../../modals/cancel-meeting/cancel-meeting-modal.component';
import {CalendarService} from '../../../../services/Calendars/CalendarService';
import {DateTime} from 'luxon';
import {MeetingDateFilter} from '../../../meet/components/date/meeting-date-filter.interface';
import {AlternativeRoomsModalComponent} from '../../modals/alternative-rooms/alternative-rooms-modal.component';
import Bugsnag from '@bugsnag/js';
import {StorageService} from '../../../../services/Storage/StorageService';
import {ModuleService} from '../../../../services/Modules/ModuleService';

@Component({
  selector: 'app-meeting-actions',
  templateUrl: './meeting-actions.component.html',
  styleUrls: ['./meeting-actions.component.scss']
})

export class MeetingActionsComponent implements OnChanges {
  @Input() meeting: any;
  @Input() meetingInFuture = false;
  @Input() isNative = false;
  @Input() host = false;
  @Output() reloadRequired = new EventEmitter<void>();
  canCancelMeeting = false;
  canMoveMeeting = false;
  orderAllowed = false;
  cancelConfirmationModal = false;
  meetingEnded = false;
  meetingAboutToStart = false;
  fromExistingEvent = false;
  modal: any;
  hasCalendarEvent = false;

  constructor(private service: MeetingService, private nav: NavController,
              private toaster: ToastService, private calendar: CalendarService,
              private modalCtrl: ModalController, private meetingService: MeetingService,
              private storage: StorageService, public modules: ModuleService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.meeting) {
      this.canMoveMeeting = this.meeting.moveAllowed;
      this.canCancelMeeting = this.meeting.cancellationAllowed;
      this.orderAllowed = this.meeting.orderAllowed;
      this.fromExistingEvent = this.meeting.existingEvent_id !== null && this.meeting.existingEvent_id !== '';
      this.meetingEnded = DateTime.now() > this.meeting.till;
      this.meetingAboutToStart = this.meeting.from < DateTime.now().plus({minute: 15});
      this.checkCalendarEvents();
    }
  }

  async showCancelConfirmation() {
    this.modal = await this.modalCtrl.create({
      component: CancelMeetingModalComponent,
      componentProps: {
        meeting: this.meeting,
        isNative: this.isNative
      },
      initialBreakpoint: .60,
      breakpoints: [0, .70]
    });
    this.modal.present().then(() => {
      const handle = document.querySelector('.show-modal').shadowRoot.querySelector('.modal-handle');
      handle.addEventListener('click', () => {
        this.modal.dismiss();
      });
    });
  }

  checkCalendarEvents() {
    if (!this.isNative) {
      this.calendar.getEvents(
        this.meeting.from.toJSDate(),
        this.meeting.till.toJSDate(),
        true).then(events => {
        this.hasCalendarEvent = events.some(event => event.note.includes(`MD-${this.meeting.id}`));
      });
    }
  }

  addToCalendar() {
    this.service.calendarInformation(this.meeting.id).then(calendarData => {
      this.calendar.calendars(true).then(calendars => {
        if (calendars.length > 0) {
          this.storage.get('default-calendar').then(defaultCalendar => {
            if (!defaultCalendar) {
              this.storage.set('default-calendar', calendars[0]);
              defaultCalendar = calendars[0];
            }
            this.calendar.createEvent(
              calendarData.title,
              calendarData.location,
              calendarData.description,
              this.meeting.from,
              this.meeting.till,
              defaultCalendar.id
            )
              .then(eventId => {
                this.meeting.existingEvent_id = eventId;
                if (this.host) {
                  this.meetingService.setNativeEventId(this.meeting.id, eventId);
                  if (this.meeting.attendees.length) {
                    this.calendar.addEventAttendees(eventId, this.meeting.attendees);
                  }
                }
                this.toaster.toast('Event Created');
                this.checkCalendarEvents();
              });
          });
        } else {
          this.toaster.toast('You have no active calendars');
        }
      });
    });
  }

  moveMeeting() {
    this.nav.navigateBack('/meet', {
      state: {
        action: 'move',
        meetingId: this.meeting.id,
        eventId: this.meeting.existingEvent_id,
        from: this.meeting.from.toMillis(),
        till: this.meeting.till.toMillis(),
        propertyId: this.meeting.room.property.id,
        capacity: parseInt(this.meeting.capacity, 10),
        subject: this.meeting.subject !== this.meeting.room.name ? this.meeting.subject : null,
        description: this.meeting.description,
        tokenPrice: this.meeting.tokenPrice,
      },
    });
  }

  async bookAlternative() {
    const dateFilter: MeetingDateFilter = {
      date: this.meeting.from,
      from: this.meeting.from,
      till: this.meeting.till,
    };
    try {
      const rooms = await this.meetingService.availableMeetingRooms(
        dateFilter,
        this.meeting.capacity,
        this.meeting.room.property_id,
        {sortBy: null, sortDirection: null},
        false,
        false,
        false,
        null,
        null,
        false,
        true
      );
      const sameCapRooms = this.formatRooms(rooms.filter(room => room.maxCapacity === this.meeting.capacity));
      const schedules = await this.getSchedules(sameCapRooms);
      this.modal = await this.modalCtrl.create({
        component: AlternativeRoomsModalComponent,
        componentProps: {
          rooms: sameCapRooms,
          meeting: this.meeting,
          schedules,
        },
        initialBreakpoint: 1,
        breakpoints: [0, 1]
      });
      this.modal.present().then(() => {
        const handle = document.querySelector('.show-modal').shadowRoot.querySelector('.modal-handle');
        handle.addEventListener('click', () => {
          this.modal.dismiss();
        });
      });
      const {data} = await this.modal.onWillDismiss();
      if (data.room) {
        this.updateMeetingRoom(data.room);
      }
    }
    catch(err) {
      Bugsnag.notify(err);
      this.toaster.toast('No available alternative rooms for this time slot');
    }

  }

  formatRooms(rooms) {
    return rooms.map(room => (this.meetingService.formatRoom(room)));
  }

  async getSchedules(rooms) {
    return await this.meetingService.getScheduledMeetingsForIds(
      this.meeting.from.startOf('day'),
      this.meeting.from.endOf('day'),
      rooms.map(r => r.id));
  }

  updateMeetingRoom(room) {
    this.meetingService.replaceMeetingRoom(this.meeting.id, room.id).then(response => {
      this.toaster.toast(response.message);
      this.nav.navigateBack('/refresh', {skipLocationChange: true, animated: false}).then(() => {
        this.nav.navigateBack(['/meeting', {id: this.meeting.id}]);
      });
    }).catch(err => {
      Bugsnag.notify(err);
      this.toaster.toast(err.error?.error);
    });
  }

  makeRecurring() {
    //TODO make recurring
  }

  roomOptions() {
    this.nav.navigateForward(['/room', {id: this.meeting.room.id, canGoBack: true}]);
  }

  catering() {
    this.nav.navigateForward(['/catering'], {
      state: {
        meetingId: this.meeting.id,
        date: this.meeting.from,
      }
    });
  }

  editNativeMeeting() {
    this.calendar.updateEventInteractively(this.meeting.originalNativeEvent.id).then(() => {
      this.reloadRequired.emit();
    });
  }

  getOrderItemText(item) {
    return parseInt(item.amount, 10) + 'x ' + item.article.name;
  }
}
