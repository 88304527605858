import {AnimationController} from '@ionic/angular';

export const enterAnimation = (baseEl: HTMLElement) => {
  const animationCtrl = new AnimationController();

  const root = baseEl.shadowRoot;

  const backdropAnimation = animationCtrl
    .create()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .addElement(root.querySelector('ion-backdrop')!)
    .beforeStyles({background: 'rgb(0 0 0 / 0.1)'})
    .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

  const wrapperAnimation = animationCtrl
    .create()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .addElement(root.querySelector('.modal-wrapper')!)
    .beforeStyles({transform: 'translateX(100%);', opacity: 1})
    .fromTo('transform', 'translateX(100%)', 'translateX(10%)')
    .fromTo('background', 'transparent', 'transparent');

  return animationCtrl
    .create()
    .addElement(baseEl)
    .easing('ease-out')
    .duration(300)
    .addAnimation([backdropAnimation, wrapperAnimation]);
};

export const leaveAnimation = (baseEl: HTMLElement) =>
  enterAnimation(baseEl).direction('reverse');
