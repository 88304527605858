import {Component, OnInit} from '@angular/core';
import {TypeFormService} from '../../services/TypeForms/TypeFormService';
import {Location} from '@angular/common';
import {UserService} from '../../services/User/UserService';
import {createWidget} from '@typeform/embed';

@Component({
  selector: 'app-type-form',
  templateUrl: 'type-form.page.html',
  styleUrls: ['type-form.page.scss']
})
export class TypeFormPage implements OnInit {
  form: any;
  userId: number;

  constructor(private service: TypeFormService, private location: Location, private user: UserService) {
  }

  ngOnInit() {
    this.user.profile().then(profile => {
      this.userId = profile.id;
      // @ts-ignore
      const {id} = this.location.getState();
      this.service.typeForm(id).then(typeForm => {
        this.form = typeForm;
        this.handleTypeFormWidget();
      });
    });
  }

  handleTypeFormWidget() {
    setTimeout(() => {
      const container = document.getElementById('typeform');
      createWidget(this.form.typeform_id, {
        container,
        inlineOnMobile: true,
        width: container.clientWidth,
        height: container.clientHeight,
        iframeProps: {width: '100%', height: '100%'},
        hidden: {userid: `${this.userId}`}
      });
    }, 200);
  }
}
