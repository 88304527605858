import {Component, EventEmitter, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {IonModal, ModalController} from '@ionic/angular';
import {NotificationService} from '../../services/Notifications/NotificationService';
import {Notification} from '../../services/Notifications/notification.interface';
import {CacheService} from '../../services/Cache/CacheService';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center-modal.component.html',
  styleUrls: ['./notification-center-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class NotificationCenterModalComponent {
  @Output() connected = new EventEmitter<any>();
  @ViewChild(IonModal) modal: IonModal;
  nextNotificationPage = 1;
  nextNewsPage = 1;
  allNotifications: Array<Notification> = [];
  todayNotifications: Array<Notification> = [];
  olderNotifications: Array<Notification> = [];
  news: Array<Notification> = [];
  visibleNotifications = 'Notifications';

  constructor(private modalCtrl: ModalController, private service: NotificationService,
              private cache: CacheService) {
    this.init();
  }

  init() {
    this.getNotifications();
  }

  getNotifications() {
    this.cache.getWithDelayedObservable(
      'notifications', this.service.notifications(this.nextNotificationPage), 60 * 60 * 24 * 5)
      .subscribe(notifications => {
        const notificationsArray = this.reformatNotifications(notifications.notifications);
        if (notifications.currentPage === 1) {
          this.allNotifications = notificationsArray;
        } else {
          this.allNotifications = [...this.allNotifications, ...notificationsArray];
        }
        this.formatToShortMessage();
        this.nextNotificationPage = notifications.nextPage;
        this.service.markNotificationsAsRead();
        this.todayNotifications = [];
        this.olderNotifications = [];
        this.allNotifications.forEach(notification => {
          if(notification.date.hasSame(DateTime.now(),'day')){
            this.todayNotifications.push(notification);
          } else {
            this.olderNotifications.push(notification);
          }
        });
      });
  }

  reformatNotifications(notifications) {
    notifications.forEach(notification => {
      if (typeof notification.date === 'string') {
        notification.date = DateTime.fromISO(notification.date);
        notification.validTill = notification.validTill ? DateTime.fromISO(notification.validTill) : null;
      }
    });
    return notifications;
  }

  handleVisibleNotifications(type) {
    this.visibleNotifications = type;
  }

  formatToShortMessage() {
    setTimeout(() => {
      this.allNotifications.forEach((item, index) => {
        const lines = this.extractLinesFromTextNode(document.querySelectorAll('.notification-item-body-hidden')[index].firstChild);
        item.shortMessage = lines.slice(0, 3).join(' ');
        if (lines.length > 3) {
          item.more = true;
          item.shortMessage = item.shortMessage.slice(0, -2) + '..';
        }
      });
    }, 0);
  }

  extractLinesFromTextNode(textNode) {
    if (textNode.nodeType !== 3) {
      throw(new Error('Lines can only be extracted from text nodes.'));
    }
    textNode.textContent = this.collapseWhiteSpace(textNode.textContent);
    const textContent = textNode.textContent;
    let lines = [];
    let lineCharacters = [];
    const range = document.createRange();
    for (let i = 0; i < textContent.length; i++) {
      range.setStart(textNode, 0);
      range.setEnd(textNode, (i + 1));
      const lineIndex = (range.getClientRects().length - 1);
      if (!lines[lineIndex]) {
        lines.push(lineCharacters = []);
      }
      lineCharacters.push(textContent.charAt(i));
    }
    lines = lines.map(
      characters => (this.collapseWhiteSpace(characters.join('')))
    );
    if (lines[0].length < 2) {
      return [textNode.textContent];
    }
    return (lines);
  }

  collapseWhiteSpace(value) {
    return (value.trim().replace(/\s+/g, ' '));
  }
}
