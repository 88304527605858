import {Injectable} from '@angular/core';
import {AssetsApiService} from './API/AssetsApiService';
import {AssetTypes} from './AssetTypes';
import {ToastService} from '../General/ToastService';
import {NavController} from '@ionic/angular';
import {UrlActionService} from '../General/UrlActionService';
import {ScanDataService} from '../Scan/ScanDataService';
import {PaymentModalService} from '../Payment/PaymentModalService';
import Bugsnag from '@bugsnag/js';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {
  scanDataService: ScanDataService;

  constructor(private api: AssetsApiService, private toaster: ToastService,
              private urlActionService: UrlActionService, private nav: NavController,
              private paymentModal: PaymentModalService, private location: Location) {
  }

  public asset(assetId: number, tenant: string = null) {
    return this.api.asset(assetId, tenant);
  }

  public types() {
    return this.api.types();
  }

  public create(roomId: number, name: string, assetTypeId: number, photo: File = null) {
    return this.api.create(roomId, name, assetTypeId, photo);
  }

  public processAsset(assetId, tagId = null, tenant = null) {
    this.asset(assetId, tenant).then(asset => {
      const assetTypeId = parseInt(asset.assetType_id, 10);
      const assetTypeParentId = parseInt(asset.assetTypeParent_id, 10);
      if (assetTypeId === AssetTypes.printer) {
        asset.printer.name = asset.name;
        this.isPrinter(asset.printer, tagId);
      } else if (assetTypeParentId === AssetTypes.vendingControl) {
        this.isTcnAuthentication(asset);
      } else if (assetTypeId === AssetTypes.loxoneDoor || assetTypeId === AssetTypes.saltoDoor) {
        this.isDoor(asset, tagId);
      } else if (assetTypeId === AssetTypes.barrier) {
        this.isBarrier(asset, tagId);
      } else if (assetTypeId === AssetTypes.sharedBike) {
        this.isSharedBike(asset, tagId);
      } else if (assetTypeId === AssetTypes.charger) {
        this.isCharger(asset, tagId);
      }
      else {
        this.handleAssetInformation(asset, tagId);
      }
    }).catch(err => {
      Bugsnag.notify(err);
      if (err.error.message) {
        this.toaster.toast(err.error.message);
      } else {
        this.toaster.toast('Asset not found');
      }
      this.nav.navigateRoot('/home');
    });
  }


  async handleAssetFlexReservation(asset, tagId = null) {
    this.paymentModal.openModal({
      type: 'seatReservation',
      params: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        room_id: asset.flex_reservation.room_id,
        type: 'flex',
        date: asset.flex_reservation.date,
        property: asset.flex_reservation.property_id
      },
    }).then(result => {
      if (result.role === 'success') {
        this.toaster.toast('Seat successfully reserved', 'ok');
        this.scanDataService.handleAsset(asset.id, tagId);
      }
    });
  }

  private isPrinter(printer, tagId = null) {
    this.nav.navigateBack(['/print'], {
      state: {
        printer,
        tagId
      }
    });
  }

  private isSharedBike(asset, tagId = null) {
    this.nav.navigateBack(['/bike-sharing'], {state: {asset, tagId}});
  }
  private isCharger(asset, tagId = null) {
    this.nav.navigateBack(['/car-charging-location-detail'], {state: {asset, tagId}});
  }


  private isTcnAuthentication(asset) {
    const unlockAction = asset.actions.find(e => e.action === 'unlock' || e.action === 'tcn-auth');
    unlockAction.action = 'tcn-auth';
    this.urlActionService.handleAction(unlockAction);
    this.nav.navigateRoot('/home');
    this.nav.navigateForward('/tcn-confirmation');
  }

  private isDoor(asset, tagId = null) {
    const unlockAction = asset.actions.filter(e => e.action === 'unlock');
    if (asset.flex_reservation && !asset.flex_reservation.complete) {
      this.handleAssetFlexReservation(asset, tagId);
    } else {
      if (unlockAction.length > 0) {
        this.urlActionService.handleAction(unlockAction[0]);
      }
      this.handleAssetInformation(asset, tagId);
    }
  }

  private isBarrier(asset, tagId = null) {
    if (asset.actions) {
      const unlockAction = asset.actions.filter(e => e.action === 'open barrier');
      if (unlockAction.length > 0) {
        this.urlActionService.handleAction(unlockAction[0]);
      }
      this.handleAssetInformation(asset, tagId);
    } else {
      this.toaster.toast('Access denied');
    }
  }

  private handleAssetInformation(asset, tagId = null) {
    if(this.location.path() === '/asset') {
      this.nav.navigateBack('/refresh', {skipLocationChange: true, animated: false}).then(() => {
        if (asset.openWallet) {
            this.handleCoffeeAsset(asset);
        }else{
          this.nav.navigateBack(['/asset'], {state: {asset, tagId}});
        }
      });
    } else {
      if (asset.openWallet) {
        this.handleCoffeeAsset(asset);
      }else{
        this.nav.navigateBack(['/asset'], {state: {asset, tagId}});
      }
    }
  }

  private handleCoffeeAsset(asset) {
    // eslint-disable-next-line max-len
    const location = `${asset.room?.property?.name ?? ''}${asset.room?.property && asset.room?.floor !== null ? ', ' : ''}${asset.room?.floor !== null ? 'floor ' + asset.room?.floor : ''}`;
    const params = {
      asset: asset.id,
      mac: asset.p_m_scontrol.p_m_sserver.device_mac,
      showAmount: asset.amount >= 0,
      showQuantity: asset.showQuantity ? parseInt(asset.showQuantity, 10) : false,
      type: asset.p_m_scontrol.p_m_sserver.type,
      serverId: asset.p_m_scontrol.p_m_sserver.external_id,
      location,
    };

    this.nav.navigateForward(['/vending-machine-payment'], {state: {params}});
  }
}
