import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DirectionsService} from '../../../../services/Directions/DirectionsService';

@Component({
  selector: 'app-meeting-directions',
  templateUrl: './meeting-directions.component.html',
  styleUrls: ['./meeting-directions.component.scss']
})

export class MeetingDirectionsComponent implements OnChanges {
  @Input() meeting: any;
  distances: Array<any> = [];

  constructor(private directions: DirectionsService) {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.meeting) {
      this.directions.distance(
        {
          lng: parseFloat(this.meeting.room.property.longitude),
          lat: parseFloat(this.meeting.room.property.latitude)
        }
      ).then(distances => {
        this.distances = distances;
      });
    }
  }

  init() {
  }

  getModeText(mode) {
    switch (mode) {
      case 'DRIVING':
        return 'by car.';
      case 'TRANSIT':
        return 'by public transport.';
      case 'WALKING':
        return 'by foot.';
    }
  }
}
