/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {DateTime} from 'luxon';
import {MeetingService} from '../Meetings/MeetingService';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OfficeService {

  private static readonly OFFICE_SET = 'office_set';
  public office: any = null;
  private attendeeChangesSubject = new BehaviorSubject<any>([]);

  constructor(private meetService: MeetingService) {
  }

  setOffice(office: any) {
    this.office = office;
    console.log('setoffice');
    window.dispatchEvent(new Event(OfficeService.OFFICE_SET));
    this.office.context.mailbox.item.addHandlerAsync(
      this.office.EventType.RecipientsChanged,
      this.onRecipientsChanged.bind(this)
    );
  }

  onOfficeSet(handler: () => void) {
    window.addEventListener(OfficeService.OFFICE_SET, handler);
  }

  getTimes() {
    return new Promise((resolve) => {
      if (!this.office) {
        console.error('No appointment found. Error: Office not set.');
        return;
      }
      const appointment = this.office.context.mailbox.item;
      appointment.start.getAsync((startResult) => {
        if (startResult.status === this.office.AsyncResultStatus.Succeeded) {
          const startDate = startResult.value;
          appointment.end.getAsync((endResult) => {
            if (endResult.status === this.office.AsyncResultStatus.Succeeded) {
              const endDate = endResult.value;
              resolve({from: startDate, till: endDate});
            } else {
              throw new Error('Failed to get end date:' + endResult.error.message);
            }
          });
        } else {
          throw new Error('Failed to get start date:' + startResult.error.message);
        }
      });
    });
  }

  getLocation() {
    return new Promise((resolve) => {
      if (!this.office) {
        console.error('No appointment found. Error: Office not set.');
        return;
      }
      const appointment = this.office.context.mailbox.item;
      appointment.location.getAsync((locationResult) => {
        if (locationResult.status === this.office.AsyncResultStatus.Succeeded) {
          resolve(locationResult.value);
        } else {
          throw new Error('Failed to get location:' + locationResult.error.message);
        }
      });
    });
  }

  getSubject() {
    return new Promise((resolve) => {
      if (!this.office) {
        console.error('No appointment found. Error: Office not set.');
        return;
      }
      const appointment = this.office.context.mailbox.item;
      appointment.subject.getAsync((subjectResult) => {
        if (subjectResult.status === this.office.AsyncResultStatus.Succeeded) {
          resolve(subjectResult.value);
        } else {
          throw new Error('Failed to get subject:' + subjectResult.error.message);
        }
      });
    });
  }

  getAttendeeChanges(): Observable<any> {
    return this.attendeeChangesSubject.asObservable();
  }

  onRecipientsChanged() {
    const appointment = this.office.context.mailbox.item;
    appointment.requiredAttendees.getAsync((requiredAttendeesResult) => {
      const requiredAttendees = requiredAttendeesResult.value;
      appointment.optionalAttendees.getAsync((optionalAttendeesResult) => {
        const optionalAttendees = optionalAttendeesResult.value;
        this.attendeeChangesSubject.next([...requiredAttendees, ...optionalAttendees]);
      });
    });
  }

  getAttendees() {
    return new Promise((resolve) => {
      if (!this.office) {
        console.error('No appointment found. Error: Office not set.');
        return;
      }
      let attendees: any;
      const appointment = this.office.context.mailbox.item;
      appointment.requiredAttendees.getAsync((requiredAttendees) => {
        if (requiredAttendees.status === this.office.AsyncResultStatus.Succeeded) {
          attendees = requiredAttendees.value;
          appointment.optionalAttendees.getAsync((optionalAttendees) => {
            if (optionalAttendees.status === this.office.AsyncResultStatus.Succeeded) {
              attendees = [...attendees, ...optionalAttendees.value];
              resolve(attendees);
            } else {
              throw new Error('Failed to get optionalAttendees:' + optionalAttendees.error.message);
            }
          });
        } else {
          throw new Error('Failed to get requiredAttendees:' + requiredAttendees.error.message);
        }
      });
    });
  }

  setAppointment(m: any) {
    this.meetService.meeting(m.id).then(meeting => {
      console.log('Setting appointment', m, meeting);

      const from = DateTime.fromFormat(meeting.dateFrom, 'yyyy-LL-dd HH:mm:ss.SSS').toJSDate();
      const till = DateTime.fromFormat(meeting.dateTill, 'yyyy-LL-dd HH:mm:ss.SSS').toJSDate();
      const appointment = this.office.context.mailbox.item;

      // Set subject
      appointment.subject.setAsync(meeting.subject, (asyncResult) => {
        if (asyncResult.status === this.office.AsyncResultStatus.Failed) {
          console.error(asyncResult.error.message);
        } else {
          console.log('Subject set successfully.');
        }
      });

      // Set body
      try {
        appointment.body.setAsync(meeting.description, {coercionType: this.office.CoercionType.Html}, (asyncResult) => {
          if (asyncResult.status === this.office.AsyncResultStatus.Failed) {
            console.error(asyncResult.error.message);
          } else {
            console.log('Body set successfully.');
          }
        });
      } catch (e) {
        console.error('Failed to set body:', e);
      }

      // Set location
      let message = meeting.room.property.name + ', ' + meeting.room.property.street + ', ' + meeting.room.property.city;

      if (meeting.is_room_assignment_final === '1') {
        message = meeting.room.property.name + ' - ' +
          meeting.room.name + ', ' +
          meeting.room.property.street + ', ' +
          meeting.room.property.city + ', ' +
          meeting.room.property.country;
      }
      appointment.location.setAsync(
        message,
        (asyncResult) => {
          if (asyncResult.status === this.office.AsyncResultStatus.Failed) {
            console.error(asyncResult.error.message);
          } else {
            console.log('Location set successfully.');
          }
        });

      // Set start date
      appointment.start.setAsync(from, (asyncResult) => {
        if (asyncResult.status === this.office.AsyncResultStatus.Failed) {
          console.error(asyncResult.error.message);
        } else {
          console.log('Start date set successfully.');
        }
      });

      // Set end date
      appointment.end.setAsync(till, (asyncResult) => {
        if (asyncResult.status === this.office.AsyncResultStatus.Failed) {
          console.error(asyncResult.error.message);
        } else {
          console.log('End date set successfully.');
        }
      });


      appointment.requiredAttendees.getAsync((requiredAttendeeResult) => {
        if (requiredAttendeeResult.status === this.office.AsyncResultStatus.Succeeded) {
          const requiredAttendees = [...requiredAttendeeResult.value, {
            displayName: 'proximus-bot',
            emailAddress: 'digital-hq@outlook.com'
          }];

          appointment.requiredAttendees.setAsync(requiredAttendees, (asyncResult) => {
            if (asyncResult.status === this.office.AsyncResultStatus.Failed) {
              console.error(asyncResult.error.message);
            } else {
              console.log('Required attendees set successfully.');
              appointment.saveAsync((result: any) => {
                if (result.status === this.office.AsyncResultStatus.Failed) {
                  console.error('Failed to save the appointment:', asyncResult.error.message);
                } else {
                  console.log('Appointment saved successfully.', result.value);
                  this.meetService.setOutlookEventId(meeting.id, result.value);
                }
              });
            }
          });
        } else {
          throw new Error('Failed to get requiredAttendees:' + requiredAttendeeResult.error.message);
        }
      });
    });
  }
}
