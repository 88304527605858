import {Component, Input, ViewEncapsulation} from '@angular/core';
import {MeetBox} from '../../../../components/meetbox/meetbox.interface';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-alternative-rooms',
  templateUrl: './alternative-rooms-modal.component.html',
  styleUrls: ['./alternative-rooms-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class AlternativeRoomsModalComponent {
  @Input() rooms: MeetBox[] = [];
  @Input() meeting: any;
  @Input() schedules: any;

  constructor(private modalCtrl: ModalController) {
  }

  roomSelected(room) {
    this.modalCtrl.dismiss({room});
  }
}
