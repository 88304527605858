import {Component, ViewChild, ViewEncapsulation} from '@angular/core';
import {RoomService} from '../../services/Rooms/RoomService';
import {RoomControlService} from '../../services/Rooms/RoomControlService';
import {ToastService} from '../../services/General/ToastService';
import {Router} from '@angular/router';
import {DisplayService} from '../../services/Displays/DisplayService';
import {CacheService} from '../../services/Cache/CacheService';
import {AuthService} from '../../services/Auth/AuthService';
import {PropertyService} from '../../services/Properties/PropertyService';
import {LocationService} from '../../services/Location/LocationService';
import {SwiperComponent} from 'swiper/angular';
import {UserService} from '../../services/User/UserService';

@Component({
  selector: 'app-room-quick-access',
  templateUrl: './room-quick-access.component.html',
  styleUrls: ['./room-quick-access.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RoomQuickAccessComponent {
  @ViewChild('swiper', {static: false}) swiper?: SwiperComponent;
  rooms: Array<any> = null;
  unlocked: Array<number> = [];
  checkIn: { roomId: number; time: string };
  unlocking: number = null;

  constructor(
    private service: RoomService, private controls: RoomControlService,
    private display: DisplayService, private toaster: ToastService,
    private router: Router, private cache: CacheService,
    private auth: AuthService, private propertyService: PropertyService,
    private locationService: LocationService, private user: UserService,
  ) {
    this.init();
  }

  init() {
    this.cache.getWithDelayedObservable('personal-rooms', this.service.accessibleRooms()).subscribe(rooms => {
      this.rooms = this.mergeDuplicateRooms(rooms);
      this.scrollToRoom();
    });
    this.auth.checkedIn().then(checkIn => {
      this.checkIn = checkIn;
    });
  }

  mergeDuplicateRooms(rooms) {
    const mergedRooms = [];
    rooms.forEach(room => {
      if (!mergedRooms.length || !mergedRooms.filter(r => r.id === room.id).length) {
        room.doors = [{
          id: room.id,
          pmsId: room.PMScontrol_id,
          name: room.name,
        }];
        mergedRooms.push(room);
      } else {
        mergedRooms.filter(r => r.id === room.id)[0].doors.push({
          id: room.id,
          pmsId: room.PMScontrol_id,
          name: room.name,
        });
      }
    });
    return mergedRooms;
  }

  scrollToRoom() {
    //If a room with a meeting is present, it'll be first in the list so don't scroll
    if (this.rooms.some(room => room.meeting)) {
      return;
    }

    const offices = this.rooms.filter(room => room.roomType === 'office');
    //If no offices are found don't scroll
    if (offices.length === 0) {
      return;
    }

    //If all present offices have the same property use offices[0]
    if (offices.every(office => office.property_id === offices[0].property_id)) {
      setTimeout(() => {
        this.swiper.swiperRef.slideTo(this.rooms.indexOf(offices[0]) + 1, 0);
      });
      return;
    }

    const location = this.locationService.location.value;
    // If location is found, scroll to first office in the property closest by
    if (location) {
      this.cache.get('personal-properties', this.propertyService.properties()).then(properties => {
        const closestProperty = properties.reduce((closest, property) => {
          property.diff = Math.abs(parseFloat(property.latitude) - location.lat) +
            Math.abs(parseFloat(property.longitude) - location.lng);
          return (closest === null || closest.diff > property.diff) ? property : closest;
        }, null);
        const index = this.rooms.indexOf(offices.find(office => parseInt(office.property_id, 10) === closestProperty.id));
        this.swiper.swiperRef.slideTo(index !== -1 ? index + 1 : this.rooms.indexOf(offices[0]) + 1, 0);
      });
    } else {
      //If location is not found scroll to default property office
      this.user.profile().then(profile => {
        const index = this.rooms.indexOf(offices.find(office => office.property_id === profile.defaultProperty_id));
        this.swiper.swiperRef.slideTo(index !== -1 ? index + 1 : this.rooms.indexOf(offices[0]) + 1, 0);
      });
    }
  }

  unlock(event, roomId: number, pmsId: number, index: number) {
    event.stopPropagation();
    this.unlocking = index;
    this.controls.unlock(roomId, pmsId).then(response => {
      this.toaster.toast(response.message);
      if (!this.unlocked.includes(roomId)) {
        this.unlocked.push(roomId);
      }
    }).finally(() => {
      this.unlocking = null;
    });
  }

  roomAccess(room) {
    if (room.meeting) {
      this.router.navigate(['/meeting', {id: room.meeting.id}]);
    } else {
      this.router.navigate(['/room', {id: room.id}]);
    }
  }
}
