/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ToastButton, ToastController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  permaToasts: Array<any> = [];

  constructor(private toaster: ToastController) {
  }

  async toast(text, icon = null) {
    const toast = await this.toaster.create({
      message: text,
      duration: 3000,
      position: 'bottom',
      cssClass: 'toastExtraBottom'
    });
    if (icon) {
      toast.icon = icon;
    }
    this.setHaxedValues(toast, icon);
    await toast.present();
  }

  async toastOnTop(text, icon = null, duration = 5000) {
    const toast = await this.toaster.create({
      message: text,
      duration,
      position: 'top',
      cssClass: 'toastExtraTop',
    });
    if (icon) {
      toast.icon = icon;
    }
    this.setHaxedValues(toast, icon);
    await toast.present();
  }

  async toastPermanent(text, button: ToastButton = null, icon = null) {
    this.dismissPermanentToast();

    const buttons = [];
    if (button) {
      buttons.push(button);
    }
    const toast = await this.toaster.create({
      message: text,
      position: 'bottom',
      cssClass: 'toastExtraBottom',
      buttons
    });
    this.permaToasts.push(toast);
    if (icon) {
      this.permaToasts[this.permaToasts.length - 1].icon = icon;
    }
    this.setHaxedValues(toast, icon, buttons);
    await this.permaToasts[this.permaToasts.length - 1].present();
  }

  setHaxedValues(ionToast, icon, buttons = null) {
      if (icon) {
        ionToast.style.setProperty('--custom-icon', 'url(\'/assets/icons/' + icon + '.svg\')');
      }
      const ionToastContent = ionToast.shadowRoot.querySelector('.toast-content');
      ionToastContent.setAttribute('part', 'toast-content');
      if (buttons && buttons.length > 0) {
        const ionToastButtonGroup = ionToast.shadowRoot.querySelector('.toast-button-group');
        ionToastButtonGroup.setAttribute('part', 'toast-button-group');
      }
  }

  dismissPermanentToast() {
    this.permaToasts.forEach(toast => {
      toast.dismiss();
    });
    this.permaToasts = [];
  }

}
