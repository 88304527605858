import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {QuickBookData} from './quick-book-data.interface';
import {MeetingService} from '../../services/Meetings/MeetingService';
import {MeetBox} from '../../components/meetbox/meetbox.interface';
import {ModalController} from '@ionic/angular';
import {ToastService} from '../../services/General/ToastService';
import Bugsnag from '@bugsnag/js';

@Component({
  selector: 'app-quick-book',
  templateUrl: './quick-book-modal.component.html',
  styleUrls: ['./quick-book-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class QuickBookModalComponent implements OnInit {
  @Input() info: QuickBookData;
  meetBox: MeetBox = null;
  schedule: any;

  constructor(private service: MeetingService, private modalCtrl: ModalController, private toaster: ToastService) {
  }

  ngOnInit() {
    this.service.suggestMeetingRoom(this.info.from, this.info.till, this.info.attendees.length).then(response => {
        this.meetBox = response;
        this.service.getScheduledMeetingsForIds(
          this.info.from.startOf('day'),
          this.info.till.endOf('day'),
          [response.id]).then(schedules => {
          this.schedule = schedules[0];
        });
    }).catch(err => {
      Bugsnag.notify(err);
      this.toaster.toast(err.error);
      this.modalCtrl.dismiss();
    });
  }

  continueWithBooking() {
    this.modalCtrl.dismiss({meetBox: this.meetBox}, 'continue');
  }

  changeMeetBox() {
    this.modalCtrl.dismiss(null, 'change');
  }

}
