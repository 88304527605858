/* eslint-disable id-blacklist */
import {Injectable} from '@angular/core';

@Injectable()
export class ValutaHelperService {
  constructor() {
  }

  formatValuta(value, decimals = 2) {
    return this.numberFormat(value, decimals, ',', '.');
  }


  numberFormat(number, decimals, decPoint, thousandsSep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    const n = !isFinite(+number) ? 0 : +number;
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
    const dec = (typeof decPoint === 'undefined') ? '.' : decPoint;
    let s = [];
      const toFixedFix = (n2, prec2) => {
        const k = Math.pow(10, prec2);
        return '' + Math.round(n2 * k) / k;
      };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    let result = s.join(dec);
    const indexOfDec = result.indexOf(dec);
    while (indexOfDec > -1 && result.substr(indexOfDec + 1, 1) === '0' && result.substr(result.length - 1) === '0') {
      result = result.substr(0, result.length - 1);
    }
    if (result.substr(result.length - 1) === ',') {
      result = result.substr(0, result.length - 1);
    }
    return result;
  }

}


