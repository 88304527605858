/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../General/ApiService';

@Injectable({
  providedIn: 'root'
})
export class ChargeApiService {
  constructor(private api: ApiService) {
  }

  public locations() {
    const params = {};
    return this.api.get('v1/charge/locations', params);
  }

  public location(identifier: string) {
    const params = {
      identifier
    };
    return this.api.get('v1/charge/locations/detail', params);
  }

  public startSession(
    connectorId: string,
    externalId: string,
    electricityTariffId: string,
    paymentMethod: string
  ) {
    const params = {
      connector_identifier: connectorId,
      external_id: externalId,
      electricity_tariff_identifier: electricityTariffId,
      payment_method: paymentMethod
    };
    return this.api.post('v1/charge/sessions/start', params);
  }

  public stopSession(identifier: string) {
    const params = {
      identifier,
    };
    return this.api.post('v1/charge/sessions/stop', params);
  }

  public session(identifier: string) {
    const params = {
      identifier,
    };
    return this.api.get('v1/charge/sessions/detail', params);
  }
  public charger(identifier: string) {
    const params = {
      identifier,
    };
    return this.api.get('v1/charge/chargers/detail', params);
  }

  public getConnector(assetId: number) {
    const params = {
      asset_id: assetId,
    };
    return this.api.post('v1/charge/getConnector', params);
  }
}
