import {Component, ViewEncapsulation} from '@angular/core';
import {IconService} from '../../services/General/IconService';
import {StorageService} from '../../services/Storage/StorageService';
import {TenantService} from '../../services/Tenant/TenantService';
import {ModalController, Platform} from '@ionic/angular';

@Component({
  selector: 'app-icon-change',
  templateUrl: './icon-change-modal.component.html',
  styleUrls: ['./icon-change-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class IconChangeModalComponent {
  iconName: string;
  iconSet = false;

  constructor(private iconService: IconService, private storage: StorageService,
              private tenantService: TenantService, private modalCtrl: ModalController,
              private platform: Platform) {
    this.init();
  }

  init() {
    this.iconName = this.tenantService.getName();
    this.storage.get('set-app-icon').then(icon => {
      this.iconSet = !!icon;
    });
  }

  updateIcon() {
    if (this.iconSet) {
      this.iconService.resetIcon();
    } else {
      this.iconService.changeIcon(this.iconName);
    }
    this.modalCtrl.dismiss();
  }

  getTitle() {
    if (this.platform.is('ios')) {
      return 'Apply icon';
    } else {
      return 'Restart app and apply icon';
    }
  }
}
