import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {ModalController} from '@ionic/angular';
import {ChargeTariff} from '../../../../services/Charge/interface/ChargeTariff';

@Component({
  selector: 'app-option',
  templateUrl: './option-modal.component.html',
  styleUrls: ['./option-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class OptionModalComponent implements OnInit {
  tariff: ChargeTariff;

  constructor(
    private browser: InAppBrowser,
    private modalCtrl: ModalController) {
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
