import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UserService} from '../../../../services/User/UserService';
import {MeetingService} from '../../../../services/Meetings/MeetingService';
import {ToastService} from '../../../../services/General/ToastService';
import {StorageService} from '../../../../services/Storage/StorageService';
import Bugsnag from '@bugsnag/js';
import {CalendarService} from '../../../../services/Calendars/CalendarService';
import {CalendarInterface} from '../../../../services/Calendars/calendar.interface';

@Component({
  selector: 'app-meeting-rsvp',
  templateUrl: './meeting-rsvp.component.html',
  styleUrls: ['./meeting-rsvp.component.scss'],
})
export class MeetingRsvpComponent implements OnChanges, OnInit {
  @Input() meeting: any;
  userId: number;
  status: number;
  inviteId: number;
  calendars: Array<CalendarInterface>;
  selectedCalendar: string = null;

  constructor(
    private user: UserService,
    private service: MeetingService,
    private toaster: ToastService,
    private storage: StorageService,
    private calendarService: CalendarService
  ) {
  }

  ngOnInit() {
    this.calendarService.calendars(true).then(calendars => {
      if (calendars.length > 0) {
        this.calendars = calendars;
        this.storage.get('default-calendar').then(defaultCalendar => {
          if (!defaultCalendar) {
            this.storage.set('default-calendar', this.calendars[0]);
          }
          this.selectedCalendar = defaultCalendar ? defaultCalendar.id : this.calendars[0].id;
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.meeting) {
      this.user.profile().then(profile => {
        this.userId = profile.id;
        const invite = this.meeting.attendees.filter(attendee => attendee.userId === this.userId)[0];
        this.status = parseInt(invite.status, 10);
        this.inviteId = invite.id;
      });
    }
  }

  updateRsvp(statusId) {
    let addedToNative = null;
    if (this.selectedCalendar !== null) {
      addedToNative = true;
    }

    this.service.replyToInvite(this.inviteId, statusId, addedToNative).then(() => {
      this.status = statusId;

      this.toaster.toast('Status updated to ' + this.getStatus(statusId));
      const attendees = [];
      if ([1, 4].includes(statusId) && addedToNative) {
        this.createNativeEvent();
      }

      if (statusId === 2 && addedToNative) {
        this.removeNativeEvent();
      }

      this.meeting.attendees.forEach(attendee => {
        if (attendee.userId === this.userId) {
          attendee.status = statusId;
        }
        attendees.push(attendee);
      });
      this.service.attendees.next(attendees);
    });
  }

  createNativeEvent() {
    this.calendarService.getEvents(this.meeting.from.toJSDate(), this.meeting.till.toJSDate(), true)
      .then(events => {
        if (!events.some(event => event.note.includes(this.meeting.id + '@'))) {
          this.service.calendarInformation(this.meeting.id).then(calendarInformation => {
            this.calendarService.createEvent(
              calendarInformation.title,
              calendarInformation.location,
              calendarInformation.description,
              this.meeting.from,
              this.meeting.till,
              this.selectedCalendar,
            ).then(() => {});
          }).catch(err => {
            Bugsnag.notify(err);
          });
        }
      });
  }

  removeNativeEvent() {
    this.calendarService.getEvents(this.meeting.from.toJSDate(), this.meeting.till.toJSDate(), true)
      .then(events => {
        const event = events.find(e => e.note.includes(this.meeting.id + '@'));
        if (event) {
          this.calendarService.deleteEvent(event.id);
        }
      });
  }

  getStatus(status: number) {
    switch (status) {
      case 0:
        return 'not responded';
      case 1:
        return 'attending';
      case 2:
        return 'not attending';
      case 3:
        return 'checked-in';
      case 4:
        return 'attending virtual';
    }
  }
}
