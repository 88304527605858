import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';
import {Drivers} from '@ionic/storage';
import {PreferenceService} from './PreferenceService';
import * as cordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import {ToastService} from '../General/ToastService';
import {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private indexedDBStorage: Storage | null = null;
  private sqliteStorage: Storage | null = null;
  private store: Storage | null = null;
  private safeKeys = [
    'more-modal-break-point',
    'parking-modal-break-point',
    'wallet-meta-data',
    'onboarding-cleared',
    'calendars',
    'calendar-colors',
    'has-seen-printing-page',
    'main-coffee-machine',
    'last-used-coffee-machines',
    'asked-app-icon-change',
    'set-app-icon',
    'tenant-config',
    'tenant-url',
    'hasSyncedStorage',
    'received-params',
  ];
  private shouldUsePreference = [
    // 'token',
    // 'refreshToken',
  ];

  constructor(private storage: Storage, private preference: PreferenceService,
              private toaster: ToastService, private platform: Platform) {
    this.init();
  }

  async init() {
    if (!this.store) {
      await this.initIndexedDBStorage();
      if (this.platform.is('ios')) {
        await this.initSQLiteStorage();
        const sqliteStorage = await this.sqliteStorage.create();
        //migrate away from indexedDB because iOS is the dumbest creation of all time
        const hasSynced = await sqliteStorage.get('hasSyncedStorage');
        if (!hasSynced) {
          const indexedStore = await this.indexedDBStorage.create();
          const keys = await indexedStore?.keys();
          for (const key of keys) {
            const value = await indexedStore.get(key);
            await sqliteStorage.set(key, value);
            await indexedStore.remove(key);
          }
          await sqliteStorage.set('hasSyncedStorage', true);
        }
        this.store = sqliteStorage;
      } else {
        this.store = await this.indexedDBStorage.create();
      }

    }
  }

  async initIndexedDBStorage() {
    this.indexedDBStorage = new Storage({
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    });
  }

  async initSQLiteStorage() {
    await this.storage.defineDriver(cordovaSQLiteDriver);
    this.sqliteStorage = new Storage({
      // eslint-disable-next-line no-underscore-dangle
      driverOrder: [cordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
    });
  }

  public set(key: string, value: any) {
    if (this.shouldUsePreference.includes(key)) {
      return this.preference.set(key, value);
    } else {
      return this.init().then(() => this.store?.set(key, value));
    }
  }

  public get(key: string) {
    if (this.shouldUsePreference.includes(key)) {
      return this.preference.get(key).then(token => {
        if (token) {
          return this.init().then(() => {
            this.store?.remove('token');
            this.store?.remove('refreshToken');
            return token;
          });
        } else {
          return this.init().then(() => {
            return this.store?.get('token').then((localStoreToken) => {
              if (localStoreToken) {
                this.preference.set('token', localStoreToken);
                this.store?.remove('token');
                return this.store?.get('refreshToken').then((localStoreRefreshToken) => {
                  if (localStoreRefreshToken) {
                    this.preference.set('refreshToken', localStoreRefreshToken);
                    this.store?.remove('refreshToken');
                  }
                  return key === 'token' ? localStoreToken : localStoreRefreshToken;
                });
              } else {
                return null;
              }
            });
          });
        }
      });
    } else {
      return this.init().then(() => this.store?.get(key));
    }
  }

  public remove(key: string) {
    if (this.shouldUsePreference.includes(key)) {
      return this.preference.remove(key);
    } else {
      return this.store?.remove(key);
    }
  }

  public clear() {
    this.preference.clear();
    return this.store?.keys().then(keys => {
      keys.forEach(key => {
        if (!this.safeKeys.includes(key)) {
          this.store?.remove(key);
        }
      });
    });
  }

  public async keys(): Promise<string[]> {
    await this.init();
    const allKeys: string[] = [];

    if (this.store) {
      const keys = await this.store.keys();
      allKeys.push(...keys);
    }

    return allKeys;
  }
}
