import {Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {Attendee} from '../../../../services/Meetings/attendee.interface';
import {MeetingService} from '../../../../services/Meetings/MeetingService';
import {UserService} from '../../../../services/User/UserService';
import {NavController} from '@ionic/angular';

@Component({
  selector: 'app-meeting-attendees',
  templateUrl: './meeting-attendees.component.html',
  styleUrls: ['./meeting-attendees.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class MeetingAttendeesComponent implements OnChanges {
  @Input() meeting: any;
  @Input() isNative = false;
  @Input() meetingEnded = false;
  @Input() host = false;
  @Input() nativeEventId: string = null;
  @Input() customerName: string;
  @Input() allowCarParkReservation: boolean;
  @Input() allowCarParkWithInvitation: boolean;
  attendees: Array<Attendee>;
  userId: any;
  confirmedAttendees: number;
  modal = undefined;
  messageModal = false;

  constructor(private service: MeetingService, private user: UserService,
              private nav: NavController) {
    this.init();
  }

  init() {
    this.service.attendees.next([]);
    this.user.profile().then(profile => {
      this.userId = profile.id;
    });
    this.service.attendees.subscribe(attendees => {
      this.attendees = attendees;
      this.calculateConfirmedAttendees();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.meeting) {
      this.attendees = this.meeting.attendees;
      this.calculateConfirmedAttendees();
    }
  }

  calculateConfirmedAttendees() {
    this.confirmedAttendees = this.attendees.filter(attendee => attendee.status === 1 || attendee.status === 3).length;
  }

  openMessageModal() {
    this.messageModal = true;
  }

  closeMessageModal() {
    this.messageModal = false;
  }

  userIsHost() {
    return this.attendees.filter(attendee => attendee.host && attendee.userId === this.userId).length;
  }

  getStatus(status: number) {
    switch (status) {
      case 0:
        return 'not-responded';
      case 1:
        return 'attending';
      case 2:
        return 'not-attending';
      case 3:
        return 'checked-in';
      case 4:
        return 'attending-virtual';
    }
  }

  edit() {
    this.nav.navigateForward(['/edit-invitees'], {state: {
        attendees: this.attendees,
        meetingId: this.meeting.id,
        host: this.host,
        nativeEventId: this.isNative ? this.meeting.originalNativeEvent.id : this.nativeEventId,
        allowCarParkReservation: this.allowCarParkReservation,
        allowCarParkWithInvitation: this.allowCarParkWithInvitation,
        customerName: this.customerName,
        isNative: this.isNative,
    }});
  }

  loopThroughObject(obj: any): boolean {
    return typeof obj === 'object';
  }
}
