import {AfterViewInit, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Notification} from '../../../../services/Notifications/notification.interface';
import {DateTime} from 'luxon';
import {RedirectService} from '../../../../services/Routing/RedirectService';
import {ModalController} from '@ionic/angular';
import {MeetingService} from '../../../../services/Meetings/MeetingService';
import {ToastService} from '../../../../services/General/ToastService';
import {UserService} from '../../../../services/User/UserService';
import Bugsnag from '@bugsnag/js';
import {CalendarService} from '../../../../services/Calendars/CalendarService';
import {StorageService} from '../../../../services/Storage/StorageService';
import {ModuleService} from '../../../../services/Modules/ModuleService';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
})

export class NotificationItemComponent implements AfterViewInit, OnChanges {
  @Input() notification: Notification;
  showMore = false;
  timeLeftToReply = '2:00';
  lastUpdate = 0;
  delaySet = false;
  rsvpStatus = null;
  meeting: any;

  constructor(
    private redirect: RedirectService,
    private modalCtrl: ModalController,
    private meetingService: MeetingService,
    private userService: UserService,
    private toaster: ToastService,
    private calendarService: CalendarService,
    private storage: StorageService,
    public modules: ModuleService,
  ) {
  }

  ngAfterViewInit() {
    if (this.notification.type === 'arrival') {
      requestAnimationFrame((time) => {
        this.getTimeLeftToReply(time);
      });
    }
    if (this.notification.type.substring(0, 8) === 'arrival-') {
      this.meetingService.defaultVisitorRegistration(this.notification.id).then(response => {
        this.notification.action = parseInt(response.action, 10);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.notification && this.notification.type === 'booking-invitation') {
      this.getMeetingForNotification();
    }
  }

  getMeetingForNotification() {
    const meetingId = parseInt(this.notification.url.split('/')[2], 10);
    if (meetingId) {
      this.meetingService.meeting(meetingId).then(meeting => {
        this.meeting = meeting;
        if (meeting.till > DateTime.now()) {
          this.userService.profile().then((profile) => {
            meeting.attendees.forEach(attendee => {
              if (attendee.userId === profile.id) {
                this.notification.attendee = attendee;
                this.rsvpStatus = attendee.status;
              }
            });
          });
        }
      });
    }
  }

  notificationDate(date) {
    if (DateTime.now().hasSame(date, 'day')) {
      return 'Today';
    }
    const days = Math.floor(DateTime.now().diff(date).shiftTo('days').days);
    return `${Math.max(1, days)}d ago`;
  }

  goToUrl(url) {
    let empty;
    let type;
    let param;
    if (url.substring(0, 1) === '/') {
      [empty, type, param] = url.split('/');
    } else {
      [type, param] = url.split('/');
    }

    this.redirect.navigate(type, param ?? null);
    this.modalCtrl.dismiss();
  }

  getTimeLeftToReply(time) {
    if (time - this.lastUpdate > 1000) {
      this.lastUpdate = time;
      const duration = this.notification.date.plus({minute: 2}).diff(DateTime.now(), ['minutes', 'seconds']).shiftTo('minutes', 'seconds');
      if (duration.minutes <= 0 && duration.seconds <= 0 && this.notification.type !== 'arrival-default') {
        this.notification.type = 'arrival-default';
        this.meetingService.defaultVisitorRegistration(this.notification.id).then(response => {
          this.notification.action = parseInt(response.action, 10);
        });
      }
      this.timeLeftToReply = `${Math.floor(duration.minutes)}:${Math.floor(duration.seconds).toString().padStart(2, '0')}`;
      if (!this.delaySet && this.notification.type === 'arrival') {
        this.delaySet = true;
        const pieElement = document.querySelector('#notification-' + this.notification.id + ' .pie') as HTMLElement;
        pieElement.style.setProperty('--delay', -(2 * 60 - duration.shiftTo('seconds').seconds) + 's');
      }
    }

    if (this.notification.type === 'arrival') {
      requestAnimationFrame((newTime) => {
        this.getTimeLeftToReply(newTime);
      });
    }
  }

  async updateRsvp(statusId) {
    let addedToNative = null;
    let selectedCalendar = null;
    const calendars = await this.calendarService.calendars(true);
    if (calendars.length > 0) {
      const defaultCalendar = await this.storage.get('default-calendar');
      if (!defaultCalendar) {
        this.storage.set('default-calendar', calendars[0]);
      }
      selectedCalendar = defaultCalendar ? defaultCalendar.id : calendars[0].id;
      addedToNative = true;
    }

    this.meetingService.replyToInvite(this.notification.attendee.id, statusId, addedToNative).then(() => {
      this.rsvpStatus = statusId;
      this.toaster.toast('Status updated to ' + this.getStatus(statusId));
      const attendees = [];
      this.meetingService.attendees.next(attendees);
      if ([1, 4].includes(statusId) && addedToNative) {
        this.createNativeEventFromNotification(selectedCalendar);
      }
      if (statusId === 2 && addedToNative) {
        this.removeNativeEvent();
      }
    });
  }

  createNativeEventFromNotification(selectedCalendar) {
    if (selectedCalendar) {
      this.calendarService.getEvents(this.meeting.from.toJSDate(), this.meeting.till.toJSDate(), true)
        .then(events => {
          if (!events.some(event => event.note.includes(this.meeting.id + '@'))) {
            this.meetingService.calendarInformation(this.meeting.id).then(calendarInformation => {
              this.calendarService.createEvent(
                calendarInformation.title,
                calendarInformation.location,
                calendarInformation.description,
                this.meeting.from,
                this.meeting.till,
                selectedCalendar,
              ).then(() => {
              });
            }).catch(err => {
              Bugsnag.notify(err);
            });
          }
        });
    }
  }

  removeNativeEvent() {
    this.calendarService.getEvents(this.meeting.from.toJSDate(), this.meeting.till.toJSDate(), true)
      .then(events => {
        const event = events.find(e => e.note.includes(this.meeting.id + '@'));
        if (event) {
          this.calendarService.deleteEvent(event.id);
        }
      });
  }

  getStatus(status: number) {
    switch (status) {
      case 0:
        return 'not responded';
      case 1:
        return 'attending';
      case 2:
        return 'not attending';
      case 3:
        return 'checked-in';
      case 4:
        return 'attending virtual';
    }
  }

  respond(type, action) {
    this.notification.action = action;
    if (['arrival-default', 'arrival'].includes(type)) {
      this.meetingService.replyToVisitorRegistration(this.notification.id, action);
    }
  }
}
