/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../General/ApiService';

@Injectable({
  providedIn: 'root'
})
export class DisplayApiService {
  constructor(private api: ApiService) {
  }

  public displays(roomId: number) {
    return this.api.get(`display/${roomId}`);
  }

  public displayRemote(id: number, meetingId: number = null) {
    if (meetingId) {
      return this.api.get(`v2/displays/${id}/remote/${meetingId}`);
    } else {
      return this.api.get(`v2/displays/${id}/remote`);
    }
  }

  public action(id: number, action: string) {
    return this.api.post(`v2/displays/${id}/remote/${action}`);
  }
}
