import {Component, Input, ViewEncapsulation} from '@angular/core';
import {MeetingService} from '../../services/Meetings/MeetingService';
import {ModalController, NavController} from '@ionic/angular';
import {ToastService} from '../../services/General/ToastService';
import {CalendarService} from '../../services/Calendars/CalendarService';
import Bugsnag from '@bugsnag/js';
import {TenantService} from '../../services/Tenant/TenantService';
import {ModuleService} from '../../services/Modules/ModuleService';

@Component({
  selector: 'app-cancel-meeting',
  templateUrl: './cancel-meeting-modal.component.html',
  styleUrls: ['./cancel-meeting-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CancelMeetingModalComponent {
  @Input() meeting: any;
  @Input() isNative = false;
  disableBtn = false;
  deleteNativeEvent = true;
  tenantName: string;

  constructor(private service: MeetingService, private nav: NavController, private toaster: ToastService,
              private modalCtrl: ModalController, private calendar: CalendarService,
              private tenantService: TenantService, public modules: ModuleService) {
    this.tenantName = tenantService.getName();
  }

  cancelMeeting(event) {
    this.disableBtn = true;
    if (this.isNative) {
      this.calendar.deleteEvent(this.meeting.originalNativeEvent.id).then(() => {
        this.closeUp();
      }).catch(err => {
        //Not sure why this returns true in the error if the delete worked....
        if (err === true || err === null) {
          this.closeUp();
        }
      }).finally(() => {
        this.disableBtn = false;
      });
    } else {
      this.service.cancelMeeting(this.meeting.id).then(() => {
        if (this.meeting.existingEvent_id) {
          if (this.deleteNativeEvent) {
            this.calendar.deleteEvent(this.meeting.existingEvent_id);
          } else {
            this.calendar.removeExistingEventNote(this.meeting.existingEvent_id);
          }
        }
        this.closeUp();
      }).catch(error => {
        Bugsnag.notify(error);
        this.toaster.toast('Failed to cancel booking');
      }).finally(() => {
        this.disableBtn = false;
      });
    }
  }

  closeUp() {
    this.toaster.toast('Meeting was cancelled', 'ok');
    this.nav.navigateRoot('/calendar');
    this.modalCtrl.dismiss(null, 'cancelled');
  }

  getRefundAmount() {
    const isToken = this.meeting.cancellationType.id === 'tokens';
    const value = this.meeting.cancellationType.value;
    if (!value) {
      return parseInt(isToken ? this.meeting.tokenPrice : this.meeting.price, 10);
    } else {
      return Math.ceil(isToken ? value : this.meeting.price * (1 - (value / 100)));
    }
  }
}
