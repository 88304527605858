import {Component, Input, ViewEncapsulation} from '@angular/core';
import {CarParkService} from '../../../../services/CarParks/CarParkService';
import {ToastService} from '../../../../services/General/ToastService';
import {ModalController} from '@ionic/angular';
import {StorageService} from '../../../../services/Storage/StorageService';
import Bugsnag from '@bugsnag/js';
import {ModuleService} from '../../../../services/Modules/ModuleService';

@Component({
  selector: 'app-car-park-controls',
  templateUrl: './car-park-controls.component.html',
  styleUrls: ['./car-park-controls.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CarParkControlsComponent {
  @Input() carPark: any;
  @Input() carParkSession: any;
  openingDevice: number = null;

  constructor(private service: CarParkService, private toaster: ToastService,
              private modalCtrl: ModalController, private storage: StorageService,
              public modules: ModuleService) {
  }

  barrierOpen(event, barrierId, carParkId) {
    event.stopPropagation();
    this.openingDevice = barrierId;
    this.service.barrierOpen(barrierId).then(response => {
      this.toaster.toast('The barrier will open','ok');
      this.storage.set('last-used-carpark', carParkId);
      this.modalCtrl.dismiss();
    }).catch(err => {
      Bugsnag.notify(err);
      this.openingDevice = null;
      this.toaster.toast('Failed to open barrier');
    });
  }

  getLabelText(carPark) {
    if(this.carParkSession && this.carParkSession.reservations.includes(carPark.id)) {
      return 'RESERVED';
    }
    if(this.carParkSession && this.carParkSession.activeCarPark === carPark.id) {
      if (this.modules.has('parking_anpr')) {
        return this.carParkSession.licensePlate ?? 'PARKED';
      } else {
        return 'PARKED';
      }
    }
    if (carPark.freeSpace <= 0) {
      return 'FULL';
    }
    return carPark.freeSpace;
  }

  getLabelColor(carPark) {
    if(this.carParkSession && this.carParkSession.reservations.includes(carPark.id)) {
      return 'blue';
    }
    if(this.carParkSession && this.carParkSession.activeCarPark === carPark.id) {
      return 'yellow';
    }
    if (carPark.freeSpace <= 0) {
      return 'red';
    }
    return 'green';
  }

  getActiveClass(carPark, device) {
    if(device.id === this.openingDevice) {
      return 'opening';
    }
    if(this.carParkSession &&
      ((this.carParkSession.activeCarPark === carPark.id && device.type !== '2') ||
      (this.carParkSession.activeCarPark !== carPark.id && device.type === '2'))) {
      return 'active';
    }
    return '';
  }
}
