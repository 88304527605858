import {Component, EventEmitter, Output, ViewEncapsulation} from '@angular/core';
import {
  CameraFileBrowserChoiceModalComponent
} from '../../modals/camera-file-browser-choice/camera-file-browser-choice-modal.component';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-file-select',
  templateUrl: './file-select.component.html',
  styleUrls: ['./file-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FileSelectComponent {
  @Output() updateFiles = new EventEmitter<Array<File>>();
  files: Array<File> = [];
  modal: any;

  constructor(private modalCtrl: ModalController) {
  }

  async fileSelect() {
    const breakpoint = 124 / window.innerHeight;
    this.modal = await this.modalCtrl.create({
      component: CameraFileBrowserChoiceModalComponent,
      initialBreakpoint: breakpoint,
      breakpoints: [0, breakpoint]
    });
    this.modal.present().then(() => {
      const handle = document.querySelector('.show-modal').shadowRoot.querySelector('.modal-handle');
      handle.addEventListener('click', () => {
        this.modal.dismiss();
      });
    });

    const {data, role} = await this.modal.onWillDismiss();
    if (role === 'success') {
      this.fileAdded(data);
    }
  }

  fileAdded(event) {
    const file = event.target.files[0];
    const name = 'image' + (this.files.length + 1) + '.' + file.name.split('.').pop();
    const renamedFile = this.renameFile(file, name);
    this.files.push(renamedFile);
    this.updateFiles.emit(this.files);
  }

  renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  }

  newPhoto(file: File) {
    this.files.push(file);
    this.updateFiles.emit(this.files);
  }

  removeFile(index) {
    this.files.splice(index, 1);
    this.updateFiles.emit(this.files);
  }
}
