/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {pki, util, cipher} from 'node-forge';

@Injectable({
  providedIn: 'root'
})
export class EncryptService {
  pubPem = `-----BEGIN PUBLIC KEY-----
MDwwDQYJKoZIhvcNAQEBBQADKwAwKAIhALEjxcdhRx8kKQs4KOdGv+32dcjFwe5H
mkg+iENLgXvVAgMBAAE=
-----END PUBLIC KEY-----`;

  constructor() {
  }

  encrypt(data) {
    const pubk = pki.publicKeyFromPem(this.pubPem);
    return pubk.encrypt(util.encodeUtf8(data));
  }

  decrypt(key, encryptedToken) {
    const decodedToken = JSON.parse(atob(encryptedToken));
    const iv = util.decode64(decodedToken.iv);
    const encryptedData = util.decode64(decodedToken.value);

    const decipher = cipher.createDecipher('AES-CBC', key);
    decipher.start({iv});
    decipher.update(util.createBuffer(encryptedData));
    decipher.finish();

    const decrypted = decipher.output;
    return decrypted.toString();
  }
}
