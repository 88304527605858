import {Component, EventEmitter, Output, ViewEncapsulation} from '@angular/core';
import {ButtonInterface} from '../../../../components/buttons/button-switch/button.interface';

@Component({
  selector: 'app-notification-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class HeaderComponent {
  @Output() changed = new EventEmitter<string>();
  buttons: ButtonInterface[] = [
    // {
    //   id: 1,
    //   title: 'News',
    //   enabled: true,
    //   selected: false,
    // },
    {
      id: 2,
      title: 'Notifications',
      enabled: true,
      selected: true,
    },
  ];

  constructor() {
  }

  setSelectedButton(event) {
    this.changed.emit(event.title);
  }
}
