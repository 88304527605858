/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../General/ApiService';

@Injectable({
  providedIn: 'root'
})
export class RoomControlsApiService {
  constructor(private api: ApiService) {
  }

  public unlock(room_id: number, pms_id: number) {
    const params = {
      room_id,
      ...(pms_id && {PMScontrol_id: pms_id}),
    };

    return this.api.get('room/unlock', params);
  }

  public updateBlindStatus(room_id: number, power: string, direction: string) {
    const params = {
      room_id,
      power,
      direction,
    };

    return this.api.post('room/blinds/change', params);
  }

  public updateLightStatus(room_id: number, state: string) {
    const params = {
      room_id,
      state,
    };

    return this.api.post('room/lighting', params);
  }

  public getLightStatus(room_id: number) {
    const params = {
      room_id,
    };

    return this.api.get('room/lighting', params);
  }

  public temperature(room_id: number) {
    const params = {
      room_id,
    };

    return this.api.get('room/temperature', params);
  }

  public setTemperature(room_id: number, temperature: number) {
    const params = {
      room_id,
      temperature,
    };

    return this.api.post('room/temperature', params);
  }

  public setOperatingMode(room_id: number, mode: string) {
    const params = {
      room_id,
      mode,
    };

    return this.api.post('room/operatingMode', params);
  }

}
