/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {Location} from '../Location/location.interface';
import {LocationService} from '../Location/LocationService';
import {ToastService} from '../General/ToastService';
import Bugsnag from '@bugsnag/js';

declare const google;

@Injectable({
  providedIn: 'root'
})
export class DirectionsService {
  service: any;
  origin: Location;
  destination: Location;
  transportModes = ['DRIVING', 'TRANSIT', 'WALKING'];

  constructor(private locationService: LocationService, private toaster: ToastService) {
    this.service = new google.maps.DistanceMatrixService();
  }

  public async distance(destination: Location, transport: string = null) {
    this.origin = await this.locationService.get();
    this.destination = destination;
    const result = [];
    if (transport) {
      result.push(await this.distanceMatrix(transport));
    } else {
      for (const mode of this.transportModes) {
        const distanceMatrix = await this.distanceMatrix(mode);
        result.push(distanceMatrix);
      }
    }
    return result;
  }

  private async distanceMatrix(transport: string) {
    return this.service.getDistanceMatrix({
      origins: [this.origin],
      destinations: [this.destination],
      travelMode: transport,
      unitSystem: google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false
    }).then((response) => ({
        mode: transport,
        distance: response.rows[0].elements[0].distance,
        duration: response.rows[0].elements[0].duration,
      })).catch(error => {
      Bugsnag.notify(error);
      this.toaster.toast(error.message);
      return null;
    });
  }
}
