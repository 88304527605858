import {Injectable} from '@angular/core';
import {ToastService} from '../General/ToastService';
import {Platform} from '@ionic/angular';
import Bugsnag from '@bugsnag/js';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let WifiWizard2: any;

@Injectable({
  providedIn: 'root'
})
export class WifiService {

  constructor(private toaster: ToastService, private platform: Platform) {
  }

  connect(ssid: string, password: string, username: string, enterprise: boolean = false) {
    if (enterprise) {
      password = `${username}:${password}`;
    }

    if (this.platform.is('ios')) {
      return this.handleIosConnect(ssid, password);
    } else {
      return this.handleAndroidConnect(ssid, password, enterprise);
    }

  }

  handleIosConnect(ssid, password) {
    return WifiWizard2.iOSConnectNetwork(ssid, password).then(() => true)
      .catch(error => {
        Bugsnag.notify(error);
        switch (error) {
          case 'WIFI_NOT_ENABLED':
            this.toaster.toast('Please enable wifi on your device', 'wifi');
            return false;
          case 'USER_CANCELLED_ADDING_NETWORK':
            return false;
          case 'WIFI_NETWORK_ALREADY_EXISTS':
            this.toaster.toast('Wifi network was already added');
            return true;
          default:
            this.toaster.toast('Something went wrong');
            return false;
        }
      });
  }

  handleAndroidConnect(ssid, password, enterprise = false) {
    const alg = enterprise ? 'WPA3' : null;
    return WifiWizard2.suggestConnection(ssid, password, alg)
      .then(() => true)
      .catch(error => {
        Bugsnag.notify(error);
        switch (error) {
          case 'WIFI_NOT_ENABLED':
            this.toaster.toast('Please enable wifi on your device');
            return false;
          case 'USER_CANCELLED_ADDING_NETWORK':
            return false;
          case 'WIFI_NETWORK_ALREADY_EXISTS':
            this.toaster.toast('Wifi network was already added');
            return true;
          default:
            this.toaster.toast('Something went wrong');
            return false;
        }
      });
  }
}
