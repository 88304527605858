import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ToastService} from '../../../../services/General/ToastService';

@Component({
  selector: 'app-edit-meeting',
  templateUrl: './edit-meeting-modal.component.html',
  styleUrls: ['./edit-meeting-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class EditMeetingModalComponent {
  @Input() subject: string;
  @Input() description: string;

  constructor(private modalCtrl: ModalController, private toaster: ToastService) {
  }

  update() {
    if (this.subject.replace(/\s/g, '').length) {
      this.modalCtrl.dismiss({subject: this.subject, description: this.description}, 'updated');
    } else {
      this.toaster.toast('Subject is mandatory.');
    }
  }
}
