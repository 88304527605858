import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-meeting-header',
  templateUrl: './meeting-header.component.html',
  styleUrls: ['./meeting-header.component.scss']
})

export class MeetingHeaderComponent {
  @Input() meeting: any;
  @Input() meetingActive = false;

  constructor() {
  }

}
