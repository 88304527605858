import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {ModalController, NavController} from '@ionic/angular';
import {StorageService} from '../../../../services/Storage/StorageService';
import {ChargeSession} from '../../../../services/Charge/interface/ChargeSession';
import {ChargeService} from '../../../../services/Charge/ChargeService';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-option',
  templateUrl: './charging-completed-modal.component.html',
  styleUrls: ['./charging-completed-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ChargingCompletedModalComponent implements OnInit {
  chargingSession: ChargeSession = null;
  chargeSessionToken: string = null;
  duration: string = null;
  date: DateTime = null;
  locationName: string = null;
  loading;
  bool = false;

  constructor(
    private browser: InAppBrowser,
    private modalCtrl: ModalController,
    private storage: StorageService,
    private chargeService: ChargeService,
    private nav: NavController
  ) {
  }

  ngOnInit() {
    this.getChargeSessionToken();
  }

  getChargeSessionToken() {
    this.storage.get('charge_session_identifier').then(result => {
      this.chargeSessionToken = result;
      this.storage.remove('charge_session_identifier');
      this.loading = true;
      setTimeout(() => {
        this.getChargeSession();
      }, 1500);
    });
  }

  getChargeSession() {
    this.chargeService.session(this.chargeSessionToken).then(result => {
      this.loading = false;
      this.chargingSession = result.data;
      if (this.chargingSession.status !== 'Ended') {
        this.nav.navigateForward(['/car-charging-session-active']);
        this.modalCtrl.dismiss();
      } else {
        const fromDate = DateTime.fromISO(this.chargingSession.started_at);
        const endDate = DateTime.fromISO(this.chargingSession.stopped_at);
        this.duration = endDate.diff(fromDate).toFormat('h\'h\' mm\'m\'');
        this.date = DateTime.fromISO(this.chargingSession.started_at);
        this.getLocation();
      }

    });
  }

  getLocation() {
    this.locationName = this.chargingSession.location;
  }

  closeModal() {
    this.storage.remove('charge_session_identifier');
    this.modalCtrl.dismiss();
  }

}
