import {Component, Input, OnInit} from '@angular/core';
import {SvgIconRegistryService} from 'angular-svg-icon';
import {options, SvgIcon} from './svg-icon.type';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['svg-icon.component.scss'],
})
export class AppSvgIconComponent implements OnInit {
  @Input() name: SvgIcon;

  constructor(private iconReg: SvgIconRegistryService) {
  }

  ngOnInit() {
    options.forEach(option => {
      this.iconReg.loadSvg(`/assets/icons/${option}.svg`, option);
    });
  }
}
