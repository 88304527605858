import {Component, EventEmitter, Output} from '@angular/core';
import {Camera, CameraResultType, CameraSource} from '@capacitor/camera';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})

export class CameraComponent {
  @Output() newPhoto = new EventEmitter<File>();

  constructor() {
  }

  async takePicture() {
    Camera.getPhoto({
      quality: 90,
      source: CameraSource.Camera,
      resultType: CameraResultType.DataUrl
    }).then(photo => {
      this.newPhoto.emit(this.dataURLtoFile(photo.dataUrl, DateTime.now().toMillis() + '.' + photo.format));
    });
  }

  dataURLtoFile(dataurl, filename) {

    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
  }
}
