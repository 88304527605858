import {AfterViewInit, Component, Input} from '@angular/core';
import {Wallet} from '../../pages/wallets/interfaces/wallet.interface';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-wallet-selector',
  templateUrl: './wallet-selector-modal.component.html',
  styleUrls: ['./wallet-selector-modal.component.scss'],
})

export class WalletSelectorModalComponent implements AfterViewInit {
  @Input() wallets: Array<Wallet>;
  @Input() selectedWallet: Wallet;
  selectedWalletSequence = 0;

  constructor(private modalCtrl: ModalController) {
  }

  ngAfterViewInit() {
    this.selectedWalletSequence = this.selectedWallet.metaData.sequence;
  }

  selectedWalletChanged(sequenceId: number) {
    this.selectedWalletSequence = sequenceId;
    this.selectedWallet = this.wallets.find(wallet => wallet.metaData.sequence === sequenceId);
  }

  confirmWalletChange() {
    this.modalCtrl.dismiss(this.selectedWallet, 'changed');
  }

  updateWallets() {
    setTimeout(() => {
      this.modalCtrl.dismiss(null, 'reopen');
    },500);
  }
}
