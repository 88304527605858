import {Injectable} from '@angular/core';
import {ToastService} from '../General/ToastService';
import {AuthService} from '../Auth/AuthService';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {NavController} from '@ionic/angular';
import {QueryParameterService} from '../General/QueryParameterService';
import {App} from '@capacitor/app';
import {ScanDataService} from '../Scan/ScanDataService';
import {ApiService} from '../General/ApiService';
import {WalletService} from '../Wallet/WalletService';
import {DateTime} from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class DeeplinkService {
  constructor(private nav: NavController, private dataProcessor: ScanDataService,
              private toaster: ToastService, private auth: AuthService,
              private browser: InAppBrowser, private queryParameterService: QueryParameterService,
              private api: ApiService, private walletService: WalletService) {
    this.init();
  }

  init() {
    App.addListener('appUrlOpen', data => {
      if (this.api.token) {
        this.handleUrl(data.url);
      } else {
        setTimeout(() => {
          this.handleUrl(data.url);
        }, 1000);
      }
    });
  }

  handleUrl(url) {
    const match = url.replace('https://', '')
      .replace('http://', '')
      .replace('digitalhq://', '')
      .replace('meetdistrict://', '')
      .split('/');

    this.auth.isLoggedIn().then(isLoggedIn => {
      if (isLoggedIn) {
        this.handleDeeplinkMatch(url, match);
      } else if (url.includes('?')){
        this.handleQueryDeeplink(url);
      }
    });
  }

  handleDeeplinkMatch(url, match) {
    switch (match[1]) {
      case 'tag':
        this.handleQRDeeplink(url);
        break;
      case 'bookings':
        this.handleQRDeeplink(url);
        break;
      case 'service':
        this.handleQRDeeplink(url);
        break;
      case 'academy':
        this.handleQRDeeplink(url);
        break;
      case 'community':
        this.handleQRDeeplink(url);
        break;
      case 'topup':
        this.handleTopUpDeeplink(match);
        break;
      case 'seat':
        this.handleQRDeeplink(url);
        break;
      case 'auth':
        this.handleAuthDeeplink(match);
        break;
      case 'book':
        this.handleQRDeeplink(url);
        break;
      default:
        if (url.includes('?')) {
          this.handleQueryDeeplink(url);
        }
        break;
    }
  }

  async handleQueryDeeplink(url) {
    const query = url.split('?')[1];
    if (query) {
      const params = await this.queryParameterService.checkForParameters(`/?${query}`);
      this.auth.isLoggedIn().then(isLoggedIn => {
        if (isLoggedIn) {
          this.nav.navigateBack('/refresh', {skipLocationChange: true, animated: false}).then(() => {
            this.nav.navigateBack(['/home']);
          });
        } else {
          this.queryParameterService.loginWithTokenParameter(params);
        }
      });
    }
  }

  handleQRDeeplink(url) {
    this.dataProcessor.process(url);
  }

  handleTopUpDeeplink(match) {
    if (match[2] === 'success') {
      this.toaster.toast('Wallet successfully topped up!','ok');
    } else {
      this.toaster.toast('Top up failed');
    }
    this.walletService.walletUpdated.next(DateTime.now().toMillis());
  }

  handleAuthDeeplink(match) {
    const redirectUrl = match[2] ? atob(decodeURIComponent(match[2])) : null;
    this.auth.authTransferTokenUrl(redirectUrl).then(url => {
      this.browser.create(url, '_system');
    });
  }
}
