import {Injectable} from '@angular/core';
import {NotificationApiService} from './API/NotificationApiService';
import {BehaviorSubject} from 'rxjs';
import {CoffeeService} from '../Orders/Coffee/CoffeeService';
import {DateTime} from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  count: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private api: NotificationApiService, private coffeeService: CoffeeService) {
  }

  public updateCount() {
    this.api.notificationCount().then(count => {
      this.count.next(count);
    });
  }
  public notifications(page: number = 1) {
    return this.api.notifications(page).then(response => ({
      notifications: this.formatNotifications(response.data),
      nextPage: response.current_page === response.last_page ? null : response.current_page + 1,
      currentPage: response.current_page,
    }));
  }

  public markNotificationsAsRead() {
    this.api.markNotificationsAsRead().then(() => {
      this.count.next(0);
    });
  }


  private formatNotifications(notifications) {
    return notifications.map(notification => ({
      id: notification.id,
      title: notification.title,
      message: notification.message,
      read: notification.read === '1',
      type: notification.type,
      url: notification.url,
      date: DateTime.fromISO(notification.created_at),
      validTill: notification.validTill ? DateTime.fromFormat(notification.validTill, 'yyyy-LL-dd HH:mm:ss.SSS') : null,
    }));
  }
}
