import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {MeetDateComponent} from '../date/date.component';
import {MeetBoxesComponent} from '../boxes/boxes.component';
import {AppComponentsModule} from 'src/app/app_components.module';
import {MeetContainerComponent} from './meet-container.component';
import {MeetFilterModalComponent} from '../../../../modals/meet-filter/meet-filter-modal.component';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, AppComponentsModule],
  declarations: [
    MeetDateComponent,
    MeetDateComponent,
    MeetBoxesComponent,
    MeetContainerComponent,
    MeetFilterModalComponent,
  ],
    exports: [MeetContainerComponent, MeetBoxesComponent],
})
export class MeetContainerComponentModule {
}
