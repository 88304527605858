/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../General/ApiService';

@Injectable({
  providedIn: 'root'
})
export class TagsApiService {
  constructor(private api: ApiService) {
  }

  public tag(tagId: number, tenant: string = null) {
    return this.api.get(`tag/v2/${tagId}`,  {...(tenant && {tenant})});
  }

  public create(tagId: number, assetId: number) {
    const params = {
      tag_id: tagId,
      asset_id: assetId,
    };
    return this.api.post('tag/create', params);
  }
}
