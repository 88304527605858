import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-room-navigation',
  templateUrl: './room-navigation.component.html',
  styleUrls: ['./room-navigation.component.scss']
})

export class RoomNavigationComponent {
  @Input() currentNavigation;
  @Input() navigationItems;
  @Output() currentNavigationChanged = new EventEmitter<string>();

  constructor(

  ) {
  }

  switchNavTab(tab){
    this.currentNavigationChanged.emit(tab.name);
  }


}
