import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  filter: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor() {
  }
}
