import {Injectable} from '@angular/core';
import {NavController} from '@ionic/angular';
import {ModuleService} from '../Modules/ModuleService';
import {UserService} from '../User/UserService';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor(private nav: NavController, private modules: ModuleService, private user: UserService) {
  }

  navigate(url: string, param: string | number = null) {
    this.nav.navigateBack('/refresh', {skipLocationChange: true, animated: false}).then(() => {
      switch (url) {
        case 'event':
          if (this.modules.has('booking')) {
            this.nav.navigateRoot(['/meeting', {id: param}]);
          } else {
            this.home();
          }
          break;
        case 'bookings':
          if (this.modules.has('booking')) {
            this.nav.navigateRoot(['/meeting', {id: param}]);
          } else {
            this.home();
          }
          break;
        case 'ticket':
          if (this.modules.has('help_center')) {
            this.nav.navigateRoot(['/service', {id: param}]);
          } else {
            this.home();
          }
          this.nav.navigateRoot(['/service', {id: param}]);
          break;
        case 'service':
          if (this.modules.has('help_center')) {
            this.nav.navigateRoot(['/service']);
          } else {
            this.home();
          }
          break;
        case 'academy':
          if (this.modules.has('academy')) {
            this.nav.navigateBack(['/academy', {id: param}]);
          } else {
            this.home();
          }
          break;
        case 'community':
          if (this.modules.has('community')) {
            this.nav.navigateBack(['/community', {id: param}]);
          } else {
            this.home();
          }
          break;
        case 'my-meetings':
          this.nav.navigateRoot(['/calendar']);
          break;
        case 'order':
          if (this.modules.has('orders') && this.user.canOrder()) {
            this.nav.navigateRoot(['/orders', {id: param}]);
          } else {
            this.home();
          }
          break;
        case 'typeform':
          if (this.modules.has('typeforms')) {
            this.nav.navigateRoot(['/typeform'], {state: {id: param}});
          } else {
            this.home();
          }
          break;
        case 'wallet':
          this.nav.navigateRoot(['/wallets']);
          break;
        case 'scan':
          this.nav.navigateRoot(['/scan', {tagId: param}]);
          break;
        case 'login':
          this.nav.navigateRoot(['/', {tempLoginToken: param}]);
      }
    });
  }

  home() {
    this.nav.navigateRoot(['/home']);
  }

}
