import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {TypeFormService} from '../../services/TypeForms/TypeFormService';
import Bugsnag from '@bugsnag/js';

@Component({
  selector: 'app-type-form-answers',
  templateUrl: 'type-form-answers.page.html',
  styleUrls: ['type-form-answers.page.scss']
})
export class TypeFormAnswersPage implements OnInit {
  form: any;
  answers: Array<any>;

  constructor(private service: TypeFormService, private location: Location) {
  }

  ngOnInit() {
      // @ts-ignore
      const {form} = this.location.getState();
      this.form = form;
      this.service.typeFormAsnwers(form.id).then(answers => {
        this.answers = answers;
      }).catch(err => {
        Bugsnag.notify(err);
        this.answers = [];
      });
  };
}
