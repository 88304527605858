/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../../General/ApiService';
import {Wallet} from '../../../../pages/wallets/interfaces/wallet.interface';

@Injectable({
  providedIn: 'root'
})
export class CoffeeApiService {
  constructor(private api: ApiService) {
  }

  public mainMachine() {
    return this.api.get('coffee-machine/favorite');
  }

  public quickBuy(asset: number, mac: string, showQuantity: boolean, wallet: Wallet, bssid: string = null) {
    wallet.id = wallet.customerId; //On HQ.MD the customer id is set by the id of the payment method
    const params = {
      type: 'coffeeV2',
      params: {
        asset,
        mac,
        showQuantity,
        showAmount: false,
        ...(bssid && {bssid}),
      },
      wallet,
    };

    return this.api.post('coffee-machine/favorite/order', params);
  }

}
