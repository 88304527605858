import {Injectable} from '@angular/core';
import {DisplayApiService} from './API/DisplayApiService';

@Injectable({
  providedIn: 'root'
})
export class DisplayService {

  constructor(private api: DisplayApiService) {
  }

  public displays(roomId: number) {
    return this.api.displays(roomId);
  }

  public remote(displayId: number, meetingId: number = null) {
    return this.api.displayRemote(displayId, meetingId);
  }

  public togglePower(displayId: number) {
    return this.api.action(displayId, 'power');
  }

  public volumeUp(displayId: number) {
    return this.api.action(displayId, 'volume/up');
  }

  public volumeDown(displayId: number) {
    return this.api.action(displayId, 'volume/down');
  }

  public volumeMute(displayId: number) {
    return this.api.action(displayId, 'volume/mute');
  }

  public sourceController(displayId: number) {
    return this.api.action(displayId, 'source/controller');
  }

  public sourceHDMI(displayId: number) {
    return this.api.action(displayId, 'source/hdmi');
  }
}
