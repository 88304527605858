import {
  ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Share} from "@capacitor/share";

@Component({
  selector: 'app-room-remote',
  templateUrl: './remote.component.html',
  styleUrls: ['./remote.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RoomRemoteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() displays: Array<any>;
  @Input() displayId: number;
  @Input() remote: any;
  @Output() displaySet = new EventEmitter<number>();
  url: SafeUrl;
  loaded = false;
  iframeHeight = null;
  selectedDisplay: number;

  constructor(protected sanitizer: DomSanitizer, private changeDetectorRef: ChangeDetectorRef) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.remote) {
      this.setRemote();
    }
    this.selectedDisplay = this.displayId;
  }

  ngOnInit() {
    if (this.remote) {
      this.setRemote();
    }
    this.listenToIframe();
  }

  listenToIframe() {
    const app = this;
    window.addEventListener('message',
      (event) => {
        app.handleMessage(event, app);
      },
      false
    );
  }

  async handleMessage(event, app) {
    if (event.data.event === 'resize') {
      app.iframeHeight = event.data.args.height;
      app.changeDetectorRef.detectChanges();
    }
    if(event.data.event === 'share'){
      await Share.share({
        title: event.data.args.title,
        text: event.data.args.text,
        url: event.data.args.url,
        dialogTitle: event.data.args.title,
      });
    }
  }

  ngOnDestroy() {
    const app = this;
    window.removeEventListener('message',
      (event) => {
        app.handleMessage(event, app);
      },
      false
    );
  }

  setRemote() {
    this.loaded = true;
    if (this.remote.url) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.remote.url);
    }
  }

  setDisplay(id) {
    this.displaySet.emit(id);
  }

}
