import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';
import {MoreModalComponent} from './more-modal.component';
import {AppComponentsModule} from 'src/app/app_components.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, AppComponentsModule],
  declarations: [MoreModalComponent],
  exports: [MoreModalComponent],
})
export class MoreModalComponentModule {
}
