import {Component, Input} from '@angular/core';
import {Attendee} from '../../../../services/Meetings/attendee.interface';
import {ModalController} from '@ionic/angular';
import {ShareVisitorPassModalComponent} from '../../modals/share-visitor-pass/share-visitor-pass-modal.component';

@Component({
  selector: 'app-meeting-share-visitor-pass',
  templateUrl: './meeting-share-visitor-pass.component.html',
  styleUrls: ['./meeting-share-visitor-pass.component.scss'],
})
export class MeetingShareVisitorPassComponent {
  @Input() attendees: Array<Attendee>;
  modal: any;

  constructor(private modalCtrl: ModalController) {
  }

  async openShareModal() {
    this.modal = await this.modalCtrl.create({
      component: ShareVisitorPassModalComponent,
      componentProps: {
        attendees: this.attendees.filter(a => a.visitorPassUrl),
      },
      initialBreakpoint: .70,
      breakpoints: [0, .70]
    });
    this.modal.present().then(() => {
      const handle = document.querySelector('.show-modal').shadowRoot.querySelector('.modal-handle');
      handle.addEventListener('click', () => {
        this.modal.dismiss();
      });
    });
    const {data, role} = await this.modal.onWillDismiss();
  }
}
