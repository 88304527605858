import {FormsModule} from '@angular/forms';
import {NumberSliderComponent} from './components/number-slider/number-slider.component';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {AngularSvgIconModule, SvgIconComponent} from 'angular-svg-icon';
import {ButtonMediumComponent} from './components/buttons/button-medium/button-medium.component';
import {LogoButtonComponent} from './components/logo-button/logo-button.component';
import {AppSvgIconComponent} from './components/svg-icon/svg-icon.component';
import {CommonModule} from '@angular/common';
import {NotificationBellComponent} from './components/notification-bell/notification-bell.component';
import {RoomQuickAccessComponent} from './components/room-quick-access/room-quick-access.component';
import {SwiperModule} from 'swiper/angular';
import {ButtonSwitchComponent} from './components/buttons/button-switch/button-switch.component';
import {PowerButtonComponent} from './components/power-button/power-button.component';
import {RoomRemoteComponent} from './pages/room/components/remote/remote.component';
import {NgxQRCodeModule} from '@techiediaries/ngx-qrcode';
import {IonicModule} from '@ionic/angular';
import {HeaderComponent} from './components/header/header.component';
import {WifiModalComponent} from './modals/wifi/wifi-modal.component';
import {MembershipModalComponent} from './modals/membership/membership-modal.component';
import {RouterModule} from '@angular/router';
import {SearchSelectComponent} from './components/search-select/search-select.component';
import {CameraComponent} from './components/camera/camera.component';
import {InviteAttendeesComponent} from './components/invite-attendees/invite-attendees.component';
import {PropertySelectorComponent} from './components/property-selector/property-selector.component';
import {OrderItemComponent} from './components/order-item/order-item.component';
import {MeetdistrictCalendarComponent} from './components/meetdistrict-calendar/meetdistrict-calendar.component';
import {WalletListComponent} from './components/wallet-list/wallet-list.component';
import {ButtonComponent} from './components/buttons/button/button.component';
import {QuickBookModalComponent} from './modals/quick-book/quick-book-modal.component';
import {MeetboxComponent} from './components/meetbox/meetbox.component';
import {PayModalComponent} from './modals/pay/pay-modal.component';
import {CancelMeetingModalComponent} from './modals/cancel-meeting/cancel-meeting-modal.component';
import {TimelineComponent} from './components/timeline/timeline.component';
import {
  WalletSelectorModalComponent
} from './modals/wallet-selector/wallet-selector-modal.component';
import {
  CameraFileBrowserChoiceModalComponent
} from './modals/camera-file-browser-choice/camera-file-browser-choice-modal.component';
import {
  PrinterSettingsModalComponent
} from './pages/print/modals/printer-settings/printer-settings-modal.component';
import {MeetingsListComponent} from './components/meetings-list/meetings-list.component';
import {WalletSelectComponent} from './components/wallet-select/wallet-select.component';
import {ContactListModalComponent} from './modals/contact-list/contact-list-modal.component';
import {FileSelectComponent} from './components/file-select/file-select.component';
import {OptionModalComponent} from './pages/car-charging/modals/privacy/option-modal.component';
import {
  ChargingCompletedModalComponent
} from './pages/car-charging/modals/charging-completed/charging-completed-modal.component';
import {IconChangeModalComponent} from './modals/icon-change/icon-change-modal.component';

@NgModule({
  declarations: [
    ButtonMediumComponent,
    LogoButtonComponent,
    AppSvgIconComponent,
    NotificationBellComponent,
    RoomQuickAccessComponent,
    ButtonSwitchComponent,
    PowerButtonComponent,
    NumberSliderComponent,
    RoomRemoteComponent,
    HeaderComponent,
    WifiModalComponent,
    MembershipModalComponent,
    SearchSelectComponent,
    CameraComponent,
    InviteAttendeesComponent,
    PropertySelectorComponent,
    OrderItemComponent,
    MeetdistrictCalendarComponent,
    WalletListComponent,
    ButtonComponent,
    QuickBookModalComponent,
    MeetboxComponent,
    PayModalComponent,
    CancelMeetingModalComponent,
    TimelineComponent,
    WalletSelectorModalComponent,
    CameraFileBrowserChoiceModalComponent,
    PrinterSettingsModalComponent,
    MeetingsListComponent,
    WalletSelectComponent,
    ContactListModalComponent,
    FileSelectComponent,
    OptionModalComponent,
    ChargingCompletedModalComponent,
    IconChangeModalComponent,
  ],
  imports: [
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    CommonModule,
    SwiperModule,
    NgxQRCodeModule,
    IonicModule,
    FormsModule,
    RouterModule,
  ],
  exports: [
    ButtonMediumComponent,
    SvgIconComponent,
    LogoButtonComponent,
    AppSvgIconComponent,
    NotificationBellComponent,
    RoomQuickAccessComponent,
    ButtonSwitchComponent,
    PowerButtonComponent,
    NumberSliderComponent,
    RoomRemoteComponent,
    WifiModalComponent,
    MembershipModalComponent,
    HeaderComponent,
    SearchSelectComponent,
    CameraComponent,
    InviteAttendeesComponent,
    PropertySelectorComponent,
    OrderItemComponent,
    MeetdistrictCalendarComponent,
    WalletListComponent,
    ButtonComponent,
    QuickBookModalComponent,
    MeetboxComponent,
    PayModalComponent,
    CancelMeetingModalComponent,
    TimelineComponent,
    WalletSelectorModalComponent,
    CameraFileBrowserChoiceModalComponent,
    PrinterSettingsModalComponent,
    MeetingsListComponent,
    WalletSelectComponent,
    ContactListModalComponent,
    FileSelectComponent,
    IconChangeModalComponent,
  ],
})
export class AppComponentsModule {
}
