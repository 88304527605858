import {RoomBlindsComponent} from '../blinds/blinds.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';
import {RoomContainerComponent} from './room-container.component';
import {AppComponentsModule} from '../../../../app_components.module';
import {RoomHvacComponent} from '../hvac/hvac.component';
import {RoomLightsComponent} from '../lights/lights.component';
import {RoomDoorComponent} from '../door/door.component';
import {RoomHeaderComponent} from '../room-header/room-header.component';
import {RoomNavigationComponent} from '../room-navigation/room-navigation.component';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, AppComponentsModule],
  declarations: [
    RoomContainerComponent,
    RoomBlindsComponent,
    RoomHvacComponent,
    RoomLightsComponent,
    RoomDoorComponent,
    RoomHeaderComponent,
    RoomNavigationComponent],
  exports: [
    RoomContainerComponent,
    RoomHvacComponent,
    RoomDoorComponent,
    RoomLightsComponent,
    RoomBlindsComponent],
})
export class RoomContainerComponentModule {
}
