import {Injectable} from '@angular/core';
import {RoomControlsApiService} from './API/RoomControlsApiService';

@Injectable({
  providedIn: 'root'
})
export class RoomControlService {

  constructor(private api: RoomControlsApiService) {
  }

  public unlock(roomId: number, pmsId: number = null) {
    return this.api.unlock(roomId, pmsId);
  }

  public lightStatus(roomId: number) {
    return this.api.getLightStatus(roomId).then(response => !!response.state);
  }

  public lightsOn(roomId: number) {
    return this.api.updateLightStatus(roomId, 'on');
  }

  public lightsOff(roomId: number) {
    return this.api.updateLightStatus(roomId, 'off');
  }

  public blindsUp(roomId: number) {
    return this.api.updateBlindStatus(roomId, 'on', 'up');
  }

  public blindsPause(roomId: number) {
    return this.api.updateBlindStatus(roomId, 'off', 'up');
  }

  public blindsDown(roomId: number) {
    return this.api.updateBlindStatus(roomId, 'on', 'down');
  }

  public temperature(roomId: number) {
    return this.api.temperature(roomId);
  }

  public setTemperature(roomId: number, temperature: number) {
    return this.api.setTemperature(roomId, temperature);
  }

  public setOperatingModeAuto(roomId: number) {
    return this.api.setOperatingMode(roomId, 'auto');
  }

  public setOperatingModeHeating(roomId: number) {
    return this.api.setOperatingMode(roomId, 'heating');
  }

  public setOperatingModeCooling(roomId: number) {
    return this.api.setOperatingMode(roomId, 'cooling');
  }

}
