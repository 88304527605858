import {Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ToastService} from '../../../../services/General/ToastService';
import {NavController} from '@ionic/angular';
import {DisplayService} from '../../../../services/Displays/DisplayService';

@Component({
  selector: 'app-room-tv',
  templateUrl: './tv.component.html',
  styleUrls: ['./tv.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RoomDoorComponent implements OnInit {
  @ViewChild('switch') switch: ElementRef;
  @Input() displayId: number;
  @Input() roomId: number;
  ports = [
    // {
    //   name: 'hdmi',
    //   icon: 'hdmi',
    // },
    // {
    //   name: 'usb-c',
    //   icon: 'usbc',
    // }
  ];
  constructor(
    private navController: NavController,
    private displaySerivce: DisplayService,
    private toastService: ToastService,
) {
  }

  ngOnInit(): void {
  }

  openAllTvControls(){
    this.navController.navigateForward(['room',{id: this.roomId}],{state: {selectedButton: 'tv'}});
  }

  changeTvOutput(output: string){
    if(output === 'hdmi'){
      this.displaySerivce.sourceHDMI(this.displayId).then(result => {
        this.toastService.toast('Output updated.');
      });
    }
    if(output === 'usb-c'){
      //console.log('usb-c');
    }
    else{
      this.toastService.toast('Output could not be triggerd');
    }
  }
}
