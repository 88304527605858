/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../General/ApiService';

@Injectable({
  providedIn: 'root'
})
export class OrderApiService {
  constructor(private api: ApiService) {
  }

  public orders() {
    return this.api.get('order/my-orders');
  }

  public orderItems(date: string, propertyId: number, category: number) {
    const params = {
      date,
      property_id: propertyId,
      category_id: category,
    };
    return this.api.get('article/all', params);
  }

  public cancelOrder(orderId: number) {
    return this.api.post(`order/${orderId}/cancel`);
  }
}
