import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {DateTime} from 'luxon';
import {EditMeetingModalComponent} from '../../modals/edit-meeting-modal/edit-meeting-modal.component';
import {MeetingService} from '../../../../services/Meetings/MeetingService';
import {CalendarService} from '../../../../services/Calendars/CalendarService';
import {ToastService} from '../../../../services/General/ToastService';
import {AssetTypes} from '../../../../services/Assets/AssetTypes';

@Component({
  selector: 'app-meeting-information',
  templateUrl: './meeting-information.component.html',
  styleUrls: ['./meeting-information.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class MeetingInformationComponent implements OnChanges {
  @Input() meeting: any;
  @Input() isNative = false;
  @Input() meetingInFuture = false;
  @Input() isCapacityReservation: boolean;
  @Output() eventUpdated = new EventEmitter<void>();
  from: string;
  till: string;
  location: string;
  hasTv: boolean;
  hasWhiteboard: boolean;
  hasCam: boolean;
  hasDoor: boolean;
  hasBlinds: boolean;
  hasVentilation: boolean;
  meetingAboutToStart = false;
  meetingEnded = false;
  modal: any;

  constructor(private nav: NavController, private modalCtrl: ModalController,
              private meetingService: MeetingService, private calendar: CalendarService,
              private toaster: ToastService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.meeting) {
      this.from = this.meeting.from.toFormat('dd LLLL HH:mm');
      this.till = this.meeting.till.toFormat('HH:mm');
      this.location = this.isNative ? `${this.meeting.room.property.name}` :
        `${this.meeting.room.property.street}, ${this.meeting.room.property.city}`;
      this.meetingEnded = DateTime.now() > this.meeting.till;
      this.meetingAboutToStart = this.meeting.from < DateTime.now().plus({minute: 15});
      this.checkAssets(this.meeting.room.assets_in_the_room);
    }
  }

  handleDescription() {
    return this.urlify(this.meeting.description);
  }

  urlify(text) {
    if (text) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, (url) =>
        '<a class="link" href="' + url + '">' + url.replace('https://', '').replace('http://', '') + '</a>');
    }
  }

  checkAssets(assets) {
    this.hasDoor = assets.some(a => a.assetType_id === String(AssetTypes.loxoneDoor));
    this.hasBlinds = assets.some(a => a.assetType_id === String(AssetTypes.blinds));
    this.hasVentilation = assets.some(a => a.assetType_id === String(AssetTypes.ventilation));
    this.hasTv = assets.some(a => a.assetType_id === String(AssetTypes.tv));
    this.hasWhiteboard = assets.some(a => a.assetType_id === String(AssetTypes.whiteboard));
    this.hasCam = assets.some(a => a.assetType_id === String(AssetTypes.teams));
  }

  roomOptions() {
    this.nav.navigateForward(['/room', {id: this.meeting.room.id, canGoBack: true}]);
  }

  async editMeeting() {
    this.modal = await this.modalCtrl.create({
      component: EditMeetingModalComponent,
      initialBreakpoint: .55,
      breakpoints: [0, .55],
      componentProps: {
        subject: this.meeting.subject,
        description: this.meeting.description,
      },
    });
    this.modal.present().then(() => {
      const handle = document.querySelector('.show-modal').shadowRoot.querySelector('.modal-handle');
      handle.addEventListener('click', () => {
        this.modal.dismiss();
      });
    });

    const {data, role} = await this.modal.onWillDismiss();
    if (role === 'updated') {
      this.handleUpdate(data.subject, data.description);
    }
  }

  handleUpdate(subject: string, description: string) {
    if (this.isNative) {
      this.calendar.updateExistingEventInformation(this.meeting.originalNativeEvent.id, subject, description).then(() => {
        this.meeting.subject = subject;
        this.meeting.description = description;
        this.eventUpdated.emit();
      });
    } else {
      this.meetingService.update(this.meeting.id, subject, description).then(() => {
        this.meeting.subject = subject;
        this.meeting.description = description;
        this.eventUpdated.emit();
      }).catch(() => {
        this.toaster.toast('Could not update meeting information');
      });
    }
  }
}
