import {Injectable} from '@angular/core';
import OneSignal from 'onesignal-cordova-plugin';
import {Platform} from '@ionic/angular';
import {NotificationService} from './NotificationService';
import {RedirectService} from '../Routing/RedirectService';
import {Vibration} from '@awesome-cordova-plugins/vibration/ngx';
import {ApiService} from '../General/ApiService';
import {TenantService} from '../Tenant/TenantService';
import {App} from '@capacitor/app';

@Injectable({
  providedIn: 'root'
})
export class OneSignalService {
  active = false;

  constructor(private platform: Platform, private service: NotificationService,
              private redirect: RedirectService, private vibrate: Vibration,
              private api: ApiService, private tenant: TenantService) {
    if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
      this.init();
    }
  }

  init() {
    const app = this;
    App.getInfo().then(info => {
      if (info.id === 'com.meetdistrict.MeetDistrict') {
        OneSignal.initialize('d07916c4-f3d5-4a09-bc4f-a6e78c5f7d28');
      } else {
        OneSignal.initialize('d2ea1afe-b5a4-46d0-93b8-3e08341c4576');
      }
      OneSignal.Notifications.addEventListener('foregroundWillDisplay', (event) => app.checkForegroundNotification(event));
      OneSignal.Notifications.addEventListener('click', (event) => app.notificationOpenedCallback(event));

      this.active = true;
    });
  }

  checkForegroundNotification(notificationEvent) {
    notificationEvent.preventDefault();
    if (notificationEvent.notification.body || notificationEvent.notification.title) {
      this.notificationReceivedInForeground(notificationEvent);
    }
  }

  notificationReceivedInForeground(notificationEvent) {
    if (this.platform.is('android')) {
      this.vibrate.vibrate([500, 500, 500]);
    } else {
      this.vibrate.vibrate(700);
    }
    this.service.count.next(this.service.count.value + 1);
  }

  notificationOpenedCallback(notificationEvent) {
    const url = notificationEvent.notification.launchURL;
    if (this.api.token) {
      this.handleNotificationUrl(url);
    } else {
      setTimeout(() => {
        this.handleNotificationUrl(url);
      }, 1000);
    }
  }

  handleNotificationUrl(url) {
    let empty;
    let type;
    let param;
    if (url.substring(0, 1) === '/') {
      [empty, type, param] = url.split('/');
    } else {
      [type, param] = url.split('/');
    }
    this.redirect.navigate(type, param ?? null);
  }

  public setUser(userId) {
    if (this.active) {
      OneSignal.login(`${this.tenant.getOneSignalPrefix()}${userId}`);
    }
  }
}
