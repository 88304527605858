import {Component, Input} from '@angular/core';
import {ValutaHelperService} from '../../services/General/ValutaHelperService';
import {Order} from '../../services/Orders/order.interface';
import {OrderService} from '../../services/Orders/OrderService';
import {ToastService} from '../../services/General/ToastService';
import Bugsnag from '@bugsnag/js';

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss'],
})
export class OrderItemComponent {
  @Input() order: Order;
  @Input() canEdit = false;
  disableCancelBtn = false;
  cancelConfirmation = false;
  deleted = false;

  constructor(private valuta: ValutaHelperService, private service: OrderService, private toaster: ToastService) {
  }

  formatValuta(price: number) {
    return this.valuta.formatValuta(price, 2);
  }

  showCancelOrderConfirmation() {
    this.cancelConfirmation = true;
  }

  hideCancelOrderConfirmation() {
    this.cancelConfirmation = false;
  }

  cancelOrder(orderId) {
    this.disableCancelBtn = true;
    this.service.cancelOrder(orderId).then(() => {
      this.disableCancelBtn = false;
      this.toaster.toast('Order canceled');
      this.deleted = true;
    }).catch(error => {
      Bugsnag.notify(error);
      this.disableCancelBtn = false;
      this.toaster.toast('Failed to cancel order');
    });
  }

  getCancelationText(type, value) {
    switch (type) {
      case 'fullPrice':
        return 'full price';
      case 'free':
        return 'free';
      default:
        return `get ${value}% back`;
    }
  }
}
