import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {CarParkService} from '../../services/CarParks/CarParkService';
import {CacheService} from '../../services/Cache/CacheService';
import {SwiperComponent} from 'swiper/angular';
import {ModalController, NavController} from '@ionic/angular';
import {UserService} from '../../services/User/UserService';
import {StorageService} from '../../services/Storage/StorageService';
import {ModuleService} from '../../services/Modules/ModuleService';
import SwiperCore, {Navigation, Controller, Pagination} from 'swiper';

SwiperCore.use([Navigation, Pagination, Controller]);


@Component({
  selector: 'app-car-parks-controls',
  templateUrl: './car-parks-controls-modal.component.html',
  styleUrls: ['./car-parks-controls-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CarParksControlsModalComponent implements AfterViewInit {
  @ViewChild('titleSwiper', {static: false}) titleSwiper!: SwiperComponent;
  @ViewChild('swiper', {static: false}) swiper!: SwiperComponent;
  @ViewChild('carParkContent') carParkContent: ElementRef;
  @Input() parkingId: string;
  carParks: Array<any> = null;
  carParksByProperty: Array<any> = [];
  carParkSession: any = null;
  lastUsedCarParkId: number;
  swiperIndex = 0;


  constructor(private service: CarParkService, private cache: CacheService,
              private modalCtrl: ModalController, private nav: NavController,
              private user: UserService, private storage: StorageService,
              public modules: ModuleService, private change: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    this.storage.get('last-used-carpark').then(lastUsedCarParkId => {
      this.lastUsedCarParkId = lastUsedCarParkId;
      this.cache.getWithDelayedObservable('personal-car-parks', this.service.carParks()).subscribe(carParks => {
        this.carParks = carParks.filter(carPark => carPark.devices.length);
        this.sortCarParksByProperty();
      });
      this.service.currentSession().then(session => {
        this.carParkSession = session;
        if (this.carParksByProperty.length > 1 && session.activeCarPark) {
          this.sortBySession(session);
          if (this.lastUsedCarParkId !== session.activeCarPark) {
            this.lastUsedCarParkId = session.activeCarPark;
            this.storage.set('last-used-carpark', session.activeCarPark);
            this.sortCarParksByProperty();
          }
        }
      });
    });
  }

  sortCarParksByProperty() {
    this.carParksByProperty = [];
    this.carParks
      .sort((x, y) => x.id === this.lastUsedCarParkId ? -1 : y.id === this.lastUsedCarParkId ? 1 : 0)
      .forEach(carPark => {
        const carParkProperty = this.carParksByProperty.filter(property => property.property.id === carPark.property.id);
        if (!carParkProperty.length) {
          this.carParksByProperty.push({
            property: carPark.property,
            carParks: [carPark]
          });
        } else {
          carParkProperty[0].carParks.push(carPark);
        }
      });
    if (this.carParksByProperty.length > 1) {
      this.slidesInit();
    }
  }

  slidesInit() {
    setTimeout(() => {
      this.titleSwiper.swiperRef.controller.control = this.swiper.swiperRef;
      this.swiper.swiperRef.controller.control = this.titleSwiper.swiperRef;

      this.titleSwiper.swiperRef.slideTo(this.carParksByProperty.length - 1, 0);
      this.swiper.swiperRef.slideTo(this.carParksByProperty.length - 1, 0);

      // Manually trigger loop behavior
      this.titleSwiper.swiperRef.on('slideChangeTransitionEnd', () => {
        const swiper = this.titleSwiper.swiperRef;
        const slidesCount = swiper.slides.length;
        const activeIndex = swiper.activeIndex;

        this.swiperIndex = activeIndex;

        if (activeIndex === slidesCount - 1) {
          swiper.slideToLoop(1, 0);
        } else if (activeIndex === 0) {
          swiper.slideToLoop(slidesCount - 2, 0);
        }
      });

      this.swiper.swiperRef.on('slideChangeTransitionEnd', () => {
        const swiper = this.swiper.swiperRef;
        const slidesCount = swiper.slides.length;
        const activeIndex = swiper.activeIndex;

        if (activeIndex === slidesCount - 1) {
          swiper.slideToLoop(1, 0);
        } else if (activeIndex === 0) {
          swiper.slideToLoop(slidesCount - 2, 0);
        }
      });

      if (this.carParkSession && this.carParkSession.activeCarPark) {
        this.sortBySession(this.carParkSession);
      } else {
        this.user.profile().then(profile => {
          const index = this.carParksByProperty.findIndex((property) =>
            property.property.id === parseInt(profile.defaultProperty_id, 10
            ));
          if (index >= 0) {
            this.titleSwiper.swiperRef.slideTo(this.carParksByProperty.length - 1 + index, 0);
            this.swiper.swiperRef.slideTo(this.carParksByProperty.length - 1 + index, 0);
          }
        });
      }

      this.change.detectChanges();
    });
  }

  sortBySession(session) {
    const index = this.carParksByProperty.findIndex((property) =>
      property.carParks.some(carPark => carPark.id === session.activeCarPark));
    if (index >= 0) {
      this.titleSwiper.swiperRef.slideTo(this.carParksByProperty.length - 1 + index, 0);
      this.swiper.swiperRef.slideTo(this.carParksByProperty.length - 1 + index, 0);
    }
  }

  scan() {
    this.modalCtrl.dismiss();
    this.nav.navigateForward(['/scan']);
  }
}
