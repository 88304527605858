import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-room-header',
  templateUrl: './room-header.component.html',
  styleUrls: ['./room-header.component.scss']
})

export class RoomHeaderComponent {
  @Input() room: any;

  constructor() {
  }

}
