import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-button-medium',
  templateUrl: './button-medium.component.html',
  styleUrls: ['./button-medium.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ButtonMediumComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() icon: string;
  @Input() route: string;
  @Input() label: string;
  @Input() disabled = false;
  @Input() iconWidth = '';
  @Input() iconHeight = '';
  @Input() iconFill = '';
  @Input() unreadStatus = false;

  constructor(private router: Router) {
  }

  goToRoute() {
    if (this.route) {
      this.router.navigate([this.route]);
    }
  }

  getStyleObject() {
    return {
      ...(this.iconWidth !== '' && {width: this.iconWidth}),
      ...(this.iconHeight !== '' && {height: this.iconHeight}),
      ...(this.iconFill !== '' && {fill: this.iconFill})
    };
  }
}
