/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {StatusBar, Style} from '@capacitor/status-bar';
import {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class StatusBarService {
  browser: boolean;

  constructor(private platform: Platform) {
    this.browser = this.platform.is('mobileweb') || this.platform.is('desktop');
  }

  setLightColor() {
    if(!this.browser) {
      StatusBar.setBackgroundColor({color: '#ffffff'});
      StatusBar.setStyle({style: Style.Light});
    }
  }

  setDarkColor() {
    if(!this.browser) {
      StatusBar.setBackgroundColor({color: '#000000'});
      StatusBar.setStyle({style: Style.Dark});
    }
  }

  forceLightColor() {
    if(!this.browser) {
      const interval = setInterval(() => {
        StatusBar.setBackgroundColor({color: '#ffffff'});
        StatusBar.setStyle({style: Style.Light});
      }, 100);
      setTimeout(() => {
        clearInterval(interval);
      }, 1000);
    }
  }

}
