import {Injectable} from '@angular/core';
import {SeatReservationApiService} from './API/SeatReservationApiService';
import {ToastService} from '../General/ToastService';
import {NavController} from '@ionic/angular';
import {PaymentModalService} from '../Payment/PaymentModalService';
import Bugsnag from '@bugsnag/js';

@Injectable({
  providedIn: 'root'
})
export class SeatService {
  constructor(private api: SeatReservationApiService, private toaster: ToastService,
              private nav: NavController, private paymentModal: PaymentModalService) {
  }

  public seat(seatId) {
    this.api.seat(seatId).then(response => {
      this.handleSeatInformation(response);
    }).catch(err => {
      Bugsnag.notify(err);
      this.toaster.toast(err.error.message);
      this.home();
    });
  }

  public seatFromKiosk(kioskId, hash) {
    this.api.seatFromKiosk(kioskId, hash).then(response => {
      this.handleSeatInformation(response);
    }).catch(err => {
      Bugsnag.notify(err);
      this.toaster.toast(err.error.message);
      this.home();
    });
  }

  home() {
    this.nav.navigateRoot('/home');
  }

  private handleSeatInformation(seatInfo) {
    if(!seatInfo.complete) {
      this.handlePaymentModal(seatInfo);
    } else {
      this.toaster.toast('Seat is reserved');
      this.home();
    }
  }

  private async handlePaymentModal(seatInfo) {
    this.paymentModal.openModal({
      type: 'seatReservation',
      params: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        seat_id: seatInfo.data.seat_id,
        type: 'flex',
        date: seatInfo.data.date,
        property: seatInfo.data.property_id
      },
    }).then(result => {
      if (result.role === 'success') {
        this.toaster.toast('Seat successfully reserved');
        this.home();
      }
    });
  }
}
