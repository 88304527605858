import {Injectable} from '@angular/core';
import {TagsApiService} from './API/TagsApiService';

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  constructor(private api: TagsApiService) {
  }

  public tag(tagId: number, tenant: string = null) {
    return this.api.tag(tagId, tenant);
  }

  public create(tagId: number, assetId: number) {
    return this.api.create(tagId, assetId);
  }
}
