import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {IonicModule} from '@ionic/angular';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {IonicStorageModule, Storage} from '@ionic/storage-angular';
import {HttpClientModule} from '@angular/common/http';
import {ScreenOrientation} from '@awesome-cordova-plugins/screen-orientation/ngx';
import {NgxQRCodeModule} from '@techiediaries/ngx-qrcode';
import {Calendar} from '@awesome-cordova-plugins/calendar/ngx';
import {AppVersion} from '@awesome-cordova-plugins/app-version/ngx';
import {Deeplinks} from '@awesome-cordova-plugins/deeplinks/ngx';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {NFC} from '@awesome-cordova-plugins/nfc/ngx';
import {TypeFormPageModule} from './pages/type-form/type-form.module';
import {TypeFormAnswersPageModule} from './pages/type-form-answers/type-form-answers.module';
import {MeetingPageModule} from './pages/meeting/meeting.module';
import {TabsPageModule} from './components/page-tabs/tabs/tabs.module';
import {customAnimation} from './animations/route-animation';
import {CarParksControlsModalModule} from './modals/car-parks-controls/car-parks-controls-modal.module';
import {NotificationCenterModalModule} from './modals/notification-center/notification-center-modal.module';
import {ValutaHelperService} from './services/General/ValutaHelperService';
import {Vibration} from '@awesome-cordova-plugins/vibration/ngx';
import {AndroidPermissions} from '@awesome-cordova-plugins/android-permissions/ngx';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import * as cordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import {Drivers} from '@ionic/storage';

Bugsnag.start('9b731a306b1fa3d9d34c50f39cec84b8');

export const errorHandlerFactory = () => new BugsnagErrorHandler();

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({mode: 'md', navAnimation: customAnimation}),
    AppRoutingModule,
    // eslint-disable-next-line no-underscore-dangle
    IonicStorageModule.forRoot({driverOrder: [cordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]}),
    HttpClientModule,
    NgxQRCodeModule,
    MeetingPageModule,
    TypeFormPageModule,
    TypeFormAnswersPageModule,
    NotificationCenterModalModule,
    TabsPageModule,
    CarParksControlsModalModule,
  ],
  providers: [
    ScreenOrientation,
    Calendar,
    AppVersion,
    Deeplinks,
    InAppBrowser,
    NFC,
    ValutaHelperService,
    Vibration,
    AndroidPermissions,
    Storage,
    { provide: ErrorHandler, useFactory: errorHandlerFactory }
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
}
