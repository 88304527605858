/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../General/ApiService';

@Injectable({
  providedIn: 'root'
})
export class AppApiService {
  constructor(private api: ApiService) {
  }

  public minAppVersion() {
    return this.api.get('minAppVersion');
  }

  public latestAppVersion() {
    return this.api.get('latestAppVersion');
  }
}
