import {Injectable} from '@angular/core';
import {PropertyApiService} from './API/PropertyApiService';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  constructor(private api: PropertyApiService) {
  }

  public properties() {
    return this.api.properties();
  }
}
