import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {Wallet} from '../../pages/wallets/interfaces/wallet.interface';
import {WalletService} from '../../services/Wallet/WalletService';
import {ItemReorderEventDetail, ModalController} from '@ionic/angular';
import {WalletMetaDataService} from '../../services/Wallet/WalletMetaDataService';
import {WalletTopUpModalComponent} from '../../pages/wallets/modals/wallet-top-up/wallet-top-up-modal.component';
import {TenantService} from '../../services/Tenant/TenantService';

@Component({
  selector: 'app-wallet-list',
  templateUrl: './wallet-list.component.html',
  styleUrls: ['./wallet-list.component.scss'],
})
export class WalletListComponent implements OnChanges, OnDestroy {
  @Input() wallets: Array<Wallet> = [];
  @Input() selectedWallet = 0;
  @Input() allowReorder = true;
  @Input() fullWidth = false;
  @Output() selectedWalletChanged = new EventEmitter<number>();
  @Output() newWalletType = new EventEmitter<string>();
  @Output() shouldUpdate = new EventEmitter<void>();
  disableReorder = true;
  timer: any;
  modal: any;
  updates: any;
  tenantName: string = null;
  tenantIcon: string = null;

  constructor(public service: WalletService, private metadata: WalletMetaDataService,
              private modalCtrl: ModalController,
              private tenantService: TenantService) {
    this.init();
    this.tenantName = tenantService.getName();
    this.tenantIcon = tenantService.getIcon();
  }

  init() {
    this.updates = this.service.walletUpdated.subscribe((time) => {
      this.shouldUpdate.emit();
    });
  }

  ngOnDestroy() {
    this.updates.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedWallet) {
      this.disableReorder = true;
    }
  }

  updateActiveWallet(sequenceId, type = null) {
    this.selectedWalletChanged.emit(sequenceId);
    if (type) {
      this.newWalletType.emit(type === 'customer-token' ? 'token' : 'euro');
    }
  }

  handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    const from = ev.detail.from;
    const to = ev.detail.to;
    this.metadata.updateSequence(from, to).then(() => {
      this.shouldUpdate.emit();
      if (this.between(this.selectedWallet, from < to ? from : to, from < to ? to : from)) {
        if (this.selectedWallet === from) {
          this.updateActiveWallet(this.selectedWallet + to - from);
        } else {
          this.updateActiveWallet(this.selectedWallet + (from > to ? 1 : -1));
        }
        this.disableReorder = false;
      }
    });
    ev.detail.complete();
  }

  between = (x, min, max) => x >= min && x <= max;

  holdingWallet() {
    if (this.allowReorder) {
      this.timer = setTimeout(() => {
        this.disableReorder = false;
      }, 500);
    }
  }

  stopHoldingWallet() {
    clearTimeout(this.timer);
  }

  async topup(wallet) {
    if (wallet.type === 'wallet') {
      this.modal = await this.modalCtrl.create({
        component: WalletTopUpModalComponent,
        initialBreakpoint: .40,
        breakpoints: [0, .40]
      });
      this.modal.present().then(() => {
        const nodes = document.querySelectorAll('.show-modal');
        const handle = nodes[nodes.length - 1].shadowRoot.querySelector('.modal-handle');
        handle.addEventListener('click', () => {
          this.modal.dismiss();
        });
      });

      await this.modal.onWillDismiss();
      this.shouldUpdate.emit();
    }
  }
}
