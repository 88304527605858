/* eslint-disable @typescript-eslint/naming-convention*/
import {Injectable} from '@angular/core';
import {OrderApiService} from './API/OrderApiService';
import {DateTime} from 'luxon';
import {Order} from './order.interface';
import {
  OrderCateringItem
} from '../../pages/order-catering/components/order-catering-overview/order-catering-item.interface';
import {
  OrderCateringFilterResponse
} from '../../pages/order-catering/components/order-catering-filter/order-catering-filter-response.interface';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(private api: OrderApiService) {
  }

  public orders(includeHistory: boolean = false) {
    return this.api.orders().then(response => this.formatOrders(response.data, includeHistory));
  }

  public cateringItems(date: DateTime, time: DateTime, propertyId) {
    return this.api.orderItems(
      `${date.toFormat('yyyy-LL-dd')} ${time.toFormat('HH:mm:ss')}`,
      propertyId,
      1
    ).then(response => this.formatCateringItems(response.data));
  }

  public cancelOrder(orderId: number) {
    return this.api.cancelOrder(orderId);
  }

  public formatPaymentCateringOrder(
    filter: OrderCateringFilterResponse,
    propertyId: number,
    items: Array<OrderCateringItem>,
    remark: string = null,
    meetingId: number = null,
    roomId: number = null) {
    return {
      type: 'Catering',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      property_id: propertyId,
      date: `${filter.date.toFormat('yyyy-LL-dd')} ${filter.time.toFormat('HH:mm:ss')}`,
      items: this.formatPaymentCateringItems(items),
      ...(remark && {remark}),
      ...(meetingId && {roomBooking_id: meetingId}),
      ...(roomId && {room_id: roomId}),
    };
  }

  public formatPaymentCateringItems(items: Array<OrderCateringItem>) {
    // @ts-ignore
    return items
      .filter(item => item.amount)
      .map(item => ({
        article_id: item.id,
        amount: item.amount,
        price: item.price,
      }));
  }

  private formatOrders(orders: Array<any>, includeHistory: boolean): Array<Order> {
    orders = orders.map(order => ({
      id: order.id,
      type: 'Order',
      date: DateTime.fromFormat(order.date, 'yyyy-LL-dd HH:mm:ss.SSS'),
      items: this.formatOrderItems(order.items),
      totalPrice: this.itemsPrice(order.items),
      remark: order.remark,
      canCancel: order.cancellationAllowed,
      cancellationType: order.cancellationType,
      deliveryType: order.deliveryType,
      roomName: order.room_booking ? order.room_booking.room.name : 'ROOMNAME',
      bookingTitle: order.room_booking ? order.room_booking.subject : null,
      imageUrl: order.imageUrl,
      name: order.name,
    }));
    if (includeHistory) {
      return orders;
    } else {
      return orders.filter(order => order.date >= DateTime.now().startOf('day'));
    }
  }

  private formatOrderItems(items: Array<any>) {
    return items.map(item => ({
      amount: parseInt(item.amount, 10),
      article: item.article,
      articleName: item.article_name,
      price: parseFloat(item.price),
    }));
  }

  private formatCateringItems(items: Array<any>) {
    return items.map(item => ({
      id: item.id,
      name: item.name,
      price: parseFloat(item.price),
      description: item.description,
      image: item.imageUrl,
      minimumAmount: item.minimumAmount,
      amount: 0,
    }));
  }

  private itemsPrice(items: Array<any>) {
    let totalPrice = 0;
    items.forEach(item => {
      totalPrice += parseInt(item.amount, 10) * parseFloat(item.price);
    });
    return totalPrice;
  }
}
