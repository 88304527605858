import {ChangeDetectorRef, Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MeetingService} from '../../services/Meetings/MeetingService';
import {DateTime} from 'luxon';
import {TimelineEvent} from '../../components/timeline/timeline-event.interface';
import {UserService} from '../../services/User/UserService';
import {CalendarService} from '../../services/Calendars/CalendarService';
import Bugsnag from '@bugsnag/js';
import {ModalController, NavController, Platform} from '@ionic/angular';
import {NativeMeetingToMdMeetingAdapter} from '../../adapters/NativeMeetingToMdMeetingAdapter';
import {QuickBookModalComponent} from '../../modals/quick-book/quick-book-modal.component';
import {PaymentModalService} from '../../services/Payment/PaymentModalService';
import {ToastService} from '../../services/General/ToastService';
import {DisplayService} from '../../services/Displays/DisplayService';
import {ModuleService} from '../../services/Modules/ModuleService';
import {OfficeService} from '../../services/General/OfficeService';

@Component({
  selector: 'app-meeting',
  templateUrl: 'meeting.page.html',
  styleUrls: ['meeting.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MeetingPage {
  meetingId: number | string;
  isNative = false;
  meeting: any;
  profile: any;
  backRoute = '/calendar';
  meetingInFuture = false;
  mainEvent: TimelineEvent;
  canProlongMeeting = false;
  inviteOthers = false;
  otherEvents: Array<TimelineEvent>;
  prolongDuration: TimelineEvent = null;
  host = false;
  currentNavigation = 'details';
  meetingActive = false;
  meetingEnded = false;
  meetingAboutToStart = false;
  navigationItems = [];
  modal: any;
  displayId: number;
  displays: Array<any>;
  remote: any;
  maxContainerHeight = 800;
  isCapacityReservation = false;

  constructor(private route: ActivatedRoute, private service: MeetingService,
              public user: UserService, private change: ChangeDetectorRef,
              private calendar: CalendarService, private platform: Platform,
              private meetingAdapter: NativeMeetingToMdMeetingAdapter, private modalCtrl: ModalController,
              private nav: NavController, private paymentModal: PaymentModalService,
              private toaster: ToastService, private displayService: DisplayService,
              public modules: ModuleService, private officeService: OfficeService) {
    this.meetingId = this.route.snapshot.paramMap.get('id');
    this.isNative = !!this.route.snapshot.paramMap.get('native');
    this.backRoute = this.route.snapshot.paramMap.get('route') ?? '/calendar';
    this.init();
  }

  init() {
    this.user.profile().then(profile => {
      this.profile = profile;
      if (this.isNative) {
        this.handleNativeMeeting();
      } else {
        // @ts-ignore
        this.meetingId = parseInt(this.meetingId, 10);
        this.handleMeeting(this.meetingId);
      }
    });

    this.service.attendees.subscribe(attendees => {
      if (this.meeting && attendees.length) {
        this.meeting.attendees = attendees;
      }
    });
    this.updateMenuItems();
  }

  calculateContainerHeight() {
    setTimeout(() => {
      const element = document.getElementById('app-meeting-page');
      if (element) {
        this.setContainerHeight(element);
      }
    }, 200);
  }

  setContainerHeight(element) {
    this.maxContainerHeight = element.scrollHeight - 57 - 150;
    this.change.detectChanges();
  }

  updateMenuItems() {
    this.navigationItems = [
      {
        name: 'details',
        active: true,
      },
      {
        name: 'invitees',
        active: true,//!this.meetingEnded,
      },
      {
        name: 'room',
        active: !this.meetingEnded && this.meetingAboutToStart && !this.isNative,
      },
      {
        name: 'options',
        active: !this.meetingEnded,
      }
    ];
  }

  handleMeeting(meetingId) {
    this.service.meeting(meetingId).then(meeting => {
      if (meeting.prolonged_room_booking_id) {
        this.handleMeeting(meeting.prolonged_room_booking_id);
      } else {
        this.meeting = meeting;
        this.completeSettingMeetingData();
        this.setTimelineEvents();
        this.calculateContainerHeight();
      }
    });
  }

  handleNativeMeeting() {
    const timeout = setTimeout(() => {
      this.handleNativeMeeting();
    }, 1000);
    Promise.all([
      this.calendar.getEventById(`${this.meetingId}`),
      this.calendar.getAttendees(`${this.meetingId}`)
    ]).then((values) => {
      clearTimeout(timeout);
      const meeting = values[0];
      if (values[1].length) {
        meeting.attendees = values[1];
      }
      this.meetingAdapter.transformNativeToMd(meeting).then(mdMeeting => {
        this.meeting = mdMeeting;
        this.completeSettingMeetingData();
      });
    });
  }

  completeSettingMeetingData() {
    const now = DateTime.now();
    this.meetingEnded = DateTime.now() > this.meeting.till;
    this.meetingAboutToStart = this.meeting.from < DateTime.now().plus({minute: 15});
    this.meetingActive = this.meeting.from < now && now.plus({second: 1}) < this.meeting.till.set({millisecond: 0});
    this.isCapacityReservation = this.meeting.is_room_assignment_final === '0';
    this.updateMenuItems();
    this.getDisplay();
    if (this.meeting.from > now) {
      this.meetingInFuture = true;
    }
    if (this.profile.id === this.meeting.user.id) {
      this.host = true;
      this.checkAttendees();
    }
    this.change.detectChanges();
  }

  getDisplay() {
    this.displayService.displays(this.meeting.room.id).then(displays => {
      if (displays && displays.length) {
        this.displays = displays;
        if (!this.navigationItems.some(btn => btn.name === 'tv')) {
          this.navigationItems.splice(3, 0, {
            name: 'tv',
            active: !this.meetingEnded && this.meetingAboutToStart && !this.isNative,
          });
        }
        this.setDisplay(this.displays[0].id);
      }
    });
  }

  setDisplay(id) {
    this.displayId = id;
    this.displayService.remote(this.displayId, this.meeting.id).then((remote) => {
      this.remote = remote;
    });
  }

  checkAttendees() {
    if (this.meeting.existingEvent_id) {
      this.calendar.getEventById(this.meeting.existingEvent_id).then(event => {
        const updateList = [];
        // const addList = [];
        event.attendees.forEach(eventAttendee => {
          const attendee = this.meeting.attendees.find(a => a.email === eventAttendee.email);
          if (attendee) {
            const nativeStatus = this.calendar.getNativeAttendeeStatus(attendee.status);
            if (nativeStatus !== eventAttendee.status) {
              eventAttendee.status = nativeStatus;
              updateList.push(eventAttendee);
            }
          }
          // else {
          //   eventAttendee.parking_reservation = false;
          //   addList.push(eventAttendee);
          // }
        });
        if (updateList.length) {
          this.calendar.updateEventAttendees(this.meeting.existingEvent_id, updateList);
        }
        // if (addList.length) {
        //   this.service.inviteAttendees(this.meeting.id, addList).then(response => {
        //     this.service.attendees.next([...this.meeting.attendees, ...response]);
        //   });
        // }
      }).catch(err => {
        Bugsnag.notify(err);
      });
    }
  }

  setTimelineEvents() {
    this.mainEvent = {
      start: {hours: this.meeting.from.hour, minutes: this.meeting.from.minute},
      end: {hours: this.meeting.till.hour, minutes: this.meeting.till.minute}
    };
    this.service.getScheduledMeetingsForIds(
      this.meeting.from.startOf('day'),
      this.meeting.till.endOf('day'),
      [this.meeting.room.id]).then(schedules => {
      this.otherEvents = [];
      schedules[0].bookings.forEach(booking => {
        const from = DateTime.fromFormat(booking.dateFrom, 'yyyy-LL-dd HH:mm:ss.SSS');
        const till = DateTime.fromFormat(booking.dateTill, 'yyyy-LL-dd HH:mm:ss.SSS');
        if (!(this.meeting.from.hasSame(from, 'hour') && this.meeting.from.hasSame(from, 'minute')) &&
          this.meeting.id !== parseInt(booking.prolonged_room_booking_id, 10)
        ) {
          this.otherEvents.push({
            start: {hours: from.hour, minutes: from.minute},
            end: {hours: till.hour, minutes: till.minute}
          });
        } else if (this.meeting.id === parseInt(booking.prolonged_room_booking_id, 10)) {
          //Update like this to trigger onChanges
          this.meeting = {...this.meeting, till, prolongId: booking.id};
          this.mainEvent.end = {
            hours: till.hour,
            minutes: till.minute
          };
          this.completeSettingMeetingData();
        }
      });
    });
  }

  setProlongDuration(duration: TimelineEvent) {
    this.prolongDuration = duration;
  }

  actionTriggered(action) {
    switch (action) {
      case 'invite-others':
        this.inviteOthers = !this.inviteOthers;
        break;
    }
  }

  reloadEvent() {
    this.user.profile().then(profile => {
      this.profile = profile;
      if (this.isNative) {
        this.handleNativeMeeting();
      } else {
        this.handleMeeting(this.meetingId);
      }
    });
  }

  currentNavigationChanged(tab) {
    this.currentNavigation = tab;
    this.change.detectChanges();
  }

  bookMeetbox() {
    this.openQuickBook(this.meeting.originalNativeEvent, this.meeting.attendees);
  }

  async openQuickBook(event, attendees) {
    const info = {
      action: 'quick-book',
      eventId: event.id,
      subject: event.title,
      description: event.note,
      from: event.from,
      till: event.till,
      attendees,
      capacity: attendees.length
    };
    let breakpoint = .6;
    if (this.platform.is('ios')) {
      breakpoint = .7;
    }
    this.modal = await this.modalCtrl.create({
      component: QuickBookModalComponent,
      componentProps: {
        info
      },
      initialBreakpoint: breakpoint,
      breakpoints: [0, breakpoint]
    });
    this.modal.present().then(() => {
      const handle = document.querySelector('.show-modal').shadowRoot.querySelector('.modal-handle');
      handle.addEventListener('click', () => {
        this.modal.dismiss();
      });
    });

    const {data, role} = await this.modal.onWillDismiss();
    if (role === 'continue') {
      this.openPaymentModal(data.meetBox, event);
    } else if (role === 'change') {
      const state = {...info};
      state.from = state.from.toMillis();
      state.till = state.till.toMillis();
      this.nav.navigateBack(['/meet'], {state});
    }
  }

  async openPaymentModal(meetBox, event) {
    this.paymentModal.openModal({
      type: 'booking',
      params: {
        dateFrom: event.from.toFormat('yyyy-LL-dd HH:mm:ss'),
        dateTill: event.till.toFormat('yyyy-LL-dd HH:mm:ss'),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        room_id: meetBox.id,
        capacity: meetBox.capacity,
        subject: event.title,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        existingEvent_id: event.id,
      },
    }).then(result => {
      if (result.role === 'success') {
        this.calendar.updateExistingEventNote(
          result.data.existingEvent_id,
          result.data.id);
        this.toaster.toast(`${result.data.room.name} was successfully booked`, 'ok');
        this.nav.navigateBack(['/refresh'], {skipLocationChange: true, animated: false}).then(() => {
          this.nav.navigateRoot(['/meeting', {id: result.data.id}]);
        });
      }
    });
  }

  getTitle() {
    return this.meeting ? this.meeting.subject : 'Meeting';
  }
}
