/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../General/ApiService';

@Injectable({
  providedIn: 'root'
})
export class CarParkApiService {
  constructor(private api: ApiService) {
  }

  public carParks() {
    return this.api.get('user/accessible-carParks');
  }

  public sessions(date: string) {
    const params = {
      date,
    };

    return this.api.get('v3/parking/session', params);
  }

  public licensePlates() {
    return this.api.get('v3/parking/license-plates');
  }

  public deleteLicensePlate(plateId: number) {
    return this.api.delete(`v3/parking/license-plates/${plateId}`);
  }

  public open(barrierId: number) {
    return this.api.post(`barrier/open/${barrierId}`);
  }

  public parkingTicket(parkingTicketId: number) {
    return this.api.get(`parkingTicket/${parkingTicketId}`);
  }

  public parkingTicketByBarcode(barcode: string) {
    return this.api.get(`parkingTicket-by-barcode/${barcode}`);
  }

  public validateParkingTicket(parkingTicketId: number,
                               customerId: number = null,
                               userId: number = null,
                               remark: string = null,
                               meetingId: number = null) {
    const params = {
      parkingTicket_id: parkingTicketId,
      ...(customerId && {customer_id: customerId}),
      ...(userId && {user_id: userId}),
      ...(remark && {comment: remark}),
      ...(meetingId && {roomBooking_id: meetingId}),
    };

    return this.api.post('validate-parking-ticket', params);
  }

}
