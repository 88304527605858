export class AssetTypes{
  static printer = 64;
  static loxoneDoor = 111;
  static saltoDoor = 116;
  static barrier = 16;
  static sharedBike = 127;
  static vendingControl = 128;
  static charger = 129;
  static tv = 59;
  static whiteboard = 61;
  static teams = 122;
  static ventilation = 112;
  static blinds = 113;
  static lights = 30;
}
