import {Injectable} from '@angular/core';
import {StorageService} from '../Storage/StorageService';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private readonly cacheKeyPrefix: string = 'cache-';

  constructor(private storageService: StorageService) {
  }


  async get(key: string, fetchDataPromise: Promise<any>, ttl: number = 3600): Promise<any> {
    const cacheKey = this.getCacheKey(key);
    const cacheData = await this.storageService.get(cacheKey);

    if (cacheData) {
      const {value, expiry} = cacheData;
      const now = Math.floor(Date.now() / 1000);

      if (expiry && expiry > now) {
        return value;
      } else {
        await this.storageService.remove(cacheKey);
      }
    }

    const result = await fetchDataPromise;
    const expiry = Math.floor(Date.now() / 1000) + ttl;
    await this.storageService.set(cacheKey, {value: result, expiry});

    return result;
  }

  getWithDelayedObservable(key: string, fetchDataPromise: Promise<any>, ttl: number = 3600): Observable<any> {
    const cacheKey = this.getCacheKey(key);
    const subject = new Subject<any>();

    this.storageService.get(cacheKey).then(cacheData => {
      if (cacheData) {
        const { value, expiry } = cacheData;
        const now = Math.floor(Date.now() / 1000);

        if (expiry && expiry > now) {
          subject.next(value);
        } else {
          this.storageService.remove(cacheKey);
        }
      }
      fetchDataPromise.then(result => {
        const expiry = Math.floor(Date.now() / 1000) + ttl;
        this.storageService.set(cacheKey, { value: result, expiry });
        subject.next(result);
        subject.complete();
      }).catch(error => {
        subject.error(error);
      });
    }).catch(error => {
      subject.error(error);
    });

    return subject.asObservable();
  }

  async remove(key: string): Promise<void> {
    const cacheKey = this.getCacheKey(key);
    await this.storageService.remove(cacheKey);
  }

  async clear(): Promise<void> {
    const keys = await this.storageService.keys();
    const cacheKeys = keys.filter(key => key.startsWith(this.cacheKeyPrefix));
    await Promise.all(cacheKeys.map(cacheKey => this.storageService.remove(cacheKey)));
  }

  private getCacheKey(key: string): string {
    return `${this.cacheKeyPrefix}${key}`;
  }
}
