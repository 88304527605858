import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DateTime} from 'luxon';
import {ButtonInterface} from '../../../../components/buttons/button-switch/button.interface';
import {RoomService} from '../../../../services/Rooms/RoomService';
import {DisplayService} from '../../../../services/Displays/DisplayService';
import {AssetTypes} from '../../../../services/Assets/AssetTypes';

@Component({
  selector: 'app-meeting-room-controls',
  templateUrl: './meeting-room-controls.component.html',
  styleUrls: ['./meeting-room-controls.component.scss']
})

export class MeetingRoomControlsComponent implements OnChanges, OnInit {
  @Input() room: any;
  loaded = false;
  doorPmsIdsCorrected: Array<number> = [];
  doorPmsIds: Array<number> = [];
  doorNames: Array<string> = [];
  hasDoor = false;
  hasHVAC = false;
  hasLights = false;
  hasBlinds = false;
  hasMeetings = false;
  meetings: Array<any> = [];
  assetIds = {
    lights: String(AssetTypes.lights),
    door: String(AssetTypes.loxoneDoor),
    hvac: String(AssetTypes.ventilation),
    blinds: String(AssetTypes.blinds)
  };
  constructor(
    private roomService: RoomService) {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnInit() {
    this.roomService.room(this.room.id).then(room => {
      this.hasMeetings = ['EducationSpace', 'MeetSpace'].includes(room.type);
      room.bookings.forEach(meeting => {
        this.meetings.push({
          from: DateTime.fromFormat(meeting.dateFrom, 'yyyy-LL-dd HH:mm:ss.SSS').toFormat('HH:mm'),
          till: DateTime.fromFormat(meeting.dateTill, 'yyyy-LL-dd HH:mm:ss.SSS').toFormat('HH:mm'),
          customer: meeting.customer_name,
        });
      });
      if (room.assets_in_the_room) {
        this.checkAssets(room.assets_in_the_room);
      }
      this.loaded = true;
    });
  }

  checkAssets(assets) {
    this.checkDoors(assets.filter(a => a.assetType_id === this.assetIds.door));
    this.checkLights(assets.filter(a => a.assetType_id === this.assetIds.lights));
    this.checkBlinds(assets.filter(a => a.assetType_id === this.assetIds.blinds));
    this.checkHVAC(assets.filter(a => a.assetType_id === this.assetIds.hvac));
  }

  checkDoors(doors) {
    doors.forEach(door => {
      const doorPmsId = parseInt(door.PMScontrol_id, 10) ?? null;
      this.hasDoor = !this.hasDoor; //only set hasDoor when amount of doors is odd
      this.doorPmsIds.push(doorPmsId);
      this.doorNames.push(door.name);
    });
    const newDoors = [...this.doorPmsIds];
    newDoors.pop();
    this.doorPmsIdsCorrected = this.doorPmsIds.length % 2 ? newDoors : this.doorPmsIds;
  }

  checkLights(lights) {
    this.hasLights = typeof lights[0] !== 'undefined';
  }

  checkBlinds(blinds) {
    this.hasBlinds = typeof blinds[0] !== 'undefined';
  }

  checkHVAC(hvac) {
    this.hasHVAC = typeof hvac[0] !== 'undefined';
  }
}
