import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Clipboard} from '@capacitor/clipboard';
import {ToastService} from '../../../../services/General/ToastService';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';

@Component({
  selector: 'app-meeting-teams',
  templateUrl: './meeting-teams.component.html',
  styleUrls: ['./meeting-teams.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MeetingTeamsComponent {
  @Input() url: string;

  constructor(private toaster: ToastService, private browser: InAppBrowser) {
  }

  joinTeamsUrl() {
    this.browser.create(this.url, '_system');
  }

  async shareTeamsUrl() {
    await Clipboard.write({
      // eslint-disable-next-line id-blacklist
      string: this.url,
    });
    this.toaster.toast('Teams URL Copied');
  }
}
