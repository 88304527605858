/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../General/ApiService';

@Injectable({
  providedIn: 'root'
})
export class AssetsApiService {
  constructor(private api: ApiService) {
  }

  public asset(assetId: number, tenant: string = null) {
    return this.api.get(`asset/${assetId}`, {...(tenant && {tenant})});
  }

  public types() {
    return this.api.get('asset/types');
  }

  public create(roomId: number, name: string, assetTypeId: number, photo: File = null) {
    const params = new FormData();
    if (photo) {
      params.append('file', photo, photo.name);
    }
    params.append('name', name);
    params.append('room_id', `${roomId}`);
    params.append('type', `${assetTypeId}`);
    return this.api.post('asset/create', params);
  }
}
