import {TenantApiService} from './API/TenantApiService';
import {Injectable} from '@angular/core';
import {StorageService} from '../Storage/StorageService';
import {BehaviorSubject} from 'rxjs';
import {AppUrlsInterface, TenantConfig} from './tenantConfig.interface';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  public storageConfigKey = 'tenant-config';
  public tenantConfig = new BehaviorSubject<TenantConfig>(null);

  constructor(
    private api: TenantApiService,
    private storage: StorageService
    ) {
    this.api.setApiTenantService(this);
  }

  async setConfigTenant(){
    await this.storage.get(this.storageConfigKey).then(async (storageResult) => {
      if (storageResult) {
        this.tenantConfig.next(storageResult);
      }
      await this.getConfig().then(result => {
        this.storage.set(this.storageConfigKey, result);
        this.tenantConfig.next(result);
      });
    });
  }

  public getName(): string{
    return this.tenantConfig.value.name;
  }

  public getIcon(): string{
    return this.tenantConfig.value.icon;
  }

  public getAppUrls(): AppUrlsInterface{
    return this.tenantConfig.value.appUrls;
  };

  public getFaqLink(): string{
    return this.tenantConfig.value.faqLink;
  }

  public getWifiConfig(){
    return this.tenantConfig.value.wifi;
  }

  public getOneSignalPrefix(){
    return this.tenantConfig.value.oneSignalPrefix;
  }

  public getLogin() {
    return this.tenantConfig.value.login;
  }

  public getService() {
    return this.tenantConfig.value.service;
  }

  public setColors(colors: Array<any>){
    for (const [key, value] of Object.entries(colors)) {
      const htmlEl = document.querySelector('html');
      htmlEl.style.setProperty('--ion-color-tenant-'+key, value);
    }
  }

  public forgetVariables(){
    this.storage.remove(this.storageConfigKey);
  }

  private getConfig(): Promise<TenantConfig>{
    return this.api.getConfig();
  }
}
