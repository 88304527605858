/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../General/ApiService';

@Injectable({
  providedIn: 'root'
})
export class WalletApiService {
  constructor(private api: ApiService) {
  }

  public wallets(type: string = null) {
    const params = {
      ...(type && {type})
    };
    return this.api.get('v2/wallets', params);
  }

  public walletTransactions(type: string, customerId: number = null, page: number = 1) {
    const params = {
      type,
      customerId,
      page
    };
    return this.api.get('v2/wallet/transactions', params);
  }

  public walletValue(type: string, customerId: number = null) {
    const params = {
      type,
      ...(customerId && {customerId}),
    };
    return this.api.get('v2/wallet/value', params);
  }

  public topUp(amount: number) {
    return this.api.post('v1/wallets/topup', {amount});
  }

  public topUpStatus(externalId: string){
    return this.api.get('v2/wallet/topup/status', {externalId});
  }
}
