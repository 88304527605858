/* eslint-disable @typescript-eslint/naming-convention */
import {DateTime} from 'luxon';

export interface WalletTransaction {
  value: string;
  date: DateTime;
  type: WalletTransactionType;
  remark: string;
}

export enum WalletTransactionType {
  ADD = '+',
  SUB = '-'
}
