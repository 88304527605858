import {Component, OnInit, ViewChild} from '@angular/core';
import {IonTabs, ModalController, NavController} from '@ionic/angular';
import {MoreModalComponent} from './../../../modals/more/more-modal.component';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/Auth/AuthService';
import {StorageService} from '../../../services/Storage/StorageService';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {QueryParameterService} from '../../../services/General/QueryParameterService';
import {UserService} from '../../../services/User/UserService';
import {ModuleService} from '../../../services/Modules/ModuleService';
import {TenantService} from '../../../services/Tenant/TenantService';

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss'],
})
export class TabsPage implements OnInit {
  @ViewChild(IonTabs) tabs: IonTabs;
  isMoreModalOpen = false;
  modal = undefined;
  initialBreakpoint: any;
  activeUrl: string;

  constructor(private modalCtrl: ModalController, private router: Router, private nav: NavController,
              private auth: AuthService, private storage: StorageService,
              private browser: InAppBrowser, private queryParameterService: QueryParameterService,
              public user: UserService, public modules: ModuleService,
              private tenantService: TenantService) {
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      // @ts-ignore
      if (event.type === 1) {
        // @ts-ignore
        this.activeUrl = event.url;
        this.queryParameterService.clearForUrl(this.activeUrl);
      }
      //Store query parameters if any are found (they get automatically cleared)
      // @ts-ignore
      if (event.type === 0) {
        // @ts-ignore
        this.queryParameterService.checkForParameters(event.url);
      }
    });
  }

  async toggleModal() {
    if (this.isMoreModalOpen) {
      this.closeModal();
      return;
    }

    this.initialBreakpoint = await this.storage.get('more-modal-break-point') ?? 0;

    this.modal = await this.modalCtrl.create({
      component: MoreModalComponent,
      initialBreakpoint: this.initialBreakpoint,
      breakpoints: [0, this.initialBreakpoint]
    });
    this.modal.present().then(() => {
      this.setActualBreakPoint();
      const handle = document.querySelector('.show-modal').shadowRoot.querySelector('.modal-handle');
      handle.addEventListener('click', () => {
        this.modal.dismiss();
      });
    });
    this.isMoreModalOpen = true;

    const {data, role} = await this.modal.onWillDismiss();

    if (role) {
      this.isMoreModalOpen = false;
    }

    if (typeof data === 'object' && 'route' in data) {
      this.navigate(data.route);
    }
  }

  setActualBreakPoint() {
    const headerHeight = this.modal.children.item(0).children.item(0).clientHeight;
    const bodyHeight = this.modal.children.item(0).children.item(1).children.item(0).clientHeight;
    const breakpoint = ((headerHeight + bodyHeight + 16) / this.modal.clientHeight).toFixed(2);
    this.storage.set('more-modal-break-point', breakpoint);
    this.modal.breakpoints = [breakpoint];
    this.modal.setCurrentBreakpoint(breakpoint);
  }

  navigate(route) {
    switch (route) {
      case 'profile':
        this.nav.navigateBack(['/profile']);
        break;
      case 'wallet':
        this.nav.navigateBack(['/wallets']);
        break;
      case 'calendars':
        this.nav.navigateBack(['/calendar']);
        break;
      case 'rooms':
        this.nav.navigateBack(['/rooms']);
        break;
      case 'print':
        if (this.modules.has('printing')) {
          this.nav.navigateBack(['/print']);
        }
        break;
      case 'order':
        if (this.modules.has('orders') && this.user.canOrder()) {
          this.nav.navigateBack(['/orders']);
        }
        break;
      case 'car-charging-map':
        if (this.modules.has('car_charging')) {
          this.storage.get('charge_session_identifier').then(result => {
            if(result){
              this.nav.navigateBack(['/car-charging-session-active']);
            } else {
              this.nav.navigateBack(['/car-charging-map']);
            }
          });
        }
        break;
      case 'faq':
        this.browser.create(this.tenantService.getFaqLink(), null, {
          zoom: 'no',
          location: 'no',
        });
        break;
      case 'log-out':
        this.auth.logout('tabs').then(() => {
          this.tenantService.forgetVariables();
          this.nav.navigateBack('/');
        });
        break;
      default:
        break;
    }
  }

  checkExceptedUrls() {
    const exceptions = [
      '/',
      '/tenant-login',
      '/scan',
      '/splash',
      '/onboarding',
      '/login-overview',
      '/login-connect-email',
      '/invitations-found',
      '/car-charging-map',
      '/car-charging-session-active'
    ];
    return !exceptions.includes(this.activeUrl.split(';')[0]) && //prevent state variable interference
      !exceptions.includes(this.activeUrl.split('?')[0]); //prevent parameter interference
  }

  closeModal() {
    if (this.modal) {
      this.modal.dismiss();
      this.isMoreModalOpen = false;
    }
  }
}
