import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {ApiService} from '../../services/General/ApiService';
import {MeetFilterModalComponent} from '../../modals/meet-filter/meet-filter-modal.component';
import {FilterService} from '../../modals/meet-filter/FilterService';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class HeaderComponent implements OnInit, OnDestroy {
  @Input() title?: string;
  @Input() showNotifications?: boolean = true;
  @Input() showHistoryInvites?: boolean = false;
  @Input() showCalendarSelector?: boolean = false;
  @Input() showScan?: boolean = false;
  @Input() showAlternativeScanIcon?: boolean = false;
  @Input() route?: string;
  @Input() canGoBack = false;
  @Input() showBookingFilter = false;
  modal = undefined;
  countFilters = 0;
  subscription: any;

  constructor(
    private nav: NavController,
    private api: ApiService,
    private modalController: ModalController,
    private filterService: FilterService
  ) {
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    if (this.showBookingFilter) {
      this.getFilters();
    }
  }

  getFilters() {
    this.subscription = this.filterService.filter.subscribe(result => {
      this.countFilters = 0;
      const results = Object
        .keys(result)
        .filter((value, index) =>
          value !== 'property' && value !== 'sort' && (result[value] !== undefined && result[value] !== false && result[value] !== null));
      this.countFilters = results.length;
    });
  }

  back() {
    if (this.canGoBack) {
      this.nav.pop();
    } else {
      this.nav.navigateBack(this.route);
    }
  }

  showHistoryInvitesPage() {
    this.nav.navigateForward(['invite-history']);
  }

  showCalendarSelectorPage() {
    this.nav.navigateForward(['calendar-selector']);
  }

  iconOverview() {
    if (this.api.url.includes('hqbeta')) {
      this.nav.navigateForward(['svg-icon-overview']);
    }
  }

  scan() {
    this.nav.navigateBack(['/scan']);
  }

  async meetingFilterModal(app = this) {
    const breakpoint = .70;
    this.modal = await this.modalController.create({
      component: MeetFilterModalComponent,
      initialBreakpoint: breakpoint,
      breakpoints: [0, breakpoint],
    });
    this.modal.present().then(() => {
      const handle = document.querySelector('.show-modal').shadowRoot.querySelector('.modal-handle');
      handle.addEventListener('click', () => {
        this.modal.dismiss();
      });
    });

  }
}
