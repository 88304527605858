import {Injectable} from '@angular/core';
import {ChargeApiService} from './API/ChargeApiService';

@Injectable({
  providedIn: 'root'
})
export class ChargeService {
  constructor(private api: ChargeApiService) {
  }

  public locations() {
    return this.api.locations();
  }

  public location(identifier) {
    return this.api.location(identifier);
  }

  public startSession(
    connectorId: string,
    externalId: string,
    electricityTariffId: string,
    paymentMethod: string
  ) {
    return this.api.startSession(connectorId, externalId, electricityTariffId, paymentMethod);
  }

  public stopSession(identifier: string) {
    return this.api.stopSession(identifier);
  }

  public session(identifier: string) {
    return this.api.session(identifier);
  }

  public getConnector(assetId: number) {
    return this.api.getConnector(assetId);
  }

  public charger(identifier: string) {
    return this.api.charger(identifier);
  }
}
