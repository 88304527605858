import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';
import {AppComponentsModule} from 'src/app/app_components.module';
import {CarParksControlsModalComponent} from './car-parks-controls-modal.component';
import {SwiperModule} from 'swiper/angular';
import {CarParkControlsComponent} from './components/car-park-controls/car-park-controls.component';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, AppComponentsModule, SwiperModule],
  declarations: [CarParksControlsModalComponent, CarParkControlsComponent],
  exports: [CarParksControlsModalComponent],
})
export class CarParksControlsModalModule {
}
