/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {AppIcon} from '@capacitor-community/app-icon';
import {StorageService} from '../Storage/StorageService';
import {ModalController, Platform} from '@ionic/angular';
import {IconChangeModalComponent} from '../../modals/icon-change/icon-change-modal.component';
import {TenantService} from '../Tenant/TenantService';
import {options} from '../../components/svg-icon/svg-icon.type';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  icons = ['Sodexo'];
  modal: any;
  svgIcons = options;

  constructor(private storage: StorageService,
              private modalCtrl: ModalController,
              private tenantService: TenantService,
              private platform: Platform) {
  }

  hasIcon() {
    if (this.platform.is('desktop')) {
      return false;
    }

    const name = this.tenantService.getName();
    // @ts-ignore
    return this.svgIcons.includes(name + '-app-icon');
  }

  async changeIcon(name) {
    if (this.icons.includes(name)) {
      await this.storage.set('set-app-icon', name);
      await AppIcon.change({name, suppressNotification: true, disable: this.icons.filter(icon => icon !== name)});
    }
  }

  async resetIcon() {
    await this.storage.remove('set-app-icon');
    await AppIcon.reset({suppressNotification: true, disable: this.icons});
  }

  async openModal() {
    this.modal = await this.modalCtrl.create({
      component: IconChangeModalComponent,
      initialBreakpoint: .60,
      breakpoints: [0, .60]
    });
    this.modal.present().then(() => {
      const handle = document.querySelector('.show-modal').shadowRoot.querySelector('.modal-handle');
      handle.addEventListener('click', () => {
        this.modal.dismiss();
      });
    });
  }

}
