import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {SearchSelectOption} from './search-select-option.interface';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class SearchSelectComponent implements OnChanges {
  @Input() options: Array<SearchSelectOption> = [];
  @Input() backgroundColor = '#ffffff';
  @Input() selected: number = null;
  @Input() label = 'Search';
  @Input() placeholder: string = null;
  @Input() maxMenuHeight = 300;
  @Input() searchProperty = 'name';
  @Input() disableForceSelect = false;
  @Input() id = 0;
  @Input() hideLabel = false;
  @Input() disabled = false;
  @Input() search = '';
  @Input() loader = false;
  @Output() searchChange = new EventEmitter<string>();
  @Output() changed = new EventEmitter<number>();
  previousSearch = '';
  filteredOptions: Array<SearchSelectOption> = [];
  showOptions = false;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.filterOptions();
    }
    if (changes.selected) {
      const selected = this.options.filter(option => option.id === this.selected)[0];
      if (selected) {
        this.selectOption(selected);
      }
    }
  }

  filterOptions() {
    if (this.search) {
      this.filteredOptions = this.options.filter((r) =>
        r[this.searchProperty].toLowerCase().includes(this.search.toLowerCase()) || `${r.id}`.includes(this.search.toLowerCase()));
      if (this.filteredOptions.length === 1 && this.search.length > this.previousSearch.length && !this.disableForceSelect) {
        this.search = this.filteredOptions[0][this.searchProperty];
        this.selected = this.filteredOptions[0].id;
        this.changed.emit(this.selected);
        this.searchChange.emit(this.search);
        this.inputBlur();
      } else {
        this.selected = null;
        this.changed.emit(this.selected);
        this.searchChange.emit(this.search);
      }
      this.previousSearch = this.search;
    } else {
      this.filteredOptions = this.options;
      this.previousSearch = '';
      this.selected = null;
      this.changed.emit(this.selected);
      this.searchChange.emit(this.search);
    }
  }

  selectOption(option) {
    this.search = option[this.searchProperty];
    this.selected = option.id;
    this.changed.emit(this.selected);
    this.searchChange.emit(this.search);
  }

  inputInFocus() {
    this.showOptions = true;
    setTimeout(() => {
      document.getElementById(`options-anchor-${this.id}`).scrollIntoView({block: 'end'});
    }, 300);
  }

  inputBlur() {
    document.getElementById('searchInput').blur();
    this.showOptions = false;
  }
}
