import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {NavController} from '@ionic/angular';
import {AssetTypes} from '../../../../services/Assets/AssetTypes';

@Component({
  selector: 'app-meeting-banner',
  templateUrl: './meeting-banner.component.html',
  styleUrls: ['./meeting-banner.component.scss']
})

export class MeetingBannerComponent implements OnChanges {
  @Input() meeting: any;
  hasDoor = false;
  hasBlinds = false;
  hasVentilation = false;
  hasDisplay = false;

  constructor(private nav: NavController) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.meeting) {
      this.checkRoomOptions(this.meeting.room.assets_in_the_room);
    }
  }

  checkRoomOptions(assets) {
    if (assets.filter(e => e.assetType_id === String(AssetTypes.loxoneDoor)).length > 0) {
      this.hasDoor = true;
    }
    if (assets.filter(e => e.assetType_id === String(AssetTypes.blinds)).length > 0) {
      this.hasBlinds = true;
    }
    if (assets.filter(e => e.assetType_id === String(AssetTypes.ventilation)).length > 0) {
      this.hasVentilation = true;
    }
    if (assets.filter(e => e.assetType_id === String(AssetTypes.tv)).length > 0) {
      this.hasDisplay = true;
    }
  }

  roomOptions() {
    this.nav.navigateForward(['/room', {id: this.meeting.room.id, canGoBack: true}]);
  }
}
