import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';
import {AppComponentsModule} from 'src/app/app_components.module';
import {NotificationCenterModalComponent} from './notification-center-modal.component';
import {NotificationItemComponent} from './components/notification-item/notification-item.component';
import {HeaderComponent} from './components/header/header.component';
import {NoNotificationComponent} from './components/no-notification/no-notification.component';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, AppComponentsModule],
  declarations: [NotificationCenterModalComponent, NotificationItemComponent, HeaderComponent, NoNotificationComponent],
  exports: [NotificationCenterModalComponent],
})
export class NotificationCenterModalModule {
}
