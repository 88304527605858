import {Injectable} from '@angular/core';
import {PayModalComponent} from '../../modals/pay/pay-modal.component';
import {ModalController, Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PaymentModalService {
  modal: any;

  constructor(private modalCtrl: ModalController, private platform: Platform) {
  }

  async openModal(props) {
    let breakpoint = .70;
    if(this.platform.is('ios')) {
      breakpoint = .75;
    }
    this.modal = await this.modalCtrl.create({
      component: PayModalComponent,
      componentProps: props,
      initialBreakpoint: breakpoint,
      breakpoints: [0, breakpoint]
    });
    this.modal.present().then(() => {
      const handle = document.querySelector('.show-modal').shadowRoot.querySelector('.modal-handle');
      handle.addEventListener('click', () => {
        this.modal.dismiss();
      });
    });

    const {data, role} = await this.modal.onWillDismiss();
    return {data, role};
  }
}
