import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {SvgIcon} from '../../svg-icon/svg-icon.type';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ButtonComponent {
  @Input() title: string;
  @Input() route: string;
  @Input() color: string;
  @Input() disabled = false;
  @Input() small = false;
  @Input() noMargin = false;
  @Input() icon: SvgIcon = null;

  constructor(private router: Router) {
  }

  goToRoute() {
    if (this.route) {
      this.router.navigate([this.route]);
    }
  }
}
