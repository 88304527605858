import {Injectable} from '@angular/core';
import {StorageService} from '../Storage/StorageService';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {
  modules: any;

  constructor(private storage: StorageService) {
  }

  set(modules: any) {
    this.storage.set('app-modules', modules);
    this.modules = modules;
  }

  get() {
    return this.storage.get('app-modules').then(modules => {
      this.modules = modules;
      return modules;
    });
  }

  has(module: string) {
    if (!this.modules) {
      return false;
    }
    return this.modules[module] ?? false;
  }

  async hasAsync(module: string) {
    if (!this.modules) {
      const modules = await this.get();
      return modules[module] ?? false;
    } else {
      return this.modules[module] ?? false;
    }
  }
}
