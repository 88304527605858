import {Injectable} from '@angular/core';
import {UserApiService} from './API/UserApiService';
import {StorageService} from '../Storage/StorageService';
import {EncryptService} from '../General/EncryptService';
import {DateTime} from 'luxon';
import {BehaviorSubject} from 'rxjs';
import {CacheService} from '../Cache/CacheService';
import {ModuleService} from '../Modules/ModuleService';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  hasMembership = new BehaviorSubject<boolean>(true);
  membershipType = new BehaviorSubject<number>(null);

  constructor(private api: UserApiService, private storage: StorageService,
              private encrypt: EncryptService, private cache: CacheService,
              private modules: ModuleService) {
  }

  async profile(fresh = false) {
    if (fresh) {
      return this.getProfileFromApi();
    } else {
      return this.storage.get('profile')
        .then(profile => profile ?? this.getProfileFromApi());
    }
  }

  public isMember(fresh = false) {
    const now = DateTime.now();
    const endOfMonth = now.endOf('month');
    const secondsTillEndOfMonth = Math.round(endOfMonth.diff(now).as('seconds'));
    this.cache.get('user-is-member',
      this.profile(true)
        .then(profile => {
          if (profile.membership) {
            this.membershipType.next(parseInt(profile.membership.type_id, 10));
          }
          return !!(profile.membership && profile.membership.type_id === '1');
        }), secondsTillEndOfMonth)
      .then(check => {
        this.hasMembership.next(this.modules.has('memberships') ? check : true);
        if (!check) {
          this.storage.get('cache-user-is-member').then(data => {
            data.expires = DateTime.now().plus({minute: 10}).toMillis();
            this.storage.set('cache-user-is-member', data);
          });
        }
      });
  }

  public canBook() {
    return this.modules.has('guest_can_book') || this.membershipType.value === 1;
  }

  public canOrder() {
    return this.modules.has('guest_can_order') || this.membershipType.value === 1;
  }

  public canReserve() {
    return this.modules.has('guest_can_order') || this.membershipType.value === 1;
  }

  public canInviteGuest() {
    return this.modules.has('guest_can_invite_guest') || this.membershipType.value === 1;
  }

  public getProfileFromApi() {
    return this.api.profile().then(profile => {
      this.storage.set('profile', profile);
      return profile;
    });
  }

  public update(firstname: string, lastname: string, phone: string, twoFactorAuth: boolean, propertyId: number) {
    return this.api.update(firstname, lastname, phone, twoFactorAuth, propertyId);
  }

  public updateConsent(name: string, allowed: boolean) {
    return this.api.updateConsent(name, allowed);
  }

  public updatePreference(name: string, allowed: boolean) {
    return this.api.updatePreference(name, allowed);
  }

  public verifyEmail(email: string, token: string) {
    return this.api.verifyEmail(email, token);
  }

  public updateDefaultCustomer(customerId: number) {
    return this.api.updateDefaultCustomer(customerId);
  }

  public updateServicePassword() {
    return this.api.updateServicePassword();
  }

  public offices() {
    return this.api.offices().then(response => response.rooms);
  }

  public invitableColleagues(date: string) {
    return this.api.invitableColleagues(date).then(response => response.colleagues);
  }

  public inviteColleague(date: string, userId: number, propertyId: number) {
    return this.api.inviteColleague(date, userId, propertyId);
  }

  public marketPlaces() {
    return this.api.marketPlaces().then(response => response.rooms);
  }

  public userQRUrl(id: number) {
    return 'm-' + btoa(this.encrypt.encrypt(id + '-' + DateTime.now().toMillis()));
  }

  public prefillUserInformation(userQr) {
    return this.api.prefillUserInformation(userQr);
  }
}
