import {Component, ViewEncapsulation} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-camera-file-browser-choice',
  templateUrl: './camera-file-browser-choice-modal.component.html',
  styleUrls: ['./camera-file-browser-choice-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CameraFileBrowserChoiceModalComponent {

  constructor(private modalCtrl: ModalController) {
  }

  fileAdded(event) {
    this.modalCtrl.dismiss(event, 'success');
  }

  camera() {
    document.getElementById('camera').click();
  }

  browser() {
    document.getElementById('file').click();
  }
}
