import {Injectable} from '@angular/core';
import {WalletApiService} from './API/WalletApiService';
import {DateTime} from 'luxon';
import {WalletTransaction, WalletTransactionType} from '../../pages/wallets/interfaces/wallet-transaction.interface';
import {WalletMetaDataService} from './WalletMetaDataService';
import {StorageService} from '../Storage/StorageService';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  walletUpdated = new Subject<any>();

  constructor(private api: WalletApiService, private storage: StorageService,
              private metaData: WalletMetaDataService) {
  }

  public wallets(type: string = null) {
    return this.storage.get('wallet-meta-data').then(walletMetaData =>
      this.api.wallets(type).then(response => {
        const wallets = response.data.wallets.map((wallet, index) => ({
          id: index,
          customerId: wallet.id,
          name: wallet.name,
          type: wallet.type,
          amount: wallet.remaining ?? null,
        }));
        return this.metaData.handleWalletMetaDataUpdate(wallets, walletMetaData, true);
      }));
  }

  public walletTransactions(type: string, customerId: number = null, page: number = 1) {
    return this.api.walletTransactions(type, customerId, page).then(response => {
      const data = response.data.page;
      return {
        transactions: this.formatTransactions(data.data),
        page: data.current_page,
        nextPage: data.next_page_url ? data.current_page + 1 : null,
      };
    });
  }

  public walletValue(type: string, customerId: number = null) {
    return this.api.walletValue(type, customerId).then(response => response.data.amount);
  }

  public topUp(amount: number) {
    return this.api.topUp(amount).then(response => response.data);
  }

  public getWalletType(type) {
    switch (type) {
      case 'wallet':
        return 'Personal Wallet';
      case 'customer':
        return 'Corporate Wallet';
      case 'customer-token':
        return 'Meeting Tokens';
    }
    return '';
  }

  public createTransactionListByDate(transactions: Array<WalletTransaction>) {
    const list = [];
    let previousTransaction = null;
    transactions.forEach(transaction => {
      if (previousTransaction === null || !transaction.date.hasSame(previousTransaction.date, 'day')) {
        list.push({
          date: transaction.date,
          transactions: [],
        });
      }
      list[list.length - 1].transactions.push(transaction);
      previousTransaction = transaction;
    });
    return list;
  }

  public topUpStatus(externalId: string){
    return this.api.topUpStatus(externalId);
  }

  private formatTransactions(transactions) {
    return transactions.map(transaction => ({
      value: transaction.value.replace('.', ','),
      date: transaction.date ?
        DateTime.fromFormat(transaction.date, 'yyyy-LL-dd HH:mm:ss.SSS')
        : DateTime.fromISO(transaction.created_at),
      type: transaction.value.includes('-') ? WalletTransactionType.SUB : WalletTransactionType.ADD,
      remark: this.getTransactionRemark(transaction),
    }));
  }

  private getTransactionRemark(transaction) {
    if (transaction.remark === 'coffeeV2') {
      return 'Coffee';
    } else if (transaction.remark === 'seatReservation') {
      return 'Seat Reservation';
    } else if (transaction.remark !== null) {
      return transaction.remark[0].toUpperCase() + transaction.remark.substring(1);
    } else {
      if (transaction.info === 'top up') {
        return 'Top Up';
      } else {
        return transaction.info;
      }
    }
  }
}
