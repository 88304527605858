import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UserService} from '../../services/User/UserService';
import {PropertyService} from '../../services/Properties/PropertyService';
import {CacheService} from '../../services/Cache/CacheService';
import {ButtonInterface} from '../buttons/button-switch/button.interface';
import {PropertySelectorType} from './property-selector-type.enum';

@Component({
  selector: 'app-property-selector',
  templateUrl: './property-selector.component.html',
  styleUrls: ['./property-selector.component.scss'],
})
export class PropertySelectorComponent {
  @Input() type = PropertySelectorType.DEFAULT;
  @Input() label = 'Property';
  @Input() isRequired = 'false';
  @Input() disabled = false;
  @Input() presetState: any;
  @Output() watchId = new EventEmitter<number>();
  @Output() watchLocation = new EventEmitter<ButtonInterface>();
  @Output() watchProperties = new EventEmitter<any>();
  properties: any;
  propertyId: number;
  locations: Array<ButtonInterface> = [];

  constructor(private user: UserService, private propertyService: PropertyService, private cache: CacheService) {
    this.init();
  }

  init() {
    this.cache.get('personal-properties', this.propertyService.properties()).then(properties => {
      this.properties = properties;
      this.watchProperties.emit(this.properties);
      this.user.profile().then(profile => {
        if (this.presetState && this.presetState.propertyId){
          this.propertyId = this.presetState.propertyId;
        } else {
          this.propertyId = parseInt(profile.defaultProperty_id, 10);
        }
        this.locations = properties.map(property => ({
          id: property.id,
          title: property.city,
          enabled: true,
          selected: property.id === this.propertyId,
        }));
        this.propertyChanged();
      });
    });
  }

  setSelectedLocation(location) {
    this.propertyId = location.id;
    this.propertyChanged();
  }

  propertyChanged() {
    const resultLocation = this.locations.filter(location => location.id === this.propertyId)[0];
    this.watchId.emit(this.propertyId);
    this.watchLocation.emit(resultLocation);
  }

}
