import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Attendee} from '../../../../services/Meetings/attendee.interface';
import {Share} from '@capacitor/share';

@Component({
  selector: 'app-share-visitor-pass',
  templateUrl: './share-visitor-pass-modal.component.html',
  styleUrls: ['./share-visitor-pass-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ShareVisitorPassModalComponent {
  @Input() attendees: Array<Attendee>;

  constructor() {
  }

  async share(attendee: Attendee) {
    await Share.share({
      title: 'Share Visitor Pass URL',
      text: attendee.visitorPassUrl,
      url: attendee.visitorPassUrl,
      dialogTitle: 'Share Visitor Pass URL',
    });
  }
}
