import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {RoomControlService} from '../../../../services/Rooms/RoomControlService';
import {DateTime} from 'luxon';
import Bugsnag from '@bugsnag/js';

interface History {
  degrees: number;
  name: string;
  date: string;
}

interface Status {
  color: 'green' | 'blue' | 'red';
  message: string;
}

@Component({
  selector: 'app-room-hvac',
  templateUrl: './hvac.component.html',
  styleUrls: ['./hvac.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RoomHvacComponent implements OnInit {
  @Input() roomId: number;
  temperature: number;
  current: number;
  amountOfFans = 2;
  operatingMode: string;
  coolingActive = false;
  heatingActive = false;
  status: Status = {
    color: 'green',
    message: 'fresh air flow',
  };
  history: History[] = [];
  previousData: any;

  constructor(private controls: RoomControlService) {
  }

  ngOnInit() {
    this.controls.temperature(this.roomId).then((data) => {
      this.setData(data);
    });
  }

  setData(data) {
    if (!data) {
      data = this.previousData;
    }
    this.history = data.history.map(item => ({
      degrees: item.temperature,
      name: item.by,
      date: DateTime.fromFormat(item.timestamp, 'yyyy-LL-dd HH:mm:ss').toFormat('dd/LL/yyyy HH:mm'),
    }));
    this.temperature = data.temperature.setpoint;
    this.current = data.temperature.current;
    this.operatingMode = data.operatingMode;
    this.amountOfFans = data.fan;

    if (this.operatingMode === 'cooling') {
      this.status = {
        color: 'blue',
        message: `cooling to ${this.temperature} °C`,
      };
    } else if (this.operatingMode === 'heating') {
      this.status = {
        color: 'red',
        message: `heating to ${this.temperature} °C`,
      };
    } else {
      this.status = {
        color: 'green',
        message: 'fresh air flow',
      };
    }
    this.previousData = data;
  }

  clickedCooling() {
    if (this.operatingMode === 'cooling') {
      this.operatingMode = 'auto';
      this.controls.setOperatingModeAuto(this.roomId).then(data => {
        this.setData(data);
      }).catch(err => {
        Bugsnag.notify(err);
        this.setData(null);
      });
    } else {
      this.operatingMode = 'cooling';
      this.controls.setOperatingModeCooling(this.roomId).then(data => {
        this.setData(data);
      }).catch(err => {
        Bugsnag.notify(err);
        this.setData(null);
      });
    }
  }

  clickedHeating() {
    if (this.operatingMode === 'heating') {
      this.operatingMode = 'auto';
      this.controls.setOperatingModeAuto(this.roomId).then(data => {
        this.setData(data);
      }).catch(err => {
        Bugsnag.notify(err);
        this.setData(null);
      });
    } else {
      this.operatingMode = 'heating';
      this.controls.setOperatingModeHeating(this.roomId).then(data => {
        this.setData(data);
      }).catch(err => {
        Bugsnag.notify(err);
        this.setData(null);
      });
    }
  }

  clickedDecreaseTemperature() {
    this.temperature--;
    if (this.temperature < 19) {
      this.temperature = 19;
    }
    this.controls.setTemperature(this.roomId, this.temperature).then(data => {
      this.setData(data);
    }).catch(err => {
      Bugsnag.notify(err);
      this.setData(null);
    });
  }

  clickedIncreaseTemperature() {
    this.temperature++;
    if (this.temperature > 25) {
      this.temperature = 25;
    }
    this.controls.setTemperature(this.roomId, this.temperature).then(data => {
      this.setData(data);
    }).catch(err => {
      Bugsnag.notify(err);
      this.setData(null);
    });
  }
}
