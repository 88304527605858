import {AfterViewInit, Component, EventEmitter, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {IonModal, ModalController, Platform} from '@ionic/angular';
import {WifiService} from '../../services/Network/WifiService';
import {Wifi} from './wifi.interface';
import {UserService} from '../../services/User/UserService';
import {ToastService} from '../../services/General/ToastService';
import {Clipboard} from '@capacitor/clipboard';
import {StorageService} from '../../services/Storage/StorageService';
import {DateTime} from 'luxon';
import Bugsnag from '@bugsnag/js';
import {ModuleService} from '../../services/Modules/ModuleService';
import {TenantService} from '../../services/Tenant/TenantService';

@Component({
  selector: 'app-wifi',
  templateUrl: './wifi-modal.component.html',
  styleUrls: ['./wifi-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class WifiModalComponent implements AfterViewInit {
  @Output() connected = new EventEmitter<any>();
  @ViewChild(IonModal) modal: IonModal;
  networks: Array<Wifi> = [];
  disableBtn = false;
  refreshing = false;
  isAndroid = false;
  popupNetworkIndex: number = null;

  constructor(private modalCtrl: ModalController, private user: UserService,
              private wifi: WifiService, private toaster: ToastService,
              private platform: Platform, private storage: StorageService,
              public modules: ModuleService,
              public tenantService: TenantService) {
    this.networks = [...this.tenantService.getWifiConfig()];
    this.isAndroid = this.platform.is('android');
  }

  ngAfterViewInit() {
    this.storage.get('credentials-refreshed').then(date => {
      const refresh = date ? !DateTime.fromMillis(date).hasSame(DateTime.now(), 'day') : true;
      this.user.profile().then(profile => {
        this.networks.push({
          ssid: this.tenantService.getName(),
          username: profile.facility_username,
          password: profile.facility_password,
          enterprise: true,
          refresh,
        });
      });
    });
  }

  connect(network) {
    this.disableBtn = true;
    this.wifi.connect(network.ssid, network.password ?? null, network.username ?? null, network.enterprise ?? false)
      .then(connected => {
        if (connected) {
          this.modalCtrl.dismiss(null, 'close');
          this.toaster.dismissPermanentToast();
        }
      }).finally(() => {
      this.disableBtn = false;
    });
  }

  toggleHide(network) {
    network.hidden = !network.hidden;
  }

  showRefreshPasswordConfirmation(index) {
    this.popupNetworkIndex = index;
  }

  closeRefreshPasswordConfirmation() {
    this.popupNetworkIndex = null;
  }

  refreshPassword() {
    this.refreshing = true;
    this.user.updateServicePassword().then(data => {
      this.storage.set('credentials-refreshed', DateTime.now().toMillis());
      this.networks[this.popupNetworkIndex].refresh = false;
      this.user.profile(true).then(profile => {
        this.networks[this.popupNetworkIndex].username = profile.facility_username;
        this.networks[this.popupNetworkIndex].password = profile.facility_password;
      }).finally(() => {
        this.refreshing = false;
        this.popupNetworkIndex = null;
      });
    }).catch((err) => {
      Bugsnag.notify(err);
      this.toaster.toast('Something went wrong refreshing the password');
      this.refreshing = false;
    });
  }

  onWillDismiss(event: Event) {
    return event;
  }

  copy(text) {
    Clipboard.write({
      // eslint-disable-next-line id-blacklist
      string: text,
    }).then(() => {
      if (this.platform.is('ios')) {
        this.toaster.toast('Copied!', 'ok');
      }
    });
  }
}
