/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../General/ApiService';
import {BookPaymentParameters} from '../Interfaces/book-payment-parameters.interface';
import {OrderPaymentParameters} from '../Interfaces/order-payment-parameters.interface';
import {SeatReservationPaymentParameters} from '../Interfaces/seat-reservation-payment-parameters.interface';
import {Wallet} from '../../../pages/wallets/interfaces/wallet.interface';
import {VendingMachinePaymentParameters} from '../Interfaces/vending-machine-payment-parameters.interface';

@Injectable({
  providedIn: 'root'
})
export class PaymentApiService {

  constructor(private api: ApiService) {
  }

  public information(type, params: BookPaymentParameters |
    OrderPaymentParameters | SeatReservationPaymentParameters | VendingMachinePaymentParameters) {
    const parameters = {
      type,
      params
    };
    return this.api.post('v1/payment_information', parameters);
  }

  public pay(type,
             params: BookPaymentParameters | OrderPaymentParameters | SeatReservationPaymentParameters | VendingMachinePaymentParameters,
             method: Wallet) {
    method.id = method.customerId; //On HQ.MD the customer id is set by the id of the payment method
    const parameters = {
      type,
      params,
      payment_method: method,
    };
    return this.api.post('v1/app-payments', parameters);
  }
}
