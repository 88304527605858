import {Component, Input, ViewEncapsulation} from '@angular/core';
import {RoomControlService} from '../../../../services/Rooms/RoomControlService';

@Component({
  selector: 'app-room-blinds',
  templateUrl: './blinds.component.html',
  styleUrls: ['./blinds.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RoomBlindsComponent {
  @Input() roomId: number;

  constructor(private service: RoomControlService) {
  }

  buttonClicked(button: string) {
    switch (button) {
      case 'up':
        this.service.blindsUp(this.roomId);
        break;
      case 'pause':
        this.service.blindsPause(this.roomId);
        break;
      case 'down':
        this.service.blindsDown(this.roomId);
        break;
    }
  }
}
