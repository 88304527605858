/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  constructor() {
  }

  colors() {
    return [
      '#DB8639',
      '#4285F4',
      '#34A853',
      '#DAB739',
      '#9042F4',
      '#F44242',
      '#DC4CBC',
      '#1D95A8',
      '#f8b070',
      '#EA2020',
    ];
  }
}
