import {Injectable} from '@angular/core';
import {PaymentApiService} from './API/PaymentApiService';
import {BookPaymentParameters} from './Interfaces/book-payment-parameters.interface';
import {OrderPaymentParameters} from './Interfaces/order-payment-parameters.interface';
import {SeatReservationPaymentParameters} from './Interfaces/seat-reservation-payment-parameters.interface';
import {StorageService} from '../Storage/StorageService';
import {WalletMetaDataService} from '../Wallet/WalletMetaDataService';
import {Wallet} from '../../pages/wallets/interfaces/wallet.interface';
import {VendingMachinePaymentParameters} from './Interfaces/vending-machine-payment-parameters.interface';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private api: PaymentApiService, private storage: StorageService, private metaData: WalletMetaDataService) {
  }

  public formatPaymentMethods(paymentMethods, canUseTokenPayment) {
    return this.storage.get('wallet-meta-data').then(walletMetaData => {
        const wallets = paymentMethods.map((wallet, index) => ({
          id: index,
          customerId: wallet.id,
          name: wallet.name,
          type: wallet.type,
          amount: wallet.remaining ?? null,
        }));
        return this.metaData.handleWalletMetaDataUpdate(wallets, walletMetaData, canUseTokenPayment);
      });
  }

  public information(type, params: BookPaymentParameters |
    OrderPaymentParameters | SeatReservationPaymentParameters | VendingMachinePaymentParameters) {
    return this.api.information(type, params).then(response => {
      const data = response.data;
      return this.formatPaymentMethods(data.payment_methods, data.tokenAmount !== null).then(wallets => ({
          price: data.amount ?
            parseFloat(data.amount.replace('<sup>', '').replace('</sup>', '').replace('.', '').replace(',', '.')) : 0,
          priceWithVAT: data.amountWithVAT ?
            parseFloat(data.amountWithVAT.replace('<sup>', '').replace('</sup>', '').replace('.', '').replace(',', '.')) : 0,
          free: data.free,
          paymentMethods: wallets,
          ...(data.tokenAmount && {tokens: data.tokenAmount}),
          ...(data.booking_id && {bookingId: data.booking_id}),
          ...(data.booking && {booking: data.booking}),
          ...(data.date && {date: data.date}),
          ...(data.message && {message: data.message}),
          ...(data.seat_id && {seatId: data.seat_id}),
        }));
    });
  }

  public pay(type,
             params: BookPaymentParameters | OrderPaymentParameters | SeatReservationPaymentParameters | VendingMachinePaymentParameters,
             method: Wallet) {
    return this.api.pay(type, params, method).then(response => response.data);
  }
}
