import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {TimelineEvent} from './timeline-event.interface';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnChanges {
  @ViewChild('timeline') timeline: ElementRef;
  @Input() mainEvent: TimelineEvent;
  @Input() otherEvents: Array<TimelineEvent>;
  @Input() alternativeLayout = false;
  @Input() prolongAllowed = false;
  @Input() host = false;
  @Output() prolongDurationSelected = new EventEmitter<TimelineEvent | null>();
  hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  loaded = false;
  prolongEvents: Array<TimelineEvent> = [];
  prolongSelectedEvents: Array<TimelineEvent> = [];
  tryLoad: any;

  constructor(private change: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.mainEvent) {
      if (!this.loaded) {
        this.tryLoad = setInterval(() => {
          try {
            this.attemptScrollTo();
          } catch (err) {
            // do nothing error here is because native element isn't found yet
          }
        }, 20);
      }
    }
    if (this.mainEvent && this.otherEvents && this.host) {
      this.setProlongEvents();
    }
  }

  setProlongEvents() {
    const hours = this.mainEvent.end.hours;
    const minutes = this.mainEvent.end.minutes;
    const prolongOptionAmount = this.getProlongOptionAmount();
    const time = DateTime.now().set({hour: hours, minute: minutes});
    for (let i = 0; i < prolongOptionAmount; i++) {
      const newStartTime = time.plus({minute: i * 30});
      const newEndTime = time.plus({minute: (i + 1) * 30});
      this.prolongEvents.push({
        start: {
          hours: newStartTime.hour,
          minutes: newStartTime.minute,
        },
        end: {
          //Set to 24 when ending at 00.00 for event length calculation
          hours: !newEndTime.hour && !newEndTime.minute ? 24 : newEndTime.hour,
          minutes: newEndTime.minute,
        },
        index: i,
      });
    }
  }

  getProlongOptionAmount() {
    const totalHoursInMinutesLeftToday = (23 - this.mainEvent.end.hours) * 60;
    const totalHalfHoursLeft = Math.floor(((60 - this.mainEvent.end.minutes) + totalHoursInMinutesLeftToday) / 30);
    if (this.otherEvents.length) {
      const lateEvents = this.otherEvents.filter(event =>
        event.start.hours > this.mainEvent.end.hours ||
        (event.start.hours === this.mainEvent.end.hours && event.start.minutes > this.mainEvent.end.minutes)
      );
      lateEvents.sort((x, y) =>
        x.start.hours - y.start.hours || x.start.minutes - y.start.minutes);
      if (lateEvents.length) {
        const event = lateEvents[0];
        const amount = Math.floor(
          (((event.start.hours - this.mainEvent.end.hours) * 60) +
            (event.start.minutes - this.mainEvent.end.minutes)) / 30);
        return Math.min(amount, totalHalfHoursLeft);
      }
    }
    return totalHalfHoursLeft;
  }

  prolongSelected(index) {
    this.prolongSelectedEvents = [{
      start: {
        hours: this.prolongEvents[0].start.hours,
        minutes: this.prolongEvents[0].start.minutes,
      },
      end: {
        hours: this.prolongEvents[index].end.hours,
        minutes: this.prolongEvents[index].end.minutes,
      },
    }];
    this.prolongDurationSelected.emit(this.prolongSelectedEvents[0]);
  }

  clearProlongSelected() {
    this.prolongSelectedEvents = [];
    this.prolongDurationSelected.emit(null);
  }

  attemptScrollTo() {
    this.timeline.nativeElement.scrollLeft =
      this.calcEventStart(this.mainEvent) -
      (this.alternativeLayout ? 0 : (this.timeline.nativeElement.clientWidth / 2)) +
      (this.alternativeLayout ? 0 : (this.calcEventWidth(this.mainEvent) / 2)) - 10;
    if (this.timeline.nativeElement.scrollLeft || this.mainEvent.start.hours === 0) {
      this.loaded = true;
      clearTimeout(this.tryLoad);
      this.change.detectChanges();
    }
  }

  calcEventStart(event) {
    return (event.start.hours * 120) + (event.start.minutes * 2);
  }

  calcEventWidth(event) {
    return ((event.end.hours - event.start.hours) * 120) +
      ((event.end.minutes - event.start.minutes) * 2) +
      (this.alternativeLayout ? 10 : 0);
  }

  showCheck(event) {
    if (!this.alternativeLayout) {
      const halfDuration = ((event.end.hours - event.start.hours) * 60 + (event.end.minutes - event.start.minutes)) / 2;
      const time = DateTime.now().set({
        hour: event.start.hours,
        minute: event.start.minutes
      }).plus({minute: halfDuration}).minute;
      return time !== 0;
    } else {
      return true;
    }
  }
}
