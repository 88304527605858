import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list-modal.component.html',
  styleUrls: ['./contact-list-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ContactListModalComponent implements OnInit{
  @Input() contacts: any;
  shownContacts: any;
  searchTerm = '';

  constructor(
    private modalCtrl: ModalController) {
  }

  ngOnInit(): void {
    this.shownContacts = this.contacts;
  }

  selected(email) {
    this.modalCtrl.dismiss({email});
  }

  searchInput(){
    this.shownContacts = structuredClone(this.contacts).filter(contact => {
      if (this.searchTerm === '') {
        return true;
      } else {
        if (contact.name && contact.name.display && contact.name.display.toLowerCase().includes(this.searchTerm.toLowerCase())) {
          return true;
        }
        const emailContainsSearch = contact.emails.filter(email => email.address.toLowerCase().includes(this.searchTerm.toLowerCase()));
        if (emailContainsSearch.length) {
          contact.emails = emailContainsSearch;
          return true;
        }
      }
      return false;
    });
  }
}
