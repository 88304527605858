/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {UserService} from '../services/User/UserService';
import {NativeCalendarEvent} from '../services/Calendars/native-calendar-event.interface';
import {CalendarService} from '../services/Calendars/CalendarService';
import {DateTime} from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class NativeMeetingToMdMeetingAdapter {
  meeting: any;
  nativeMeeting: NativeCalendarEvent;
  profile: any;

  constructor(private user: UserService, private calendarService: CalendarService) {
  }

  transformNativeToMd(nativeMeeting, profile = null) {
    return new Promise(resolve => {
      this.profile = profile;
      this.nativeMeeting = nativeMeeting;
      this.meeting = {};
      if (!profile) {
        this.user.profile().then(user => {
          this.profile = user;
          this.createMeetingObject();
          resolve(this.meeting);
        });
      } else {
        this.createMeetingObject();
        resolve(this.meeting);
      }
    });
  }

  createMeetingObject() {
    this.addAttendees();
    this.addRoom();
    this.meeting.callForCatering = '0';
    this.meeting.campaign_id = null;
    this.meeting.cancellationAllowed = true;
    this.meeting.cancellationType = {id: 'fullPrice', value: null};
    this.meeting.capacity = this.nativeMeeting.attendees.length;
    this.meeting.confirmationMail_id = null;
    this.meeting.created_at = DateTime.now().toISO();
    this.meeting.creator_id = this.profile.id;
    this.meeting.customer = null;
    this.meeting.customer_id = null;
    this.meeting.dateFrom = this.nativeMeeting.from.toFormat('yyyy-LL-dd HH:mm:ss.SSS');
    this.meeting.dateTill = this.nativeMeeting.till.toFormat('yyyy-LL-dd HH:mm:ss.SSS');
    this.meeting.deleted_at = null;
    this.meeting.discount_code_id = null;
    this.meeting.event_id = null;
    this.meeting.existingEvent_id = null;
    this.meeting.extendPossible = false;
    this.meeting.external_id = null;
    this.meeting.from = this.nativeMeeting.from;
    this.meeting.till = this.nativeMeeting.till;
    this.meeting.id = this.nativeMeeting.id;
    this.meeting.initialDateTill = this.nativeMeeting.till.toFormat('yyyy-LL-dd HH:mm:ss.SSS');
    this.meeting.moveAllowed = false;
    this.meeting.moved = '0';
    this.meeting.notConfirmed = null;
    this.meeting.not_confirmed_till = null;
    this.meeting.notes = this.nativeMeeting.note;
    this.meeting.description = this.nativeMeeting.note;
    this.meeting.notes_cleaning = null;
    this.meeting.notes_planning = null;
    this.meeting.notes_reception = null;
    this.meeting.oldPrice = null;
    this.meeting.online_meeting_id = null;
    this.meeting.online_meeting_join_url = null;
    this.meeting.orderAllowed = false;
    this.meeting.orders = [];
    this.meeting.parkingReservationPrice = 0;
    this.meeting.paymentProvider = 'None';
    this.meeting.paymentRef = null;
    this.meeting.po_number = null;
    this.meeting.price = '0.00';
    this.meeting.prolongedMeetingId = null;
    this.meeting.prolonged_room_booking_id = null;
    this.meeting.reservableCarPark = null;
    this.meeting.roomBookingRepeat_id = null;
    this.meeting.room_id = null;
    this.meeting.securityHours = null;
    this.meeting.sequence = '0';
    this.meeting.source = 'Native';
    this.meeting.subject = this.nativeMeeting.title;
    this.meeting.teamsUrl = null;
    this.meeting.tokenPrice = 0;
    this.meeting.token_price = '0';
    this.meeting.updated_at = DateTime.now().toISO();
    this.meeting.user = this.profile;
    this.meeting.user_id = `${this.profile.id}`;
    this.meeting.validation_payment_id = null;
    this.meeting.originalNativeEvent = this.nativeMeeting;
  }

  addAttendees() {
    this.meeting.attendees = [];
    this.nativeMeeting.attendees.forEach((attendee, index) => {
      const isHost = this.profile.email === attendee.email;
      const attendeeObj = {
        carParkReservation: false,
        customer: null,
        email: attendee.email,
        host: isHost,
        id: attendee.id ?? index + 1,
        invitedAttendees: [],
        invited_by_attendee_id: null,
        name: attendee.name.length ? attendee.name : this.getNameFromEmail(attendee.email),
        status: this.calendarService.getMdAttendeeStatus(attendee.status),
        userId: isHost ? this.profile.id : null,
      };
      if (isHost) {
        this.meeting.attendees.unshift(attendeeObj);
      } else {
        this.meeting.attendees.push(attendeeObj);
      }
    });
    if (!this.nativeMeeting.attendees.some(attendee => attendee.email === this.profile.email)) {
      const name = this.profile.firstname ? `${this.profile.firstname} ${this.profile.lastname}` :
        this.getNameFromEmail(this.profile.email);
      this.meeting.attendees.unshift({
        carParkReservation: false,
        customer: null,
        email: this.profile.email,
        host: true,
        id: `a-${this.profile.id}`,
        invitedAttendees: [],
        invited_by_attendee_id: null,
        name,
        status: 1,
        userId: this.profile.id,
      });
    }
  }

  addRoom() {
    this.meeting.room = {};
    this.meeting.room.id = null;
    this.meeting.room.name = null;
    this.meeting.room.shortname = this.nativeMeeting.title;
    this.meeting.room.imageUrl = '/assets/images/native-event-bg.png';
    this.meeting.room.maxCapacity = Math.max(1, this.meeting.attendees.length);
    this.meeting.room.assets_in_the_room = [];
    this.meeting.room.property = {};
    this.meeting.room.property.name = this.nativeMeeting.eventLocation ?? this.nativeMeeting.location ?? '';
  }

  private getNameFromEmail(email: string) {
    const nameArray = email.split('@')[0].split('.');
    if (nameArray.length > 1) {
      let name = '';
      nameArray.forEach(part => {
        if (name.length) {
          name += ' ';
        }
        name += part;
      });
    } else {
      return nameArray[0];
    }
  }
}
