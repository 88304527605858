import {Component, Input, ViewEncapsulation} from '@angular/core';
import {DisplayService} from '../../services/Displays/DisplayService';
import {ToastService} from '../../services/General/ToastService';

@Component({
  selector: 'app-power-button',
  templateUrl: './power-button.component.html',
  styleUrls: ['./power-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PowerButtonComponent {
  @Input() displayId: number;
  @Input() enabled = false;

  constructor(private service: DisplayService, private toaster: ToastService) {
  }

  handleClick() {
    this.service.togglePower(this.displayId).then(() => {
      this.toaster.toast('Display power toggled','ok');
    });
  }
}
