import {MeetBox} from '../../../../components/meetbox/meetbox.interface';
import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-meet-boxes',
  templateUrl: './boxes.component.html',
  styleUrls: ['./boxes.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MeetBoxesComponent {
  @Input() boxes: MeetBox[] = [];
  @Input() schedules: Array<any> = [];
  @Input() nativeSchedule: Array<any> = [];
  @Input() from: DateTime;
  @Input() till: DateTime;
  @Input() noRooms: boolean;
  @Output() selected = new EventEmitter<MeetBox>();

  select(box: MeetBox): void {
    this.selected.emit(box);
  }

  getRoomSchedule(box: MeetBox) {
    return this.schedules ? this.schedules.find(room => room.id === box.id) : null;
  }
}
