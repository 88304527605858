import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {MeetingService} from '../../../../services/Meetings/MeetingService';
import {ToastService} from '../../../../services/General/ToastService';

@Component({
  selector: 'app-meeting-message-modal',
  templateUrl: './meeting-message-modal.component.html',
  styleUrls: ['./meeting-message-modal.component.scss']
})

export class MeetingMessageModalComponent implements OnChanges {
  @Input() meetingId: number;
  @Output() closeMenu = new EventEmitter<void>();
  selectedNotification: any = -1;
  customNotification: string;

  constructor(private service: MeetingService, private toaster: ToastService) {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  setNotifierOption(index) {
    this.selectedNotification = index;
  }

  closeNotifyMenu() {
    this.closeMenu.emit();
  }

  sendNotificationToOrganizer() {
    let message;
    if (this.selectedNotification === 0) {
      message = 'I\'ll be 10 minutes late.';
    } else if (this.selectedNotification === 1) {
      message = 'I\'ll be 20 minutes late.';
    } else {
      message = this.customNotification;
    }
    this.service.notifyHost(this.meetingId, message).then(() => {
      this.toaster.toast('Notification sent','ok');
      this.closeNotifyMenu();
    });
  }
}
