import {Component} from '@angular/core';

@Component({
  selector: 'app-no-notification',
  templateUrl: './no-notification.component.html',
  styleUrls: ['./no-notification.component.scss'],
})

export class NoNotificationComponent {
  constructor() {
  }
}
