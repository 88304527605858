import {Injectable} from '@angular/core';
import {Subject, interval} from 'rxjs';
import {ApiService} from '../General/ApiService';
import {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NetworkPingService {
  pingStream: Subject<number> = new Subject<number>();
  ping = 0;
  skipCycle = false;
  interval: any;

  constructor(private api: ApiService, private platform: Platform) {
    this.init();
    this.platform.resume.subscribe(() => {
      this.init();
    });
  }

  init() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.doPing();
    this.interval = setInterval(() => {
      if (!this.skipCycle) {
        this.doPing();
      } else {
        this.skipCycle = false;
      }
    }, 5000);
  }

  doPing() {
    const timeStart: number = performance.now();

    this.api.ping()
      .then(() => {
        const timeEnd: number = performance.now();
        const ping: number = timeEnd - timeStart;
        this.ping = ping;
        this.pingStream.next(ping);
      }).catch(() => {
    });
  }
}
