import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {Wallet} from '../../pages/wallets/interfaces/wallet.interface';
import {WalletService} from '../../services/Wallet/WalletService';
import {WalletTopUpModalComponent} from '../../pages/wallets/modals/wallet-top-up/wallet-top-up-modal.component';
import {ModalController} from '@ionic/angular';
import {TenantService} from '../../services/Tenant/TenantService';

@Component({
  selector: 'app-wallet-select',
  templateUrl: './wallet-select.component.html',
  styleUrls: ['./wallet-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WalletSelectComponent implements OnChanges {
  @Input() wallets: Array<Wallet> = [];
  @Input() selectedWallet = 0;
  @Input() fullWidth = false;
  @Input() allowWebFlow = false;
  @Output() selectedWalletChanged = new EventEmitter<number>();
  @Output() newWalletType = new EventEmitter<string>();
  @Output() shouldUpdate = new EventEmitter<void>();
  fullSelectedWallet: any;
  selecting = false;
  modal: any;
  tenantName: string;

  constructor(public service: WalletService, private modalCtrl: ModalController,
              private tenantService: TenantService) {
    this.tenantName = tenantService.getName();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.wallets) {
      this.updateActiveWallet(this.selectedWallet);
    }
  }

  openSelect() {
    this.selecting = true;
  }

  closeSelect() {
    this.selecting = false;
  }

  updateActiveWallet(sequenceId, type = null) {
    if (sequenceId === 999) {
      this.closeSelect();
      this.selectedWalletChanged.emit(sequenceId);
    } else {
      this.fullSelectedWallet = this.wallets.find(wallet => wallet.metaData.sequence === sequenceId);
      this.wallets.sort((a, b) => a.metaData.sequence === sequenceId ? -1 : b.metaData.sequence === sequenceId ? 1 : 0);
      this.closeSelect();
      this.selectedWalletChanged.emit(sequenceId);
      if (type) {
        this.newWalletType.emit(type === 'customer-token' ? 'token' : 'euro');
      }
    }
  }

  async topup(wallet) {
    if (wallet.type === 'wallet') {
      this.modal = await this.modalCtrl.create({
        component: WalletTopUpModalComponent,
        initialBreakpoint: .40,
        breakpoints: [0, .40]
      });
      this.modal.present().then(() => {
        const handle = document.querySelector('.show-modal').shadowRoot.querySelector('.modal-handle');
        handle.addEventListener('click', () => {
          this.modal.dismiss();
        });
      });

      await this.modal.onWillDismiss();
      this.shouldUpdate.emit();
    }
  }
}
