/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {ApiService} from '../../General/ApiService';

@Injectable({
  providedIn: 'root'
})
export class LocationApiService {
  constructor(private api: ApiService) {
  }

  public log(deviceId, appVersion, BSSID, SSID, lng, lat, deviceSession, notificationPermission) {
    const params = {
      deviceUuid: deviceId,
      appVersion,
      ...(lng && {longitude: lng}),
      ...(lat && {latitude: lat}),
      ...(BSSID && {accessPoint_BSSID: BSSID}),
      ...(SSID && {accessPoint_SSID: SSID}),
      deviceSession,
      notificationPermission,
    };
    return this.api.post('locationLog/create', params);
  }

}
