import {AnimationController} from '@ionic/angular';

export const customAnimation = (_: HTMLElement, opts: any) => {
  const animationCtrl = new AnimationController();
  // create root transition
  const rootTransition = animationCtrl
    .create()
    .duration(opts.duration || 120)
    .easing('cubic-bezier(0.7,0,0.3,1)');

  const enterTransition = animationCtrl.create().addElement(opts.enteringEl);
  const exitTransition = animationCtrl.create().addElement(opts.leavingEl);

  if (opts.direction === 'back') {
    exitTransition.fromTo('opacity', '0', '0');
  } else {
    exitTransition.fromTo('opacity', '1', '0');
  }
  enterTransition.fromTo('transform', 'translateY(1.5%)', 'translateY(0%)');
  exitTransition.fromTo('transform', 'translateY(0%)', 'translateY(-1.5%)');

  rootTransition.addAnimation([enterTransition, exitTransition]);
  return rootTransition;
};
