/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import { Keyboard } from '@capacitor/keyboard';
@Injectable({
  providedIn: 'root'
})
export class KeyboardService {

  constructor() {
    this.init();
  }

  init() {
    Keyboard.addListener('keyboardDidShow', info => {
      document.querySelector('html').classList.add('keyboard-is-open');
    });

    Keyboard.addListener('keyboardDidHide', () => {
      document.querySelector('html').classList.remove('keyboard-is-open');
    });
  }
}
