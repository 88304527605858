import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {MeetBox} from './meetbox.interface';
import {TimelineEvent} from '../timeline/timeline-event.interface';
import {DateTime} from 'luxon';
import {TenantService} from '../../services/Tenant/TenantService';
import {ModuleService} from '../../services/Modules/ModuleService';

@Component({
  selector: 'app-meetbox',
  templateUrl: './meetbox.component.html',
  styleUrls: ['./meetbox.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MeetboxComponent implements OnChanges {
  @Input() meetbox: MeetBox;
  @Input() schedule: any;
  @Input() from: DateTime;
  @Input() till: DateTime;
  @Input() capacityReservation: boolean;
  @Output() meetboxSelected = new EventEmitter<MeetBox>();
  mainEvent: TimelineEvent;
  otherEvents: Array<TimelineEvent> = [];
  tenantName: string = null;

  constructor(
    private tenantService: TenantService, public modules: ModuleService
  ) {
    this.tenantName = tenantService.getName();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.from && this.till) {
      this.mainEvent = {
        start: {hours: this.from.hour, minutes: this.from.minute},
        end: {hours: this.till.hour, minutes: this.till.minute}
      };
    }
    if(changes.schedule) {
      this.otherEvents = [];
    }
    if (this.schedule) {
      const prolongedBookings = [];
      this.schedule.bookings.forEach(booking => {
        const from = DateTime.fromFormat(booking.dateFrom, 'yyyy-LL-dd HH:mm:ss.SSS');
        const till = DateTime.fromFormat(booking.dateTill, 'yyyy-LL-dd HH:mm:ss.SSS');
        if (!booking.prolonged_room_booking_id) {
          this.otherEvents.push({
            start: {hours: from.hour, minutes: from.minute},
            end: {hours: till.hour, minutes: till.minute},
            id: booking.id,
          });
        } else {
          //Save bookings to be merged with others
          booking.from = from;
          booking.till = till;
          prolongedBookings.push(booking);
        }
      });
      this.mergeProlongedBookings(prolongedBookings);
    }
  }

  mergeProlongedBookings(prolongedBookings) {
    prolongedBookings.forEach(booking => {
      const eventToUpdate = this.otherEvents.filter(event => event.id === parseInt(booking.prolonged_room_booking_id, 10))[0];
      eventToUpdate.end = {
        hours: booking.till.hour,
        minutes: booking.till.minute
      };
    });
  }

  select(meetbox) {
    this.meetboxSelected.emit(meetbox);
  }
}
