import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'tenant-login',
    loadChildren: () => import('./pages/tenant-login/tenant-login.module').then(m => m.TenantLoginPageModule),
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./pages/onboarding/onboarding.module').then(m => m.OnboardingPageModule),
  },
  {
    path: 'login-overview',
    loadChildren: () => import('./pages/login-overview/login-overview.module').then(m => m.LoginOverviewPageModule),
  },
  {
    path: 'login-connect-email',
    loadChildren: () => import('./pages/login-connect-email/login-connect-email.module').then(m => m.LoginConnectEmailPageModule),
  },
  {
    path: 'invitations-found',
    loadChildren: () => import('./pages/invitations-found/invitations-found.module').then(m => m.InvitationsFoundPageModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
  },
  {
    path: 'meet',
    loadChildren: () => import('./pages/meet/meet.module').then(m => m.MeetPageModule),
  },
  {
    path: 'meet-capacity',
    loadChildren: () => import('./pages/capacity-reservation/capacity-reservation.module').then(m => m.CapacityReservationPageModule),
  },
  {
    path: 'meet-capacity-reservation',
    loadChildren: () => import('./pages/capacity-reservation/capacity-reservation.module').then(m => m.CapacityReservationPageModule),
  },
  {
    path: 'meet-confirm',
    loadChildren: () => import('./pages/meet-confirm/meet-confirm.module').then(m => m.MeetConfirmPageModule),
  },
  {
    path: 'scan',
    loadChildren: () => import('./pages/scan/scan.module').then(m => m.ScanPageModule),
  },
  {
    path: 'asset',
    loadChildren: () => import('./pages/asset/asset.module').then(m => m.AssetPageModule),
  },
  {
    path: 'edit-tag',
    loadChildren: () => import('./pages/edit-tag/edit-tag.module').then(m => m.EditTagPageModule),
  },
  {
    path: 'service',
    loadChildren: () => import('./pages/service/service.module').then(m => m.ServicePageModule),
  },
  {
    path: 'reserve',
    loadChildren: () => import('./pages/reserve/reserve.module').then(m => m.ReservePageModule),
  },
  {
    path: 'invite',
    loadChildren: () => import('./pages/invite/invite.module').then(m => m.InvitePageModule),
  },
  {
    path: 'invite-history',
    loadChildren: () => import('./pages/invite-history/invite-history.module').then(m => m.InviteHistoryModule),
  },
  {
    path: 'room',
    loadChildren: () => import('./pages/room/room.module').then(m => m.RoomPageModule),
  },
  {
    path: 'rooms',
    loadChildren: () => import('./pages/rooms/rooms.module').then(m => m.RoomsPageModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule),
  },
  {
    path: 'wallets',
    loadChildren: () => import('./pages/wallets/wallets.module').then(m => m.WalletsPageModule),
  },
  {
    path: 'community',
    loadChildren: () => import('./pages/community/community.module').then(m => m.CommunityPageModule),
  },
  {
    path: 'academy',
    loadChildren: () => import('./pages/academy/academy.module').then(m => m.AcademyPageModule),
  },
  {
    path: 'event',
    loadChildren: () => import('./pages/event/event.module').then(m => m.EventPageModule),
  },
  {
    path: 'calendar',
    loadChildren: () => import('./pages/calendar/calendar.module').then(m => m.CalendarPageModule),
  },
  {
    path: 'calendar-selector',
    loadChildren: () => import('./pages/calendar-selector/calendar-selector.module').then(m => m.CalendarSelectorPageModule),
  },
  {
    path: 'print',
    loadChildren: () => import('./pages/print/print.module').then(m => m.PrintPageModule),
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersPageModule),
  },
  {
    path: 'catering',
    loadChildren: () => import('./pages/order-catering/order-catering.module').then(m => m.OrderCateringPageModule),
  },
  {
    path: 'edit-invitees',
    loadChildren: () => import('./pages/edit-invitees/edit-invitees.module').then(m => m.EditInviteesPageModule),
  },
  {
    path: 'become-member',
    loadChildren: () => import('./pages/become-member/become-member.module').then(m => m.BecomeMemberPageModule),
  },
  {
    path: 'order-history',
    loadChildren: () => import('./pages/order-history/order-history.module').then(m => m.OrderHistoryPageModule),
  },
  {
    path: 'vending-machine-payment',
    // eslint-disable-next-line max-len
    loadChildren: () => import('./pages/vending-machine-payment/vending-machine-payment.module').then(m => m.VendingMachinePaymentPageModule),
  },
  {
    path: 'tcn-confirmation',
    loadChildren: () => import('./pages/tcn-confirmation/tcn-confirmation.module').then(m => m.TcnConfirmationPageModule),
  },
  {
    path: 'parking-validator',
    loadChildren: () => import('./pages/parking-validator/parking-validator.module').then(m => m.ParkingValidatorPageModule),
  },
  {
    path: 'refresh',
    loadChildren: () => import('./pages/refresh/refresh.module').then(m => m.RefreshPageModule),
  },
  {
    path: 'splash',
    loadChildren: () => import('./pages/splash/splash.module').then(m => m.SplashPageModule),
  },
  {
    path: 'svg-icon-overview',
    loadChildren: () => import('./pages/svg-icon-overview/svg-icon-overview.module').then(m => m.SvgIconOverviewPageModule),
  },
  {
    path: 'bike-sharing',
    loadChildren: () => import('./pages/bike-sharing/bike-sharing.module').then(m => m.BikeSharingModule),
  },
  {
    path: 'car-charging-map',
    loadChildren: () => import('./pages/car-charging/map/map.module').then(m => m.MapPageModule),
  },
  {
    path: 'car-charging-location-detail',
    loadChildren: () => import('./pages/car-charging/detail/detail.module').then(m => m.DetailPageModule),
  },
  {
    path: 'car-charging-session-active',
    loadChildren: () => import('./pages/car-charging/sessions-active/session-active.module').then(m => m.SessionActivePageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }]
})
export class AppRoutingModule {
}
