import {Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {TimelineEvent} from '../../../../components/timeline/timeline-event.interface';
import {MeetingService} from '../../../../services/Meetings/MeetingService';
import {ToastService} from '../../../../services/General/ToastService';
import {NavController} from '@ionic/angular';
import {PaymentModalService} from '../../../../services/Payment/PaymentModalService';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-meeting-active-actions',
  templateUrl: './meeting-active-actions.component.html',
  styleUrls: ['./meeting-active-actions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class MeetingActiveActionsComponent implements OnChanges {
  @Input() meeting: any;
  @Input() meetingActive = false;
  @Input() host = false;
  mainEvent: TimelineEvent;
  otherEvents: Array<TimelineEvent>;
  canProlongMeeting = false;
  prolongDuration: TimelineEvent;
  endingMeeting = false;
  now = DateTime.now();

  constructor(private service: MeetingService, private toaster: ToastService,
              private nav: NavController, private paymentModal: PaymentModalService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.meeting) {
      this.canProlongMeeting = this.meeting.extendPossible && this.meetingActive;
      this.setTimelineEvents();
    }
  }

  setTimelineEvents() {
    this.mainEvent = {
      start: {hours: this.meeting.from.hour, minutes: this.meeting.from.minute},
      end: {hours: this.meeting.till.hour, minutes: this.meeting.till.minute}
    };
    this.service.getScheduledMeetingsForIds(
      this.meeting.from.startOf('day'),
      this.meeting.till.endOf('day'),
      [this.meeting.room.id]).then(schedules => {
      this.otherEvents = [];
      schedules[0].bookings.forEach(booking => {
        const from = DateTime.fromFormat(booking.dateFrom, 'yyyy-LL-dd HH:mm:ss.SSS');
        const till = DateTime.fromFormat(booking.dateTill, 'yyyy-LL-dd HH:mm:ss.SSS');
        if (!(this.meeting.from.hasSame(from, 'hour') && this.meeting.from.hasSame(from, 'minute')) &&
          this.meeting.prolongedMeetingId !== booking.id && this.meeting.id !== parseInt(booking.prolonged_room_booking_id, 10)
        ) {
          this.otherEvents.push({
            start: {hours: from.hour, minutes: from.minute},
            end: {hours: till.hour, minutes: till.minute}
          });
        } else if (this.meeting.prolongedMeetingId === booking.id) {
          this.mainEvent.start = {
            hours: from.hour,
            minutes: from.minute
          };
        } else if (this.meeting.id === parseInt(booking.prolonged_room_booking_id, 10)) {
          //Update like this to trigger onChanges
          this.meeting = {...this.meeting, till, prolongId: booking.id};
          this.mainEvent.end = {
            hours: till.hour,
            minutes: till.minute
          };
        }
      });
    });
  }

  setProlongDuration(duration: TimelineEvent) {
    this.prolongDuration = duration;
  }

  endMeeting() {
    this.endingMeeting = true;
    if (this.meeting.prolongId) {
      this.service.endMeeting(this.meeting.prolongId).then(() => {
        this.service.endMeeting(this.meeting.id).then(() => {
        }).finally(() => {
          this.endingMeeting = false;
          this.toaster.toast('Meeting has ended');
          this.refresh();
        });
      });
    } else {
      this.service.endMeeting(this.meeting.id).then(() => {
        this.toaster.toast('Meeting has ended');
        setTimeout(() => {
          this.refresh();
        }, 500);
      }).finally(() => {
        this.endingMeeting = false;
      });
    }
  }

  async prolongMeeting() {
    let start: { hours: number; minutes: number };
    let end: { hours: number; minutes: number };
    if (this.prolongDuration !== null) {
      start = this.prolongDuration.start;
      end = this.prolongDuration.end;
    } else {
      start = {
        hours: this.meeting.till.toFormat('HH'),
        minutes: this.meeting.till.toFormat('mm')
      };
      end = {
        hours: this.meeting.till.plus({hour: 1}).toFormat('HH'),
        minutes: this.meeting.till.toFormat('mm')
      };
    }

    this.paymentModal.openModal({
      type: 'booking',
      params: {
        dateFrom: this.meeting.from.set({hour: start.hours, minute: start.minutes}).toFormat('yyyy-LL-dd HH:mm:ss'),
        dateTill: this.meeting.from.set({hour: end.hours, minute: end.minutes}).toFormat('yyyy-LL-dd HH:mm:ss'),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        room_id: this.meeting.room.id,
        capacity: this.meeting.capacity,
        subject: this.meeting.subject,
        prolongedRoomBookingId: this.meeting.id,
      },
    }).then(result => {
      if (result.role === 'success') {
        this.toaster.toast(`Meeting in ${result.data.room.name} was successfully prolonged`);
        this.refresh();
      }
    });
  }

  refresh() {
    this.nav.navigateBack('/refresh', {skipLocationChange: true, animated: false}).then(() => {
      this.nav.navigateBack(['/meeting', {id: this.meeting.id}], {animated: false});
    });
  }
}
